export const devTools = (<any>window)?.__REDUX_DEVTOOLS_EXTENSION__;
export const isDev = location?.host === "localhost:8080";

export interface DevTools {
    connect(options: any): void;
    send(actionName: string, state: any): void;
    init(state: any): void;
    /* This can be used to hook into action in the devtools.
     * You can set it up in the constructor of the store:
     * this.devTools?.subscribe((message) => {
     *    console.log(message);
     * })
     */
    subscribe(message: any): void;
}
