// First, checks if it isn't implemented yet.
const stringFormat = (str: string, ...argsx: any[]) => {
    let args = argsx;
    if (args && Array.isArray(args[0])) {
        args = args[0];
    }
    return str.replace(/{(\d+)}/g, (match, num) => {
        return args[num] !== undefined ? args[num] : match;
    });
};

const proto = <any>String.prototype;
if (!proto.format) {
    proto.format = function () {
        return stringFormat(this, arguments);
    };
}

export default stringFormat;
