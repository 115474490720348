import * as moment from "moment-timezone";

import { List } from "immutable";
import { RecordFactory } from "common/modules/recordFactory";

export interface IDateRange {
    start: IMoment | null;
    end: IMoment | null;
}

const DateRangeRecord = RecordFactory<IDateRange>({
    start: null,
    end: null,
});

export class DateRange extends DateRangeRecord implements IDateRange {
    public readonly start: IMoment | null;
    public readonly end: IMoment | null;

    constructor(values: IDateRange) {
        if (values) {
            if (values.start) {
                values.start = moment(values.start);
                values.start.milliseconds(0);
            }
            if (values.end) {
                values.end = moment(values.end);
                values.end.milliseconds(0);
            }
        }

        super(values);
    }

    public overlaps(b: DateRange, inclusive?: boolean) {
        if (!this.start || !this.end || !b.start || !b.end) {
            return false;
        }
        if (inclusive) return this.start <= b.end && b.start <= this.end;
        return this.start < b.end && b.start < this.end;
    }

    public clone(): DateRange {
        return new DateRange({ start: this.start?.clone() ?? null, end: this.end?.clone() ?? null });
    }

    public getDates(): List<IMoment> {
        let dates = List<IMoment>();
        if (!this.start || !this.end) {
            return dates;
        }
        const start = this.start.clone().startOf("day");
        const end = this.end.clone().endOf("day");

        for (const date = start; date.isSameOrBefore(end); date.add(1, "days")) {
            dates = dates.push(date.clone());
        }

        return dates;
    }

    public get from(): IMoment | null {
        return this.start?.clone() ?? null;
    }

    public get to(): IMoment | null {
        return this.end?.clone() ?? null;
    }
}
