import cx from "classnames";
import isBool from "./isBool";

interface ICxObj {
    [key: string]: boolean;
}

/**
 * CSS Builder: Used to prefix state classes for a component
 * @param args an array of classes matched with conditions
 * @example
 *
 * import css from "common/modules/cssBuilder";
 * const classes = css(
 *      "myParentCompName_myCompName", true,
 *      "selected", true,
 *      "hidden", false,
 *);
 * Outputs: "myParentCompName_myCompName_selected"
 *
 * @example
 * const classes = css(
 *     "listView_row",
 *     "selected", this.props.isSelected
 * );
 * Outputs: "listView_row_selected" or "listView_row"
 *
 * @example
 * const classes = css(
 *      "listView_row",false,
 *      "selected", this.props.isSelected
 * );
 *
 * Outputs: "listView_row_selected" or ""
 */
export default function cssBuilder(...args: (string | boolean | undefined)[]): string {
    const rootClass = args[0];
    const rootCondition = args[1];

    const cxObj: ICxObj = {};
    // always set root class unless is specified bool false
    cxObj[<string>rootClass] = rootCondition !== false;
    const prefix = rootClass + "-";

    const startIndex = isBool(rootCondition) ? 2 : 1;
    const length = args.length;
    for (let i = startIndex; i < length - 1; i += 2) {
        const className = args[i];
        const condition = args[i + 1];

        cxObj[prefix + className] = <boolean>condition;
    }
    return cx(cxObj);
}
