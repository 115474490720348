import * as React from "react";
import { ISVGCustomProps } from "ui/components/icon/customIcons/customIconsProps";

export const svgShuffleboard = function (props: ISVGCustomProps): JSX.Element {
    return (
        <svg className="svg-inline--fa fa-w-11 fa-fw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" color={props.color}>
            <path fill="currentcolor" d="M65,15a50,50,0,1,0,50,50A50,50,0,0,0,65,15Zm0,68.75A18.75,18.75,0,1,1,83.75,65,18.74,18.74,0,0,1,65,83.75Z"></path>
            <rect fill="currentcolor" x="152" y="83" width="15" height="32" rx="7.5"></rect>
            <rect fill="currentcolor" x="183" y="51" width="15" height="64" rx="7.5"></rect>
            <path fill="currentcolor" d="M175,126a50,50,0,1,0,50,50A50,50,0,0,0,175,126Zm0,68.75A18.75,18.75,0,1,1,193.75,176,18.74,18.74,0,0,1,175,194.77Z"></path>
        </svg>
    );
};
