import * as React from "react";

import { IDeviceDataProps as ImportedDeviceDataProps, Orientation as ImportedOrientation, RenderMode as ImportedRenderMode, RenderModeContext } from "common/contexts/renderModeContext";
// These should be reexports, but with current version ov TS (3.7) that will result in a warning
export type Orientation = ImportedOrientation;
export type RenderMode = ImportedRenderMode;
export type IDeviceDataProps = ImportedDeviceDataProps;

export function withDeviceData<U extends ImportedDeviceDataProps>(WrappedComponentType: React.ComponentClass<U>): React.FC<PropsWithImmutableChildren<U>> {
    const sfc: React.FC<PropsWithImmutableChildren<U>> = (props: U): React.ReactElement<U> => {
        return <RenderModeContext.Consumer>{(renderMode) => <WrappedComponentType {...renderMode} {...props} />}</RenderModeContext.Consumer>;
    };

    if (typeof WrappedComponentType.displayName != undefined) {
        (sfc as React.FC<PropsWithImmutableChildren<unknown>> | React.ComponentClass).displayName = WrappedComponentType.displayName;
    }

    return sfc;
}
