import * as AppActions from "framework/actions/applicationActions";
import * as API from "framework/modules/apiBase";

interface IApplicationAPI {
    loadCacheState(): void;
    flushCache(): void;
    flushSystemCache(): void;
    flushNewsCache(): void;
    setUseCache(state: boolean): void;
}

class ApplicationAPI extends API.APIBase implements IApplicationAPI {
    constructor() {
        super();
        this.setApiVersion("v1");
    }

    loadCacheState() {
        const pendingOperation = { method: this.loadCacheState };
        this.loadPending(pendingOperation);

        this.get("management/cache/state", {})
            .then(
                (result) => {
                    const data = result.data;

                    this.loadSuccess(pendingOperation);
                    new AppActions.UseCacheLoaded(data);
                },
                (fail) => {
                    this.loadFailed(pendingOperation, fail);
                }
            )
            .catch((errorObj) => {
                this.loadFailed(pendingOperation, errorObj);
            });
    }

    setUseCache(state: boolean) {
        const pendingOperation = { method: this.setUseCache };
        this.loadPending(pendingOperation);
        const cacheState = { prefix: "", value: state };
        this.post("management/cache/setState", { data: cacheState })
            .then(
                (result) => {
                    const data = result.data;

                    this.loadSuccess(pendingOperation);
                    new AppActions.UseCacheLoaded(data);
                },
                (fail) => {
                    this.loadFailed(pendingOperation, fail);
                }
            )
            .catch((errorObj) => {
                this.loadFailed(pendingOperation, errorObj);
            });
    }

    flushCache() {
        const pendingOperation = { method: this.flushCache };
        this.loadPending(pendingOperation);

        this.get("management/cache/invalidate", {})
            .then(
                () => {
                    this.loadSuccess(pendingOperation);
                },
                (fail) => {
                    this.loadFailed(pendingOperation, fail);
                }
            )
            .catch((errorObj) => {
                this.loadFailed(pendingOperation, errorObj);
            });
    }

    flushSystemCache() {
        const pendingOperation = { method: this.flushSystemCache };
        this.loadPending(pendingOperation);

        this.get("management/cache/invalidateSystem", {})
            .then(
                () => {
                    this.loadSuccess(pendingOperation);
                },
                (fail) => {
                    this.loadFailed(pendingOperation, fail);
                }
            )
            .catch((errorObj) => {
                this.loadFailed(pendingOperation, errorObj);
            });
    }

    flushNewsCache() {
        const pendingOperation = { method: this.flushNewsCache };
        this.loadPending(pendingOperation);

        this.get("news/clearCache", {})
            .then(
                () => {
                    this.loadSuccess(pendingOperation);
                },
                (fail) => {
                    this.loadFailed(pendingOperation, fail);
                }
            )
            .catch((errorObj) => {
                this.loadFailed(pendingOperation, errorObj);
            });
    }
}

const applicationAPI = new ApplicationAPI();
export default applicationAPI;
