import * as React from "react";
import { ISVGCustomProps } from "ui/components/icon/customIcons/customIconsProps";

export const svgDart = function (props: ISVGCustomProps): JSX.Element {
    return (
        <svg className="svg-inline--fa fa-w-11 fa-fw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" color={props.color}>
            <path fill="currentcolor" d="M152.73,130.91a43.64,43.64,0,1,1-43.64-43.64l19-19a65.51,65.51,0,1,0,43.63,43.63Z" />
            <path fill="currentcolor" d="M188.59,95A87.43,87.43,0,1,1,145,51.41l16.31-16.32a109,109,0,1,0,43.64,43.64Z" />
            <rect fill="currentcolor" x="90.81" y="72.44" width="121.11" height="32.4" transform="translate(-18.34 132.99) rotate(-45)" />
            <polygon fill="currentcolor" points="205.09 0 196.79 0 170.18 26.61 170.18 69.82 214.59 69.82 240 44.41 240 34.91 205.09 34.91 205.09 0" />
        </svg>
    );
};
