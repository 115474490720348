import Action from "./action";

export class Change extends Action {
    static actionType: string = "device_mode_change";

    mode: "desktop" | "tablet" | "mobile";

    constructor(mode: "desktop" | "tablet" | "mobile") {
        super(false);
        this.mode = mode;
        this.doDispatch();
    }
}

export class ChangedOrientation extends Action {
    static actionType: string = "device_orientation_change";

    orientation: "portrait" | "landscape";

    constructor(orientation: "portrait" | "landscape") {
        super(false);
        this.orientation = orientation;
        this.doDispatch();
    }
}

export class ChangedScreen extends Action {
    static actionType: string = "screen_size_change";

    width: number;
    height: number;

    constructor(width: number, height: number) {
        super(false);
        this.width = width;
        this.height = height;
        this.doDispatch();
    }
}
