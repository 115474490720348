import * as StationActions from "application/actions/stationActions";
import * as Actions from "framework/actions/permissionActions";
import * as UserActions from "framework/actions/userActions";
import * as Immutable from "immutable";

import PermissionAPI from "framework/api/permissionAPI";
import UserStore from "framework/stores/userStore";
import { SystemStore } from "application/stores/systemStore";
import IStore from "framework/stores/istore";
import Store from "framework/stores/store";
import Insights from "framework/modules/insights";

import { getSelectedSystemName } from "application/actionCreators/systemActionCreators";
import { systemChanged } from "application/actions/systemActions";

export interface IPermissionStore extends IStore {
    permissionsLoaded(): boolean;
    hasPermission(action: string): boolean;
    getPermissions(): Permissions | null;
    hasPermissionForStation(action: string, stationId: number): boolean;
    isCaspecoSupport(): boolean;
    isCaspecoDeveloper(): boolean;
    invalidate(): void;
}

export type Permissions = {
    global: { [action: string]: boolean };
    station: { [action: string]: number[] };
};

class PermissionStore extends Store implements IPermissionStore {
    private permissions: Permissions | null; //Immutable.List<string>;
    private status: string;
    private lastSystem: string;

    constructor() {
        super();
        this.handle(Actions.LoadSuccess.actionType, this.onPermissionsFetched);
        this.handle(systemChanged.type, this.onSystemChanged);
        this.handle(StationActions.CreatedSuccessfully.actionType, this.onStationsUpdated);
        this.handle(StationActions.UpdatedSuccessfully.actionType, this.onStationsUpdated);
        this.permissions = null; //Immutable.List<string>();
        this.status = this.NotLoaded;
        this.lastSystem = "";
        this.permissionsLoaded();

        UserStore.onChange(this.onUserStoreChanged);
        SystemStore.onChange(this.onUserStoreChanged);
    }

    onSystemChanged = () => {
        this.permissions = null;
        PermissionAPI.loadAll();
        this.status = this.Loading;
    };

    onStationsUpdated = () => {
        this.doLoadPermissions();
    };

    invalidate = () => {
        this.permissions = null;
        this.status = this.NotLoaded;
    };

    getPermissions(): Permissions | null {
        return this.permissions;
    }

    getStationsForPermission(action: string): Immutable.Set<number> {
        if (this.status !== this.Loaded || !action) return Immutable.Set<number>();
        const stationsForPermission = this.permissions?.station[action.toLowerCase()];
        return stationsForPermission ? Immutable.Set<number>(stationsForPermission) : Immutable.Set<number>();
    }

    private doLoadPermissions = () => {
        const system = getSelectedSystemName();
        this.lastSystem = system;
        PermissionAPI.loadAll();
    };

    permissionsLoaded(): boolean {
        if (!UserStore.isAuthenticated()) {
            // || this.userStore.getSelectedSystemName() === "") {
            return false;
        }
        if (this.status === this.NotLoaded) {
            this.doLoadPermissions();
            this.status = this.Loading;
            return false;
        } else if (this.status === this.Loading) {
            return false;
        } else {
            return true;
        }
    }

    isCaspecoSupport(): boolean {
        return this.hasPermission("caspeco.support");
    }

    isCaspecoDeveloper(): boolean {
        return this.hasPermission("caspeco.developer");
    }

    loadPermissions() {
        this.permissionsLoaded(); // does load.
    }

    hasPermission(action: string): boolean {
        if (this.status !== this.Loaded) {
            this.loadPermissions();
            return false;
        }

        action = action.toLowerCase();

        if (action[0] === "!") {
            return this.permissions?.global[action.substr(1, action.length - 1)] === false;
        }

        return this.permissions?.global[action] === true;
    }

    hasPermissionForStation(action: string, stationId: number): boolean {
        if (this.status !== this.Loaded) {
            this.loadPermissions();
            return false;
        }

        action = action.toLowerCase();
        const station = this.permissions?.station;

        return !!station?.[action] && station[action].indexOf(stationId) > -1;
    }

    onPermissionsFetched = (action: Actions.LoadSuccess) => {
        action.permissions.global = Immutable.Map(action.permissions.global)
            .mapKeys((k) => (<string>k).toLowerCase())
            .toObject();
        action.permissions.station = Immutable.Map(action.permissions.station)
            .mapKeys((k) => (<string>k).toLowerCase())
            .toObject();
        this.permissions = action.permissions;

        this.status = this.Loaded;

        if (this.isCaspecoSupport()) {
            Insights.setCustomAttribute("isCaspecoSupport", "true");
        }

        this.emitChange();
    };

    onUserStoreChanged = () => {
        if (!UserStore.isAuthenticated()) {
            this.status = this.NotLoaded;
            this.permissions = null;
        }

        const system = getSelectedSystemName();

        if (system !== this.lastSystem) {
            this.status = this.NotLoaded;
            this.lastSystem = system;
            this.permissions = null;
        }

        this.emitChange();
    };
}

const permissionStore = new PermissionStore();
export default permissionStore;
