import dispatcher from "./dispatcher";

type Action = { [key: string]: any };
type ActionPayload = { source: string; action: Action };
interface IAppDispatcher {
    dispatchViewAction(action: Action): void;
    register(handler: (payload: ActionPayload) => void): void;
}

class AppDispatcher extends dispatcher implements IAppDispatcher {
    dispatchViewAction(action: Action) {
        this.dispatch({
            source: "VIEW_ACTION",
            action: action,
        });
    }
}

export const appDispatcher = new AppDispatcher();
export default appDispatcher;
