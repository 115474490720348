enum TraceLevel {
    All = 100,
    Verbose = 80,
    Information = 40,
    Warning = 30,
    Error = 20,
    Critical = 10,
}

export default TraceLevel;
