import * as Moment from "moment-timezone";

/* eslint-disable no-restricted-imports */
import { StorageManager } from "application/storageManager/storageManager";
import { DateFormat, dateFormatString } from "common/mixins/localeHelper";
import { DatetimeRange } from "common/modules/datetime";
import { zeroPad } from "./numberHelper";
import { EStorageType } from "common/models/storageType";
export { FormatType } from "common/models/formatType";
import { FormatType } from "common/models/formatType";
import { discardTimeZone } from "common/modules/momentHelper";
import moment from "moment";

const storage = StorageManager.GetInstance();
/*
 * TODO 2.1++: Should use the TimeZone of the system root station.
 * Note: This should work even if the current user does not have access to the root station!
 * Note: we can't really use per system because we don't know what system we are when we read this value
 */
let systemTimeZoneName = <string>storage.retrieve("systemTimezone", EStorageType.LocalStorage, false, "Europe/Stockholm");
Moment.tz.setDefault(systemTimeZoneName);

export function setSystemTimeZoneName(tz: string) {
    if (systemTimeZoneName !== tz) {
        // if we have a new timezone, please store it and refresh the site. Note: we can't really use per system because we don't know what system we are when we read this value
        StorageManager.GetInstance().store("systemTimezone", tz, EStorageType.LocalStorage, true);
        document.location.reload();
    }
    systemTimeZoneName = tz;
}

export function setSystemTimeZoneNameForTests(tz: string) {
    // Version for use in tests, to avoid failing calls to local storage etc.
    systemTimeZoneName = tz;
}

export function format(
    moment: IMoment | undefined,
    formatType?: FormatType,
    timeZoneName?: string,
    /** Temporary fix for timePicker in bookingDetailsView */
    _useMomentTimezone?: boolean,
    prefix?: string
): string {
    let formatString = "";
    let emptyString = "";

    if (!moment) {
        return "";
    }

    switch (formatType) {
        case FormatType.Date:
            formatString = dateFormatString(DateFormat.longWithDash, prefix);
            emptyString = "-";
            break;
        case FormatType.ShortDate:
            formatString = dateFormatString(DateFormat.short, prefix);
            emptyString = "-";
            break;
        case FormatType.DateTime:
            formatString = dateFormatString(DateFormat.longWithDash, prefix) + " HH:mm";
            emptyString = "- --:--";
            break;
        case FormatType.FullDateTime:
            formatString = dateFormatString(DateFormat.longWithDash, prefix) + " HH:mm:ss";
            emptyString = "- --:--:--";
            break;
        case FormatType.Time:
            formatString = dateFormatString(DateFormat.longWithDash, prefix) + " HH:mm";
            emptyString = "- --:--";
            break;
        case FormatType.InternalFullDateTime:
            formatString = "YYYY-MM-DD HH:mm";
            emptyString = "";
            break;
        case FormatType.InternalDate:
            formatString = "YYYY-MM-DD";
            emptyString = "";
            break;
        default:
            formatString = "HH:mm";
            emptyString = "--:--";
            break;
    }

    let momentInTimeZone: IMoment;
    if (timeZoneName) {
        momentInTimeZone = Moment.tz(moment, timeZoneName);
    } else if (_useMomentTimezone) {
        momentInTimeZone = moment;
    } else {
        momentInTimeZone = toSystemTime(moment);
    }

    return momentInTimeZone ? momentInTimeZone.format(formatString) : emptyString;
}

export function formatUtc(moment: IMoment, formatType?: FormatType): string {
    return format(moment, formatType, "UTC");
}

export function toSystemTime(moment: Moment.MomentInput): IMoment {
    return Moment.tz(moment, systemTimeZoneName);
}

export function cloneToSystemTime(moment: IMoment): IMoment {
    return Moment.tz(moment.clone(), systemTimeZoneName);
}

export function toSystemTimeKeepingLocalTime(moment: Moment.MomentInput): IMoment {
    return Moment(moment).tz(systemTimeZoneName, true);
}

export function stringToSystemTime(value: string, formatString: string): Moment.Moment {
    return Moment.tz(value, formatString, systemTimeZoneName);
}

export function toNamedTimeZoneTime(moment: IMoment, timeZoneName: string): IMoment {
    return Moment.tz(moment, timeZoneName);
}

export function getCurrentSystemTime(): IMoment {
    return toSystemTime(Moment());
}

export function toUTC(moment: IMoment): IMoment {
    return Moment.tz(moment, "UTC");
}

export function isSameDate(moment1: IMoment, moment2: IMoment): boolean {
    return toSystemTime(moment1).isSame(toSystemTime(moment2), "day");
}

export function getSystemTimeZoneName(): string {
    return systemTimeZoneName;
}

export function durationToUtcMoment(duration: IDuration): IMoment {
    return toUTC(Moment.utc("1970-01-01")).startOf("day").add(duration);
}

//Returns a string on the form HH:mm:ss
export function durationMomentToHMSString(duration: IDuration): string {
    if (!duration) {
        return "00:00:00";
    }
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const hoursString = zeroPad(hours);
    const minutesString = zeroPad(minutes);
    const secondsString = zeroPad(seconds);
    return hoursString + ":" + minutesString + ":" + secondsString;
}

//Returns a string on the form HH:mm
export function durationMomentToHMString(duration: Moment.Duration | undefined): string {
    if (!duration) {
        return "00:00";
    }
    const days = duration.days();
    const hours = duration.hours() + days * 24;
    const minutes = duration.minutes();
    const hoursString = zeroPad(hours);
    const minutesString = zeroPad(minutes);
    return hoursString + ":" + minutesString;
}

export function hoursAndMinutesToDecimalForm(hour: number, minute: number): string {
    const hourInt = hour || 0;
    const minuteInt = minute || 0;
    const minutePartInHours = minuteInt / 60;
    const resultInHours = hourInt + minutePartInHours;
    const roundedResult = Math.floor(0.5 + resultInHours * 100) / 100;

    // TODO: This should not be done if we want to format decimal with period
    let result = ("" + roundedResult).replace(".", ",");
    if (result.indexOf(",") < 0) {
        result = result + ",00";
    } else if (result.split(",")[1].length < 2) {
        // If we only have one decimal number
        result = result + "0";
    }
    return result;
}

/**
 * Will format fromMoment and toMoment to YYYY-MM-DD and then return a string key for the range.
 * @returns "string key" for the date range
 */

export function getDateRangeKey(fromMoment: IMoment, toMoment: IMoment) {
    const formattedFromDate = fromMoment.startOf("D").format("YYYY-MM-DD");
    const formattedToDate = toMoment.startOf("D").format("YYYY-MM-DD");
    return `${formattedFromDate}-${formattedToDate}`;
}

export function durationFromMinutes(minutes: number): Moment.Duration {
    const [hour, minute] = minutesToHoursAndMinutes(minutes);
    return Moment.duration({ hours: hour, minutes: minute });
}

export function durationToHoursAndMinutes(duration: string): number[] {
    let durationString = "" + duration;
    let durationForm = getDurationForm(durationString);
    const hoursAndMinutes = [];
    let hour;
    let minute;
    if (durationForm === DurationForm.Comma) {
        //Reusing conversion function
        durationString = getLetterFormFromDecimalForm(durationString);
        durationForm = DurationForm.Letter;
    }
    if (durationForm === DurationForm.Colon) {
        if (durationString.indexOf(":") > -1) {
            [hour, minute] = durationString.split(":");
        } else {
            [hour, minute] =
                durationString.length === 4 ? [durationString.substring(0, 2), durationString.substring(2, 4)] : [durationString.substring(0, 1), durationString.substring(1, 3)];
        }
    } else if (durationForm === DurationForm.Letter) {
        const letterParts = durationString.toLowerCase().split("h");
        [hour, minute] = letterParts.length === 2 ? letterParts : ["0", letterParts[0]];
    } else {
        return [0, 0];
    }
    hoursAndMinutes.push(parseInt(hour, 10));
    hoursAndMinutes.push(parseInt(minute ? minute : "0", 10));
    return hoursAndMinutes;
}

export function millisecondsToHoursAndMinutes(milliseconds: number): number[] {
    const hoursAndMinutes: number[] = [];
    const hours = Math.floor(milliseconds / 3600000);
    const remainingDuration = milliseconds - hours * 3600000;
    const minutes = Math.floor(remainingDuration / 60000);
    hoursAndMinutes.push(hours);
    hoursAndMinutes.push(minutes);
    return hoursAndMinutes;
}

export function minutesToHoursAndMinutes(minutes: number): number[] {
    const hoursAndMinutes: number[] = [];
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes - hours * 60;
    const minutePart = Math.floor(remainingMinutes);
    hoursAndMinutes.push(hours);
    hoursAndMinutes.push(minutePart);
    return hoursAndMinutes;
}

//Preferably use a duration as argument, but moment might work too. (Not entirely sure anymore.)
export function durationMomentToDecimalForm(duration: Moment.Duration | undefined): string {
    if (!duration) {
        return "0,00";
    }
    const hours = duration.hours() + duration.days() * 24;
    const minutes = duration.minutes();
    return hoursAndMinutesToDecimalForm(hours, minutes);
}

export function durationToDecimalForm(duration: string, durationForm?: DurationForm): string {
    if (!durationForm) durationForm = getDurationForm(duration);
    if (durationForm === DurationForm.Comma) {
        const formattedDuration = duration.split(duration.indexOf(",") >= 0 ? "," : ".");
        if (!(formattedDuration.length > 1)) {
            return formattedDuration[0] + ",00";
        } else if (!(formattedDuration[1].length > 1)) {
            return duration + "0";
        }
        return duration;
    } else {
        const hoursAndMinutes = durationToHoursAndMinutes(duration);
        return hoursAndMinutesToDecimalForm(hoursAndMinutes[0], hoursAndMinutes[1]);
    }
}

export enum DurationForm {
    Colon = "colon",
    Letter = "letter",
    Comma = "comma",
}

export function getDurationForm(duration: string): DurationForm | undefined {
    if (!duration) return undefined;
    duration = duration.toLowerCase();
    if (duration.match(/(^(\d*):([0-5]\d)?$)/)) return DurationForm.Colon; //Checks if the form is X:Y
    if ((duration.indexOf("h") >= 0 || duration.indexOf("m")) >= 0 && duration.match(/(^(\d*)h?([0-5]?\d)?m?$)/)) return DurationForm.Letter; //Checks if the form is XhYm TODO: Allow for space?
    if (duration.match(/(\d*[,\.]\d+)/))
        //Checks if the form is X,Y TODO: Allow for English notation
        return DurationForm.Comma;
    if (duration.match(/(^(\d*)([,\.]\d+)?$)/)) {
        if (duration.length > 2) return DurationForm.Colon; //Meant X:Y, 130 = 1:30
        return DurationForm.Comma; //Checks if the form is X,Y || XY TODO: Allow for English notation
    } else {
        return undefined;
    }
}

export function getDotNotationFromDecimalForm(duration: string): number {
    let durationNumber = 0;
    if (getDurationForm(duration) === DurationForm.Comma) {
        const dotString = duration.replace(",", ".");
        durationNumber = parseFloat(dotString);
        if (isNaN(durationNumber)) {
            return 0; //TODO: Decide what should be returned here.
        }
    }
    return durationNumber;
}

export function getLetterFormFromDecimalForm(duration: string): string {
    if (getDurationForm(duration) === DurationForm.Comma) {
        const totalHours = getDotNotationFromDecimalForm(duration);
        const hours = Math.floor(totalHours);
        const minutes = Math.round((totalHours - hours) * 60);
        return hours + "h " + minutes + "m";
    }
    return "";
}

export function getLetterForm(duration: Moment.Duration): string {
    const hours = duration.hours() + duration.days() * 24;
    const minutes = duration.minutes();
    return hours + "h " + minutes + "m";
}

export function formatTime(hours: number, minutes: number): string {
    return `${zeroPad(hours)}:${zeroPad(minutes)}`;
}

export function getUtcDateTime(isoDate: string, hours: number, minutes: number): IMoment {
    const localTimePoint = formatTime(hours, minutes);
    const localTime = stringToSystemTime(`${isoDate} ${localTimePoint}`, "YYYY-MM-DD HH:mm");
    return toUTC(localTime);
}

/**
 * Coerce a moment to UTC
 *
 * @param {IMoment} date
 * @returns {IMoment}
 */
export function forceUtc(date: IMoment): IMoment {
    const offset = date.utcOffset();
    return date.clone().utc().add(offset, "minutes");
}

// Same as Moment.add, but works properly with adding over DST.
// Also, will create a copy of date instead of mutating it.
export function add(m: IMoment, x: number, unit: Moment.unitOfTime.DurationConstructor): IMoment {
    return !m.isUTC() ? Moment(m).add(x, unit) : toUTC(toSystemTime(m).add(x, unit));
}

interface IDatetimeRangeInfo {
    weeks: number;
    days: number;
    fromDate: IMoment | null;
    toDate: IMoment | null;
}

export function weeksBetweenDates(datetimeRange: DatetimeRange): IDatetimeRangeInfo {
    const fromDate = datetimeRange.getFromMoment();
    const toDate = datetimeRange.getToMoment(true);
    if (!fromDate || !toDate) {
        return {
            weeks: 0,
            days: 0,
            fromDate,
            toDate,
        };
    }
    let weeks = !fromDate.isSame(toDate, "week") ? Math.abs(toDate.clone().startOf("day").diff(fromDate.clone().startOf("day"), "weeks")) + 1 : 1;
    const days = !fromDate.isSame(toDate, "day") ? Math.abs(toDate.clone().startOf("day").diff(fromDate.clone().startOf("day"), "days")) + 1 : 1;
    if (fromDate.clone().add(weeks, "weeks").isSame(toDate, "week")) {
        weeks++;
    }
    return {
        weeks,
        days,
        fromDate,
        toDate,
    };
}

/**
 * Gets dates as an array of moments between two dates
 * @param fromDate from inclusive
 * @param toDate to exclusive
 */
export function datesBetweenDates(fromDate: IMoment, toDate: IMoment): IMoment[] {
    if (!fromDate && !toDate) return [];
    const dates: IMoment[] = [];
    const day = fromDate.clone();
    // We do not want to include minutes/seconds when comparing
    while (day.isBefore(toDate, "day")) {
        dates.push(Moment(day.clone()));
        day.add(1, "days");
    }
    return dates;
}

/** Takes string on format hh:mm:ss or hh:mm and returns number of minutes floored */
export function minutesFromHHMMSS(input: string): number {
    var split = input.split(":");
    return +split[0] * 60 + +split[1];
}

export function formatTimeRelative(time: IMoment, now: IMoment): string {
    const localTime = toSystemTime(time);
    let timestamp = localTime.format("YYYY-MM-DD");
    if (localTime.isSame(now, "day")) {
        timestamp = localTime.format("HH:mm");
    } else if (time > now.subtract(7, "days")) {
        timestamp = localTime.format("dddd");
    }
    return timestamp;
}

/**
 * Compares time-parts only
 *
 * @export
 * @param {IMoment} time1
 * @param {("greater than" | "less than" | "equal")} operator
 * @param {IMoment} time2
 * @param {boolean} [adjustAfterMidnight=false]
 * If true, it adds 24 to hours if it is between 0 and 6, 2am => 2 + 24 = 26
 * @returns {boolean}
 */
export function compareTime(time1: IMoment, operator: "greater than" | "less than" | "equal", time2: IMoment, adjustAfterMidnight: boolean = false): boolean {
    const _time1 = discardTimeZone(time1) || moment();
    const _time2 = discardTimeZone(time2) || moment();
    const time1Hours = adjustAfterMidnight ? (_time1.get("hour") >= 0 && _time1.get("hour") <= 6 ? _time1.get("hour") + 24 : _time1.get("hour")) : _time1.get("hour");
    const time2Hours = adjustAfterMidnight ? (_time2.get("hour") >= 0 && _time2.get("hour") <= 6 ? _time2.get("hour") + 24 : _time2.get("hour")) : _time2.get("hour");
    const time1String = zeroPad(time1Hours) + zeroPad(_time1.get("minute")) + zeroPad(_time1.get("second"));
    const time2String = zeroPad(time2Hours) + zeroPad(_time2.get("minute")) + zeroPad(_time2.get("second"));

    switch (operator) {
        case "equal":
            return time1String === time2String;
        case "greater than":
            return time1String > time2String;
        case "less than":
            return time1String < time2String;

        default:
            return false;
    }
}

/**
 * Get offset difference between two timezones
 * @param tz1 ex. "Europe/Tallinn"
 * @param tz2 defaults to "Europe/Stockholm" if left out
 * @returns minutes between timezones
 */
export function offsetBetweenTimeZones(tz1: string, tz2?: string): number {
    const offsetTz1 = moment.tz(tz1).parseZone().utcOffset();
    const offsetTz2 = moment
        .tz(tz2 ?? "Europe/Stockholm")
        .parseZone()
        .utcOffset();

    return offsetTz1 - offsetTz2;
}

/** Check whether a given time (now) is in a given business day */
export function isSameBusinessDay(businessDay: IMoment, dayBreakHour: number = 5, comparisonTime: IMoment = moment()): boolean {
    const isAfterMidnight: boolean = comparisonTime.hours() < dayBreakHour;

    const dayClose = comparisonTime.clone().hours(0).minutes(0).seconds(0).milliseconds(0);
    // Count as previous day if between midnight and daybreak
    dayClose.add((isAfterMidnight ? 0 : 24) + dayBreakHour, "hours");

    const dayOpen = dayClose.clone().subtract(1, "days");

    return businessDay.isBetween(dayOpen, dayClose);
}
