import * as StationActions from "application/actions/stationActions";
import * as Immutable from "immutable";

import { IStationValues, Station } from "application/models/station";
import { APIBase, IAPIResult } from "framework/modules/apiBase";

interface IStationAPI {
    load(): void;
    loadForSystem(system: string): void;
    create(station: Station): Promise<IAPIResult<IStationValues>>;
    update(station: Station, commitMessage: string | null, createParent?: boolean): Promise<IAPIResult<IStationValues>>;
    deleteStation(station: Station): void;
    check(station: Station, fromDate: IMoment): void;
    checkStationAllowDelete(station: Station): void;
    move(station: Station, newParentId: number, commitMessage?: string): Promise<IAPIResult<IStationValues[]>>;
    remove(station: Station, destinationId: number, commitMessage?: string): Promise<IAPIResult<IStationValues[]>>;
    checkHasScheduleShifts(stationIds: Immutable.Set<number>, validFrom: IMoment): Promise<boolean>;
    getStationWhenHandleOwnHours(fromDateInclusive: IMoment, toDateExclusive: IMoment): Promise<Immutable.List<Station>>;
}

class StationAPIImpl extends APIBase implements IStationAPI {
    constructor() {
        super();
        this.setApiVersion("v1");
    }

    load() {
        const pendingOperation = { method: this.load, payload: arguments };
        this.loadPending(pendingOperation);

        this.get<IStationValues[]>("stations")
            .then(
                (result) => {
                    const data = result.data;
                    this.loadSuccess(pendingOperation);
                    new StationActions.LoadedAllSuccessfully(Immutable.List(data));
                },
                (fail) => {
                    this.loadFailed(pendingOperation, fail);
                }
            )
            .catch((errorObj) => {
                this.loadFailed(pendingOperation, errorObj);
                return errorObj;
            });
    }

    loadForSystem(system: string) {
        const pendingOperation = { method: this.loadForSystem, payload: arguments };
        this.loadPending(pendingOperation);

        this.get<IStationValues[]>("stations?systemName=" + system)
            .then(
                (result) => {
                    const data = result.data;
                    this.loadSuccess(pendingOperation);
                    const stations = Immutable.List(data);
                    const mappy = Immutable.Map<string, Immutable.List<IStationValues>>().set(system, stations);
                    new StationActions.LoadedAllBySystemSuccessfully(mappy, system);
                },
                (fail) => {
                    this.loadFailed(pendingOperation, fail);
                }
            )
            .catch((errorObj) => {
                this.loadFailed(pendingOperation, errorObj);
                return errorObj;
            });
    }

    create(station: Station): Promise<any> {
        const pendingOperation = { method: this.create, payload: station };
        this.loadPending(pendingOperation);

        return this.post("stations", { data: station.toDTORecord() }).then(
            (result) => {
                const data = result.data;
                this.loadSuccess(pendingOperation);
                const createdStation = new Station(data);
                new StationActions.CreatedSuccessfully(station.id, createdStation);
                return result;
            },
            (errorObj) => {
                this.loadFailed(pendingOperation, errorObj);
                new StationActions.CreateFailed(station);
                return errorObj;
            }
        );
    }

    update(station: Station, commitMessage: string | null, createParent: boolean = false) {
        const pendingOperation = { method: this.update, payload: station };
        this.loadPending(pendingOperation);

        const url = createParent ? "stations/updateAndCreateParent/{0}" : "stations/updateWithMessage/{0}";

        return this.put<IStationValues>(url, {
            params: [station.id.toString()],
            data: { station: station.toDTORecord(), commitMessage: commitMessage ?? "" },
        }).then(
            (result) => {
                const data = result.data;
                this.loadSuccess(pendingOperation);
                const updatedStation = new Station(data);
                new StationActions.UpdatedSuccessfully(updatedStation);
                return result;
            },
            (errorObj) => {
                this.loadFailed(pendingOperation, errorObj);
                new StationActions.UpdateFailed(station);
                return errorObj;
            }
        );
    }

    deleteStation(station: Station) {
        const pendingOperation = { method: this.deleteStation, payload: station };
        this.loadPending(pendingOperation);
        this.delete("stations/{0}", { params: [station.id.toString()] }).then(
            (_result) => {
                new StationActions.DeletedSuccessfully(station);
            },
            (_errorObj) => {
                new StationActions.DeleteFailed(station);
            }
        );
    }

    check(station: Station, fromDate: IMoment) {
        const pendingOperation = { method: this.check, payload: station };
        this.loadPending(pendingOperation);
        this.get("stations/check?stationId={0}&fromDate={1}", { params: [station.id.toString(), fromDate.toISOString()] }).then(
            (result) => {
                this.loadSuccess(pendingOperation);
                new StationActions.CheckSuccess(result.data);
            },
            (errorObj) => {
                this.loadFailed(pendingOperation, errorObj);
                new StationActions.CheckFail(station);
            }
        );
    }

    checkStationAllowDelete(station: Station) {
        const pendingOperation = { method: this.checkStationAllowDelete, payload: station };
        this.loadPending(pendingOperation);
        this.get("stations/checkStationAllowDelete?stationId={0}", { params: [station.id.toString()] }).then(
            (result) => {
                this.loadSuccess(pendingOperation);
                new StationActions.CheckAllowDeleteSuccess(result.data, station.id);
            },
            (errorObj) => {
                this.loadFailed(pendingOperation, errorObj);
                new StationActions.CheckAllowDeleteFail(station);
            }
        );
    }

    move(station: Station, newParentId: number, commitMessage?: string) {
        const pendingOperation = { method: this.move, payload: [station, newParentId] };
        this.loadPending(pendingOperation);
        return this.post<IStationValues[]>("stations/move", { data: { station: station.toDTORecord(), targetStationId: newParentId, commitMessage: commitMessage ?? "" } }).then(
            (result) => {
                this.loadSuccess(pendingOperation);
                return result;
            },
            (errorObj) => {
                this.loadFailed(pendingOperation, errorObj);
                return errorObj;
            }
        );
    }

    remove(station: Station, destinationId: number, commitMessage?: string) {
        const pendingOperation = { method: this.move, payload: [station, destinationId] };
        this.loadPending(pendingOperation);
        return this.post<IStationValues[]>("stations/remove", { data: { station: station.toDTORecord(), targetStationId: destinationId, commitMessage: commitMessage ?? "" } }).then(
            (result) => {
                this.loadSuccess(pendingOperation);
                return result;
            },
            (errorObj) => {
                this.loadFailed(pendingOperation, errorObj);
                return errorObj;
            }
        );
    }

    public checkHasScheduleShifts(stationIds: Immutable.Set<number>, validFrom: IMoment): Promise<boolean> {
        return this.getAsync<boolean, boolean>(`stations/checkHasScheduleShifts?stationIds=${stationIds.join("&stationIds=")}&validFrom=${validFrom.toISOString()}`, (d) => d);
    }

    public getStationWhenHandleOwnHours(fromDateInclusive: IMoment, toDateExclusive: IMoment): Promise<Immutable.List<Station>> {
        return this.getAsync("stations/handleOwnHours?fromDateInclusive={0}&toDateExclusive={1}", (data: Station[]) => Immutable.List(data.map((station) => new Station(station))), {
            params: [fromDateInclusive.format("YYYY-MM-DD"), toDateExclusive.format("YYYY-MM-DD")],
        });
    }
}

const StationAPI: IStationAPI = new StationAPIImpl();
export default StationAPI;
