import { IDiff } from "common/modules/diffHelper";
import { Record } from "immutable";

export interface ICostCenterValues {
    number: string;
    name: string;
}

const CostCenterRecord = Record({
    number: null,
    name: null,
});

export class CostCenter extends CostCenterRecord implements ICostCenterValues {
    readonly number: string;
    readonly name: string;

    diff(before: CostCenter, prefix: string = "") {
        let diffs: IDiff[] = [];
        const toSkip: string[] = [];
        this.keySeq().forEach((key) => {
            if (toSkip.includes(key)) {
                return true;
            }
            const propAfter = this.get(key);
            const propBefore = before && before.get(key);
            if (propAfter && propAfter.diff) {
                diffs = diffs.concat(propAfter.diff(propBefore, [prefix, key].join(".")));
                return true;
            }
            if (propBefore !== propAfter) {
                diffs.push({ key: prefix + "." + key, before: propBefore, after: propAfter });
            }
            return true;
        });
        return diffs;
    }
}
