import * as Moment from "moment-timezone";

import { IDiff, DiffValue } from "common/modules/diffHelper";
import { toSystemTime } from "common/modules/timeHelper";
import { IStationSettingsValues, StationSettings } from "./stationSettings";
import { RecordFactory } from "common/modules/recordFactory";

// ----------- StationProperties -----------
export interface IStationPropertiesValues {
    id: number;
    legacyBookingUnitId?: number;
    name: string | null;
    businessDateBreakTime?: IDuration | string;
    validFrom?: IMoment | string;
    settings?: IStationSettingsValues;
    isActive?: boolean;
    stationId?: number;
}

const StationPropertiesRecord = RecordFactory<IStationPropertiesValues>({
    id: 0,
    legacyBookingUnitId: undefined,
    name: null,
    businessDateBreakTime: undefined,
    validFrom: undefined,
    settings: new StationSettings({}),
    isActive: true,
    stationId: -1,
});

export class StationProperties extends StationPropertiesRecord implements IStationPropertiesValues {
    readonly id: number;
    readonly legacyBookingUnitId: number;
    readonly name: string;
    readonly businessDateBreakTime: IDuration;
    readonly validFrom: IMoment;
    readonly settings: StationSettings;
    readonly isActive: boolean;
    readonly stationId: number;
    constructor(values: Readonly<Partial<IStationPropertiesValues>>) {
        let modifiedValues: Partial<IStationPropertiesValues> = {};
        if (values.validFrom && !Moment.isMoment(values.validFrom)) {
            modifiedValues.validFrom = toSystemTime(values.validFrom);
        }
        if (values.settings && !(<StationSettings>values.settings).set) {
            modifiedValues.settings = new StationSettings(values.settings);
        }
        if (values.businessDateBreakTime) {
            modifiedValues.businessDateBreakTime = Moment.duration(values.businessDateBreakTime);
        }
        super({ ...values, ...modifiedValues });
    }

    diff(before: StationProperties | null, prefix: string = "station") {
        let diffs: IDiff[] = [];
        const toSkip = ["subNodes", "children", "properties", "id", "stationId", "validFrom"];
        //This is currently not used, so we remove it from the diff
        //toSkip.push("businessDateBreakTime");
        this.keySeq().forEach((key) => {
            if (toSkip.includes(key)) {
                return true;
            }
            const propAfter = this.get(key);
            const propBefore = before && before.get(key);
            if (propAfter && (<any>propAfter).diff) {
                diffs = diffs.concat((<any>propAfter).diff(propBefore, [prefix, key].join(".")));
                return true;
            }
            if (propBefore !== propAfter) {
                diffs.push({
                    key: prefix + "." + key,
                    before: <DiffValue>propBefore,
                    after: <DiffValue>propAfter,
                });
            }
            return true;
        });
        return {
            entity: this.name,
            diffs: diffs,
        };
    }
}
