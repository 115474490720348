import * as SystemMetaInformation from "framework/models/systemMetaInformation";

import { autoSelectSystem } from "application/actionCreators/systemActionCreators";
import { EStorageType, StorageManager } from "application/storageManager/storageManager";

export function handleUrlParameters() {
    const urlParams = new URLSearchParams(window.location.search);
    const system = urlParams.get("system");

    const storageManager = StorageManager.GetInstance();
    const existingSystem = storageManager.retrieve("user.settings.initSystem", EStorageType.LocalStorage, true);
    storageManager.remove("user.settings.initSystem", EStorageType.LocalStorage, true);

    if (system) {
        autoSelectSystem(system);
    } else if (existingSystem) {
        const selectedSystem = SystemMetaInformation.Record(JSON.parse(existingSystem));
        autoSelectSystem(selectedSystem.name);
    }
}
