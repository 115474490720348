import * as Immutable from "immutable";

import Trace from "trace";

import { AppInsights } from "applicationinsights-js";
import { cookie } from "browser-cookie-lite";

class InsightsSingleton {
    private _properties: Immutable.Map<string, string>;
    private _measurements: Immutable.Map<string, number>;
    private readonly isCloud: boolean;
    private readonly isTrackingDev: boolean;
    private _subject: string;
    private _system: string;

    constructor() {
        this._properties = Immutable.Map<string, string>();
        this._measurements = Immutable.Map<string, number>();

        const cookieName: string = "RP-Deployment";
        const cookieValue = cookie(cookieName);
        // const cookieLength: number = cookieName.length + 1;
        // const cookieValue: string = document.cookie
        //                                 .split(";")
        //                                 .map(c => c.trim())
        //                                 .filter(cookie =>  {
        //                                     const substring: string = cookie.substring(0, cookieLength)
        //                                     const result: boolean = substring === `${cookieName}=`;
        //                                     return result;
        //                                 })
        //                                 .map(cookie => decodeURIComponent(cookie.substring(cookieLength)))[0] || null;

        this.isCloud =
            (document.location.hostname === "app.caspeco.se" || document.location.hostname === "cloud.caspeco.se" || document.location.hostname === "unsecure.caspeco.se") &&
            cookieValue !== "trackingdev";
        this.isTrackingDev = cookieValue === "trackingdev" || cookieValue === "trackingdev-as-ne" || document.location.hostname === "webplatform-trackingdev.azure.caspeco.com";

        let iKey: string = "";
        if (this.isCloud)
            // DevOps Insights
            iKey = "8e62e7e3-9410-4ece-bfe5-c4c37940dafe";
        else if (this.isTrackingDev)
            // Trackigndev Insights
            iKey = "a3846a97-f258-4d0b-8bd9-fa3f3b1800f1";

        // TODO: Remove this after it has proved itself, at the end of today (2017-08-02)
        if (this.isCloud) Trace.verbose("Application Insights: Reporting to devops-insights");
        else if (this.isTrackingDev) Trace.verbose("Application Insights: Reporting to trackingdev-insights");
        else Trace.verbose("Application Insights: Localhost - reporting nowhere! (:");

        AppInsights.queue.push(() => {
            AppInsights.context.addTelemetryInitializer((envelope: Microsoft.ApplicationInsights.IEnvelope) => {
                const telemetryItem = (<any>envelope).data.baseData;
                // To set custom properties:
                telemetryItem.properties = telemetryItem.properties || {};
                this._properties.forEach((value, name) => {
                    telemetryItem.properties[name] = value;
                });

                // To set custom metrics:
                telemetryItem.measurements = telemetryItem.measurements || {};
                this._measurements.forEach((value, name) => {
                    telemetryItem.measurements[name] = value;
                });

                return true;
            });
        });

        AppInsights.downloadAndSetup?.({ instrumentationKey: iKey });
        AppInsights.trackPageView();
    }

    shouldSend() {
        return this.isCloud || this.isTrackingDev;
    }

    setUser(subject: string) {
        this._subject = subject;
        AppInsights.setAuthenticatedUserContext(this._subject, this._system);
    }

    setSystem(system: string) {
        this._system = system;
        AppInsights.setAuthenticatedUserContext(this._subject, this._system);
    }

    setCustomAttribute(name: string, value: any) {
        this._properties = this._properties.set(name, value);
    }

    /**
     * track an event. Use for tracking business events
     * @param name name of the event, e.g "booking.created.success"
     * @param data custom data related to this event. (note: UserId and system is already added)
     */
    trackEvent(name: string, data?: any) {
        if (this.shouldSend()) {
            AppInsights.trackEvent(name, data);
            Trace.info("trackEvent", name, data);
        } else {
            Trace.info("trackEvent (not sent)", name, data);
        }
    }

    /**
     * Tracks a pageview. Use for tracking navigation
     * @param name
     * @param urlRoute
     */
    trackPageView(name: string, urlRoute: string = "") {
        const send = this.shouldSend();
        if (urlRoute === "" && window.location && window.location.origin) urlRoute = window.location.origin;

        const properties: { [name: string]: string } = {};

        // measure viewport of device (actual size of browser rather than theoretical screen resolution)
        properties["device.viewport"] =
            (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) +
            "x" +
            (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);

        if (send) AppInsights.trackPageView(name, urlRoute, properties);

        Trace.verbose(`Tracking PageView for ${name} @ ${urlRoute} (sent? ${send})`, properties);
    }

    /**
     * Tracks exceptions.
     * @param error
     */
    trackException(error: Error) {
        if (this.shouldSend()) {
            AppInsights.trackException(error);
        } else {
            Trace.info("trackException (not sent)", error);
        }
    }

    /**
     * Logs a metric and pauses before logging another value of the same metric
     * @param name name of the metric, e.g. `"shifts loaded"`
     * @param value value of the metric, e.g. `10,1`
     * @param samplePause Only report metric if `samplePause` seconds have elapsed since last report (seconds, default: 3600)
     */
    sampleMetric(name: string, value: number, samplePause: number = 3600) {
        var now = Math.floor(new Date().getTime() / 1000);
        var threshold = now - samplePause;

        var last = this.getSampleMetricHistory(name, samplePause);

        // if last sample is not old enough. Threshold need to be larger than last to continue.
        if (last && threshold < last) {
            return;
        }

        if (this.shouldSend()) {
            AppInsights.trackMetric(name, value);
        } else {
            Trace.info("sampleMetric (not sent)", name, value);
        }

        this.setSampleMetricHistory(name, samplePause);
    }
    private getSampleMetricHistory(name: string, samplePause: number): number {
        var last = this.sampleMetricHistory[name];

        if (!last && samplePause > this.PERSIST_LIMIT) {
            const savedLast = localStorage.getItem("x_insight_" + name);
            if (savedLast) {
                last = parseInt(savedLast);
            }
        }

        return last;
    }
    private setSampleMetricHistory(name: string, samplePause: number): void {
        var now = Math.floor(new Date().getTime() / 1000);

        this.sampleMetricHistory[name] = now;

        if (samplePause > this.PERSIST_LIMIT) {
            localStorage.setItem("x_insight_" + name, now.toString());
        }
    }

    private sampleMetricHistory: { [name: string]: number } = {};
    private PERSIST_LIMIT = 10; // seconds
}
const Insights = new InsightsSingleton();
export default Insights;
