import { IRefreshTokenResponse, TRefreshTokenHandle } from "../api/refreshToken.interface";

import Action from "./action";

export class LoginSuccess extends Action {
    static actionType: string = "user_login_success";

    response: IRefreshTokenResponse;

    constructor(payload: IRefreshTokenResponse) {
        super(false);
        this.response = payload;
        this.doDispatch();
    }
}

export class Logout extends Action {
    static actionType: string = "user_logout";

    refreshTokenHandle: TRefreshTokenHandle | null;

    constructor(payload: TRefreshTokenHandle | null) {
        super(false);
        this.refreshTokenHandle = payload;
        this.doDispatch();
    }
}

export class LoginError extends Action {
    static actionType: string = "user_login_error";

    username: string;
    password: string;
    error_desc: string;

    constructor(payload: any, error_desc: string) {
        super(false);
        this.error_desc = error_desc;
        console.log("Bad login", payload);
        this.doDispatch();
    }
}
