import Action from "framework/actions/action";
import { IAPIOperation } from "framework/modules/apiBase";

export class LoadPending extends Action {
    static actionType: string = "load_pending";

    operation: IAPIOperation;

    constructor(operation: IAPIOperation) {
        super(false);
        this.operation = operation;
        this.doDispatch();
    }
}

export class LoadSuccess extends Action {
    static actionType: string = "load_sucess";

    operation: IAPIOperation;

    constructor(operation: IAPIOperation) {
        super(false);
        this.operation = operation;
        this.doDispatch();
    }
}

export class LoadFail extends Action {
    static actionType: string = "load_fail";

    operation: IAPIOperation;

    constructor(operation: IAPIOperation) {
        super(false);
        this.operation = operation;
        this.doDispatch();
    }
}
