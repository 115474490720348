import isBool from "./isBool";

type PrefixFunc = (text: string, condition?: boolean) => string;

/**
 * Used to prefix as string:
 * @param prefixString string to prefix
 * @example
 *
 * import _pf_ from "common/modules/prefixer";
 * const pf_ = _pf_("myPrefix_");
 *
 * import _pfDash from "common/modules/prefixer";
 * const pfDash = _pfDash("myPrefix-");
 *
 * const myString1 = pf_("topBar"); // myPrefix_topBar
 * const myString2 = pf_("button"); // myPrefix_button
 * const myString3 = pfDash("active"); // myPrefix-active
 *
 * const myString4 = pfDash("active",true); // myPrefix-active
 * const myString5 = pfDash("active",false); // [EMPTY STRING]
 */
export default function prefix(prefixString: string): PrefixFunc {
    return (text: string, condition?: boolean): string => {
        const hasCondition = isBool(condition);
        if (hasCondition && !condition) {
            return "";
        }
        return prefixString + text;
    };
}
