import cx from "classnames";
import * as React from "react";

import Flex from "ui/components/flex/flex";
import { BorderRadiusSize } from "ui/components/shared/types";
import { IMarginProps, Spacer } from "ui/components/spacer/spacer";
import { Spinner } from "ui/components/spinner/spinner";

import "./card.less";

type TitleStyleType = "secondary";

type ShadowType = "application" | "dialogue" | "separating" | "sharp" | "subtle" | "subtleBlurry";

export interface ICardProps extends IMarginProps {
    /** Required id. */
    id: string;
    /** Id for the container of the card. */
    containerId?: string;
    /** Center the card content. */
    centerContent?: boolean;
    className?: string;
    /** Sets card content to flex start */
    flexstart?: boolean;
    /** Sets height of card to 100%, defaults to false. */
    fillParentHeight?: boolean;
    /** Sets width of cardwrapper to 100%, defaults to false. */
    fillParentWidth?: boolean;
    /** Render a loading spinner inside the card while true. */
    isLoadingContent?: boolean;
    /** onClick for entire Card surface. */
    onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    /** Adds a drop shadow to the card. */
    shadow?: ShadowType;
    /** Adds a border 1px grey border to the card. Used if you want nested cards. Default is false. */
    showBorder?: boolean;
    /** Set card border radius. Default is "large". See visual alternative examples in Casper > "Design Guidelines" > "Border Radius" */
    borderRadius?: BorderRadiusSize;
    /** Title displayed above other content in card. Left aligned by default, will be centered if "centerContent" is true. */
    title?: string;
    /** Optional title underline. Default is false. */
    titleUnderlined?: boolean;
    /** Set no padding inside card. */
    noPadding?: boolean;
    /**
     * Choose what kind of title style you want. You can choose between "default" or "secondary". Secondary is smaller and with another subtle color which is better for cards within cards.
     * Default style is "default".
     */
    titleStyle?: TitleStyleType;
    /**
     * Optional JSX element placed next to the card title. For ex. an icon.
     * See example usage in Home > News.
     */
    titleComponent?: JSX.Element;
    /**
     * Flex between title and titleComponent. Only works if all three props are valid.
     */
    titleFlex?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
}

export const Card: React.FC<PropsWithImmutableChildren<ICardProps>> = (props) => {
    const getCardClass = () => {
        return cx({
            [`shadow-${props.shadow}`]: props.shadow,
            [`br-${props.borderRadius}`]: true,
            centered: props.centerContent,
            flexstart: props.flexstart,
            clickable: props.onClick,
            fillParentHeight: props.fillParentHeight,
            border: props.showBorder,
            noPadding: props.noPadding,
            [props.className || ""]: props.className,
        });
    };

    const spacerProps = () => {
        return {
            m: props.m,
            mx: props.mx,
            my: props.my,
            mt: props.mt,
            mb: props.mb,
            ml: props.ml,
            mr: props.mr,
        };
    };

    const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        return props.onClick ? props.onClick(e) : undefined;
    };

    const renderTitle = () => {
        if (!props.titleComponent && !props.title) return null;
        return (
            <div className={`cardTitle ${(props.title && props.titleStyle) || ""}`}>
                <Flex justify={props.title ? props.titleFlex : "flex-start"} fillParentWidth={props.title && props.titleFlex ? true : false}>
                    {props.title}
                    {props.titleComponent}
                </Flex>
            </div>
        );
    };

    const renderContent = () => {
        if (props.isLoadingContent)
            return (
                <div className={"card_spinnerWrapper"}>
                    <Spinner />
                </div>
            );
        return props.children;
    };

    return (
        <Spacer id={props.containerId} fillParentWidth={props.fillParentWidth} fillParentHeight={props.fillParentHeight} {...spacerProps()}>
            <div id={props.id} className={`card ${getCardClass()}`} onClick={handleOnClick}>
                {renderTitle()}
                {props.titleUnderlined && <hr />}
                {renderContent()}
            </div>
        </Spacer>
    );
};

Card.defaultProps = {
    borderRadius: "large",
};
