import * as React from "react";

import css from "common/modules/cssBuilder";

import { Icon, IconStyle, IconScale } from "ui/components/icon/icon";
import { IStackIconProps } from "ui/components/stackIcon/stackIcon";
import { RenderModeContext } from "common/contexts/renderModeContext";
import { SpinnerCircle } from "ui/components/spinnerCircle/spinnerCircle";
import { Text } from "ui/components/text/text";
import { Flex } from "ui/components/flex/flex";

import "./button.less";

export type ButtonType = "primary" | "secondary" | "danger" | "label";

export type SizeType = "small" | "medium" | "large" | "x-large";

type IconPlacementType = "left" | "right";

export type ILoadingOptions = {
    isLoading: boolean | undefined;
    loadingText?: string;
};
export interface IButtonProps<T> {
    /** This is an id. */
    id: string;
    autotestid?: string;
    /** This is a column. */
    column?: string;
    /** Changes button appearance. Types available are "primary", "secondary", "danger" or "label". */
    type?: ButtonType;
    /** Used internally when button is activated. Key press activates action, for example closing a dropdown with "Esc"  */
    onKeyDownCallback?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
    /** Changes appearance through css. */
    style?: React.CSSProperties;
    /** Button text. */
    text?: string;
    /** If element is focused and used for sequential keyboard navigation.
     * Negative value = possible to focus element, but not when tabbing between elements.
     * 0 = focus in sequential keyboard navigation, order defined by documents source order.
     * Positive value = focusable in sequential keyboard order defined by chosen value.
     */
    tabIndex?: number;
    /** Specifies the type of button used.
     * Can be set to button - which makes it a clickable button, submit - submits form data and reset - resets the form date to its initial values.
     * The default value is button.
     */
    reactButtonType?: React.ButtonHTMLAttributes<{}>["type"];
    /** Gets and sets the values of the class attributes of the element. */
    className?: string;
    /** Value to pass to futher action. */
    value?: T;
    /** On click action. */
    onClick?: (event: React.MouseEvent<HTMLElement>, value: T) => void;
    /** OnMouseDown action. */
    onMouseDown?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, value?: T) => void;
    /** Change after parent component. */
    stretch?: boolean;
    /** Reduces the buttons with and height. */
    thin?: boolean;
    /** Disabled by making the component greyed out and removed interaction possibilities. */
    disabled?: boolean;
    /** Adjust button size: "small", "medium", "large" or "x-large". Default is "medium"  */
    size?: SizeType;
    /** Appearance of icon set through className. */
    iconClass?: string;
    /** Solid, regular, light */
    iconStyle?: IconStyle;
    /** Icon color */
    iconColor?: string;
    iconSize?: IconScale;
    /** Increases border radius on all sides of button, making it appear round (oval). */
    round?: boolean;
    /** Only visible when there is an icon but no text. Shows the string when the user howers the icon. */
    iconTooltip?: string;
    /** If you need to show tooltip when button is disabled. */
    iconTooltipOnDisabled?: string;
    /** Sets icon on button, icon and text is mutually exclusive. If icon is set to "next", "nextStep" or "down" the icon will be placed on the right side of the text. */
    icon?: IconPreset;
    /** Reduces height of inputfield if renderAs="button" is used. If "select" is used there is no difference in appearance. */
    /** Used to layer two icons on top of each other.
     * The first <StackIcon /> in the array is automatic the first layer icon and will take up the whole size of its parent container if nothing else is specified.
     * The second <StackIcon /> is the second layer and so on.
     * The second <StackIcon /> and furthermore will have a standard size of "xs" and will be absolutely positioned in the bottom corner if nothing else is specified in the transform prop.
     */
    stackIcons?: React.ReactElement<IStackIconProps>[];
    /** Sets another custom icon on right side of title. */
    secondIcon?: IconPreset;
    /** Place your icon either on the "right" or the "left". Default is "left". */
    iconPlacement?: IconPlacementType;
    uppercase?: boolean;
    block?: boolean;
    /** TO BE DEPRECATED: Button background color is set to a bright blue that should indicate that it is "active".
     * If you find yourself in need of this styling, consider using SegmentedControls instead of Button. */
    active?: boolean;
    loadingOptions?: ILoadingOptions;
    /** Does not actually hide it, but shows displays a quality of not being usually shown. */
    hidden?: boolean;
    autoFocus?: boolean;
}

export const Button = <T,>(props: PropsWithImmutableChildren<IButtonProps<T>>) => {
    const renderModeContext = React.useContext(RenderModeContext);
    const setSpinnerSize = (size: SizeType) => {
        let spinnerSize: "large" | "medium" | "small" | "tiny";
        switch (size) {
            case "large":
                spinnerSize = "medium";
                break;
            case "x-large":
                spinnerSize = "large";
                break;
            default:
                spinnerSize = "small";
                break;
        }

        return spinnerSize;
    };

    const getButtonContent = (): JSX.Element => {
        if (props.loadingOptions?.isLoading) {
            return (
                <Flex gap={"small"} center>
                    {props.loadingOptions.loadingText ? <Text as={"span"}>{props.loadingOptions.loadingText}</Text> : null}
                    <SpinnerCircle className={"buttonContent-isLoading"} size={props.size ? setSpinnerSize(props.size) : undefined} />
                </Flex>
            );
        }
        if (props.icon && !props.text) {
            return (
                <Icon
                    iconStyle={props.iconStyle}
                    tooltip={!props.disabled || props.iconTooltipOnDisabled ? props.iconTooltip || props.iconTooltipOnDisabled : undefined}
                    id={props.id}
                    preset={props.icon}
                    color={props.iconColor}
                    size={props.iconSize}
                />
            );
        } else if (props.stackIcons && !props.text) {
            return <Icon id={props.id} stackIcons={props.stackIcons} />;
        } else if (!props.icon && !props.stackIcons && props.text) {
            return <span className="button_text">{props.text}</span>;
        } else if (props.secondIcon && props.text && props.icon) {
            return (
                <span className={"buttonContent"}>
                    <Icon id={`${props.id}.firstIcon`} iconStyle={props.iconStyle} preset={props.icon} color={props.iconColor} />
                    <span className="button_text">{props.text}</span>
                    <Icon id={`${props.id}.secondIcon`} iconStyle={props.iconStyle} preset={props.secondIcon} color={props.iconColor} />
                </span>
            );
        } else {
            return (
                <span className={"buttonContent buttonContent-" + getIconPlacement(props.icon)}>
                    {props.icon ? (
                        <Icon
                            id={props.id}
                            iconStyle={props.iconStyle}
                            preset={props.icon}
                            color={props.iconColor}
                            tooltip={props.iconTooltip && (!props.disabled || props.iconTooltipOnDisabled) ? props.iconTooltip || props.iconTooltipOnDisabled : undefined}
                        />
                    ) : props.stackIcons ? (
                        <Icon id={props.id} stackIcons={props.stackIcons} />
                    ) : null}
                    <span className="button_text">{props.text}</span>
                </span>
            );
        }
    };

    const getIconPlacement = (icon: string | undefined): string => {
        if (icon === "arrow-circle-right" || icon === "arrow-right" || icon === "chevron-down") {
            return "iconRight";
        } else if (props.iconPlacement === "right") {
            return "iconRight";
        } else {
            return "iconLeft";
        }
    };

    const onClick: React.MouseEventHandler<HTMLElement> = (event) => {
        if (props.onClick) props.onClick(event, props.value as T);
    };

    const onMouseDown: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        if (props.onMouseDown) props.onMouseDown(event, props.value);
    };

    const getButtonSize = (): string => {
        // If an explicit size is set, use that size
        if (props.size) return props.size;
        // If no explicit size is set and the render mode is a smaller screen, use "large" by default
        if (!renderModeContext.isDesktop) return "large";
        // Else return "medium" size as standard fallback size
        return "medium";
    };

    const onKeyDown: React.KeyboardEventHandler<HTMLButtonElement> = (event) => {
        if (props.onKeyDownCallback) props.onKeyDownCallback(event);
    };

    const isIconButton = props.icon && !props.text;
    const disabled = props.disabled || props.loadingOptions?.isLoading;

    const classes = css(
        props.className ? props.className + " button" : "button",
        getIconPlacement(props.icon),
        props.icon,
        getButtonSize(),
        true,
        "primary",
        props.type === "primary",
        "secondary",
        props.type === "secondary" || !props.type,
        "danger",
        props.type === "danger",
        "label",
        props.type === "label",
        "block",
        props.block,
        "uppercase",
        props.uppercase,
        "stretch",
        props.stretch,
        "thin",
        props.thin,
        "active",
        props.active,
        "label",
        props.type === "label",
        "iconButton",
        isIconButton,
        "disabled",
        disabled,
        "round",
        props.round,
        "hidden",
        props.hidden
    );

    const autoFocusCallback = React.useCallback(
        (element: HTMLButtonElement) => {
            if (props.autoFocus && element) {
                element.focus();
            }
        },
        [props.autoFocus]
    );

    return (
        <button
            style={props.style}
            ref={props.autoFocus ? autoFocusCallback : undefined}
            id={props.id + ".button"}
            data-autotestid={props.autotestid}
            tabIndex={props.tabIndex}
            className={classes}
            type={props.reactButtonType || "button"}
            onClick={props.disabled || props.loadingOptions?.isLoading ? undefined : onClick}
            onMouseDown={props.disabled || props.loadingOptions?.isLoading ? undefined : onMouseDown}
            onKeyDown={onKeyDown}
            disabled={disabled}>
            {getButtonContent()}
        </button>
    );
};

Button.defaultProps = {
    round: false,
};

export default Button;
