import { IDiff } from "common/modules/diffHelper";
import { RecordFactory } from "common/modules/recordFactory";
import * as Moment from "moment-timezone";
import { CostCenter, ICostCenterValues } from "./costCenter";

export enum RegionalSubsidiarySetting {
    Inherit = 0,
    Yes = 1,
    No = 2,
}

// ----------- StationSettings -----------
export interface IStationSettingsValues {
    costCenter1?: ICostCenterValues;
    costCenter2?: ICostCenterValues;
    costCenter3?: ICostCenterValues;
    showInSchedule?: boolean;
    isSelling?: boolean;
    se_arbetsställenummer?: number | null;
    enablePunching?: boolean;
    captureOrganizationAssociation?: boolean;
    // enableInSchedule?: boolean;
    businessUnit?: string | null;
    tipStartDate?: IMoment | null;
    legacyTipUnitId?: number | null;
    checkoutPlaceId?: string | null;
    merchantId?: number | null | undefined;
    employersContributionZone?: number;
    regionalSubsidiary?: RegionalSubsidiarySetting;
    disableAsMainAffiliation?: boolean;
}

interface IStationSettings extends IStationSettingsValues {
    costCenter1?: CostCenter;
    costCenter2?: CostCenter;
    costCenter3?: CostCenter;
}

const StationSettingsRecord = RecordFactory<IStationSettings>({
    costCenter1: new CostCenter({}),
    costCenter2: new CostCenter({}),
    costCenter3: new CostCenter({}),
    showInSchedule: true,
    isSelling: true,
    se_arbetsställenummer: null,
    enablePunching: true,
    captureOrganizationAssociation: true,
    // enableInSchedule: true,
    businessUnit: null,
    tipStartDate: null,
    legacyTipUnitId: null,
    checkoutPlaceId: null,
    merchantId: undefined,
    employersContributionZone: undefined,
    regionalSubsidiary: undefined,
    disableAsMainAffiliation: undefined,
});

export class StationSettings extends StationSettingsRecord implements IStationSettings {
    costCenter1: CostCenter;
    costCenter2: CostCenter;
    costCenter3: CostCenter;
    showInSchedule: boolean;
    isSelling: boolean;
    se_arbetsställenummer: number;
    enablePunching?: boolean;
    captureOrganizationAssociation?: boolean;
    // enableInSchedule?: boolean;
    businessUnit?: string;
    tipStartDate?: IMoment;
    legacyTipUnitId?: number;
    checkoutPlaceId?: string;
    merchantId?: number | null | undefined;
    employersContributionZone?: number;
    regionalSubsidiary?: RegionalSubsidiarySetting;
    disableAsMainAffiliation?: boolean;
    constructor(values: IStationSettingsValues) {
        values.costCenter1 = new CostCenter(values.costCenter1 || {});
        values.costCenter2 = new CostCenter(values.costCenter2 || {});
        values.costCenter3 = new CostCenter(values.costCenter3 || {});
        if (values.tipStartDate && !Moment.isMoment(values.tipStartDate)) {
            values.tipStartDate = Moment(values.tipStartDate);
        }
        super(<IStationSettings>values);
    }

    diff(before: StationSettings | null, prefix: string = "") {
        let diffs: IDiff[] = [];
        this.keySeq().forEach((key) => {
            const propAfter = this.get(key);
            const propBefore = before && before.get(key);
            if (propAfter && (<CostCenter>propAfter).diff && key !== "tipStartDate") {
                diffs = diffs.concat((<CostCenter>propAfter).diff(<CostCenter>propBefore, [prefix, key].join(".")));
                return;
            }
            if (propBefore !== propAfter && !(propBefore instanceof CostCenter) && !(propAfter instanceof CostCenter)) {
                diffs.push({ key: prefix + "." + key, before: propBefore, after: propAfter });
            }
            return;
        });
        return diffs;
    }
}
