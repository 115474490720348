import { IToastValues, Toast } from "../models/toaster/toast";
import Action from "./action";

export class Add extends Action {
    static actionType: string = "toast_add";

    toast: Toast;
    update: boolean;

    constructor(toast: Toast, update?: boolean) {
        super(false);
        this.toast = toast;
        this.update = !!update;
        this.doDispatch();
    }
}

export class AddNew extends Action {
    static actionType: string = "toast_add";

    toast: Toast;
    update: boolean;

    constructor(toast: Partial<IToastValues>, update?: boolean) {
        super(false);
        this.toast = new Toast(toast);
        this.update = !!update;
        this.doDispatch();
    }
}

export class MoveToHistory extends Action {
    static actionType: string = "toast_moveToHistory";

    toast: Toast;

    constructor(toast: Toast) {
        super(false);
        this.toast = toast;
        this.doDispatch();
    }
}

export class Remove extends Action {
    static actionType: string = "toast_remove";

    toastId: number;

    constructor(toastId: number) {
        super(false);
        this.toastId = toastId;
        this.doDispatch();
    }
}
