import * as Immutable from "immutable";
import * as Moment from "moment-timezone";
import { PayrollDataChoiceDefinition, IPayrollDataChoiceDefinition } from "application/models/payrollDataChoiceDefinition";

export enum PayrollDataType {
    String = 0,
    Decimal = 1,
    Bool = 2,
    Int = 3,
    Choice = 4,
    Date = 5,
    Percent = 6,
}

export enum PayrollDataValueType {
    Null = 0,
    Bool = 1,
    Choice = 2,
    Date = 3,
    Decimal = 4,
    Int = 5,
    String = 6,
}

export function getPayrollDataTypeFieldName(dataType: PayrollDataType | null): keyof IPayrollDataValueValues {
    switch (dataType) {
        case PayrollDataType.String:
            return "stringValue";
        case PayrollDataType.Decimal:
            return "decimalValue";
        case PayrollDataType.Bool:
            return "boolValue";
        case PayrollDataType.Int:
            return "intValue";
        case PayrollDataType.Choice:
            return "choiceValue";
        case PayrollDataType.Date:
            return "dateValue";
        case PayrollDataType.Percent:
            return "decimalValue";
        default:
            throw new Error("Unknown PayrollDataType");
    }
}

export interface IPayrollDataValueValues {
    boolValue?: boolean;
    choiceValue?: IPayrollDataChoiceDefinition;
    dateValue?: IMoment | string | null;
    decimalValue?: number;
    intValue?: number;
    stringValue?: string;
}

export interface IPayrollDataValueValuesWithType extends IPayrollDataValueValues {
    type: PayrollDataValueType;
}
const PayrollDataValueRecord = Immutable.Record({
    boolValue: null,
    choiceValue: null,
    dateValue: null,
    decimalValue: 0,
    intValue: 0,
    stringValue: null,
    type: PayrollDataValueType.Null,
});

export function fieldNameToType(fieldName: keyof IPayrollDataValueValues): PayrollDataValueType {
    switch (fieldName) {
        case "boolValue":
            return PayrollDataValueType.Bool;
        case "choiceValue":
            return PayrollDataValueType.Choice;
        case "dateValue":
            return PayrollDataValueType.Date;
        case "decimalValue":
            return PayrollDataValueType.Decimal;
        case "intValue":
            return PayrollDataValueType.Int;
        case "stringValue":
            return PayrollDataValueType.String;
        default:
            return PayrollDataValueType.Null;
    }
}

function typeToFieldName(valueType: PayrollDataValueType): keyof IPayrollDataValueValues {
    switch (valueType) {
        case PayrollDataValueType.Bool:
            return "boolValue";
        case PayrollDataValueType.Choice:
            return "choiceValue";
        case PayrollDataValueType.Date:
            return "dateValue";
        case PayrollDataValueType.Decimal:
            return "decimalValue";
        case PayrollDataValueType.Int:
            return "intValue";
        case PayrollDataValueType.String:
            return "stringValue";
        default:
            throw new Error("Unknown PayrollDataValueType");
    }
}

export class PayrollDataValue extends PayrollDataValueRecord implements IPayrollDataValueValuesWithType {
    readonly boolValue: boolean;
    readonly choiceValue: IPayrollDataChoiceDefinition;
    readonly dateValue?: IMoment | null;
    readonly decimalValue: number;
    readonly intValue: number;
    readonly stringValue: string;
    readonly type: PayrollDataValueType;

    constructor(values: IPayrollDataValueValuesWithType) {
        const modifiedValues: Partial<IPayrollDataValueValuesWithType> = {};
        if (values.dateValue) {
            if (values.dateValue === "0001-01-01") {
                modifiedValues.dateValue = undefined;
            } else {
                modifiedValues.dateValue = Moment.utc(values.dateValue);
            }
        }
        if (values.choiceValue) {
            modifiedValues.choiceValue = new PayrollDataChoiceDefinition(values.choiceValue);
        }
        if (values.type === PayrollDataValueType.Null) {
            modifiedValues.boolValue = undefined;
            modifiedValues.choiceValue = undefined;
            modifiedValues.dateValue = undefined;
            modifiedValues.decimalValue = undefined;
            modifiedValues.intValue = undefined;
            modifiedValues.stringValue = undefined;
        }

        let valuesObject = {};
        const pdv = <PayrollDataValue>values;
        if (pdv && pdv.toObject) {
            valuesObject = pdv.toObject();
        }
        super({ ...values, ...valuesObject, ...modifiedValues });
    }

    value() {
        if (this.type === PayrollDataValueType.Null) {
            return null;
        }
        return this[typeToFieldName(this.type)];
    }
}

export type IPayrollDataValue = PayrollDataValue;

export function getNumericalValue(payrollDataValue: PayrollDataValue): number | null {
    return (
        payrollDataValue.decimalValue ||
        payrollDataValue.intValue ||
        (payrollDataValue.choiceValue && payrollDataValue.choiceValue.choiceId) ||
        (payrollDataValue.boolValue && payrollDataValue.boolValue ? 1 : 0) ||
        null
    );
}
