import { Map } from "immutable";
import { ISVGCustomProps } from "ui/components/icon/customIcons/customIconsProps";

import { svg8Ball } from "ui/components/icon/customIcons/8ball";
import { svgDart } from "ui/components/icon/customIcons/dart";
import { svgMinigolf } from "ui/components/icon/customIcons/minigolf";
import { svgGokart } from "ui/components/icon/customIcons/gokart";
import { svgKaraoke } from "ui/components/icon/customIcons/karaoke";
import { svgShooting } from "ui/components/icon/customIcons/shooting";
import { svgShuffleboard } from "ui/components/icon/customIcons/shuffleboard";
export type CustomIconPreset = "8ball" | "dart" | "minigolf" | "gokart" | "karaoke" | "shooting" | "shuffleboard";

export const CustomIcons = Map<string, (props: ISVGCustomProps) => JSX.Element>([
    ["8ball", svg8Ball],
    ["dart", svgDart],
    ["minigolf", svgMinigolf],
    ["gokart", svgGokart],
    ["karaoke", svgKaraoke],
    ["shooting", svgShooting],
    ["shuffleboard", svgShuffleboard],
]);
