import { RecordFactory } from "common/modules/recordFactory";

export interface IPayrollDataChoiceDefinition {
    choiceId: number | null;
    description: string;
    helpText: string;
    groupLabel: string | null;
    props?: { [key: string]: any } | undefined;
}
const PayrollDataChoiceDefinitionRecord = RecordFactory<IPayrollDataChoiceDefinition>({
    choiceId: null,
    description: "",
    helpText: "",
    groupLabel: null,
    props: undefined,
});

export class PayrollDataChoiceDefinition extends PayrollDataChoiceDefinitionRecord implements IPayrollDataChoiceDefinition {}
