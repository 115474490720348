import { default as Action, ReduceAction } from "framework/actions/action";
import { HttpVerb } from "framework/modules/requestHelper";

export class ChangeAPIUrl extends Action {
    static actionType: string = "api_ChangeApiUrl";
    url: string;
    remove?: boolean;
    constructor(url: string, remove = false) {
        super(false);
        this.url = url;
        this.remove = remove;
        this.doDispatch();
    }
}

export class AddAPIProfilerIds extends Action {
    static actionType: string = "api_add_profiler_ids";

    ids: string[];
    deployment: string | undefined;

    constructor(ids: string[], deployment: string | undefined) {
        super(false);
        this.ids = ids;
        this.deployment = deployment;
        this.doDispatch();
    }
}

export class ChangeAccessToken extends Action {
    static actionType: string = "api_changeToken";
    token: string;
    constructor(token: string) {
        super(false);
        this.token = token;
        this.doDispatch();
    }
}

export class LoadingAction extends ReduceAction {
    public actionType: string;

    constructor(verb: HttpVerb, endpoint: string, requestState: "REQUEST" | "SUCCESS" | "FAIL", public params?: string) {
        super();
        const verbStr = HttpVerb[verb].toUpperCase();
        this.actionType = `${verbStr}_${endpoint}_${requestState}`;
        this.doDispatch();
    }
}
