/* eslint-disable @typescript-eslint/ban-types */

import { Iterable as ImmutableIterable, Record, Seq } from "immutable";

export interface IStaticallyTypedRecord<TProps extends Object> extends Omit<ImmutableIterable.Keyed<keyof TProps, TProps[keyof TProps]>, "toSeq" | "getIn" | "hasIn" | "toObject"> {
    // Reading values

    has(key: keyof TProps): boolean;

    /**
     * Returns the value associated with the provided key, which may be the
     * default value defined when creating the Record factory function.
     *
     * If the requested key is not defined by this Record type, then
     * notSetValue will be returned if provided. Note that this scenario would
     * produce an error when using Flow or TypeScript.
     */
    get<K extends keyof TProps>(key: K, notSetValue?: unknown): TProps[K];
    get<T>(key: string, notSetValue: T): T;

    // Reading deep values

    /**
     * Returns true if the key path is defined in the provided collection.
     * @param keyPath an array representing the path to the nested value
     */
    hasIn<K1 extends keyof TProps, K2 extends keyof TProps[K1], K3 extends keyof TProps[K1][K2]>(keyPath: [K1, K2, K3]): boolean;
    hasIn<K1 extends keyof TProps, K2 extends keyof TProps[K1]>(keyPath: [K1, K2]): boolean;
    hasIn<K1 extends keyof TProps>(keyPath: [K1]): boolean;
    hasIn(keyPath: unknown[]): boolean;

    /**
     * Returns the value at the provided key path starting at the provided collection, or notSetValue if the key path is not defined.
     * @param keyPath an array representing the path to the nested value
     */
    getIn<K1 extends keyof TProps, K2 extends keyof TProps[K1], K3 extends keyof TProps[K1][K2]>(keyPath: [K1, K2, K3]): TProps[K1][K2][K3];
    getIn<K1 extends keyof TProps, K2 extends keyof TProps[K1]>(keyPath: [K1, K2]): TProps[K1][K2];
    getIn<K1 extends keyof TProps>(keyPath: [K1]): TProps[K1];
    getIn(keyPath: unknown[]): unknown;

    // Value equality

    equals(other: unknown): boolean;
    hashCode(): number;

    // Persistent changes
    set<K extends keyof TProps>(key: K, value: TProps[K]): this;
    update<K extends keyof TProps>(key: K, updater: (value: TProps[K]) => TProps[K]): this;
    update<K extends keyof TProps>(key: K, netSetValue: TProps[K], updater: (value: TProps[K]) => TProps[K]): this;
    merge(...collections: (Partial<TProps> | Iterable<[string, unknown]>)[]): this;
    mergeDeep(...collections: (Partial<TProps> | Iterable<[string, unknown]>)[]): this;

    mergeWith(merger: (oldVal: unknown, newVal: unknown, key: keyof TProps) => unknown, ...collections: (Partial<TProps> | Iterable<[string, unknown]>)[]): this;
    mergeDeepWith(merger: (oldVal: unknown, newVal: unknown, key: unknown) => unknown, ...collections: (Partial<TProps> | Iterable<[string, unknown]>)[]): this;

    /**
     * Returns a new instance of this Record type with the value for the
     * specific key set to its default value.
     *
     * @alias remove
     */
    delete<K extends keyof TProps>(key: K): this;
    remove<K extends keyof TProps>(key: K): this;

    /**
     * Returns a new instance of this Record type with all values set
     * to their default values.
     */
    clear(): this;

    /**
     * Returns a copy of the collection with the value at the key path set to the provided value.
     * @param keyPath an array representing the path to the nested value
     * @param value value to set at the nested key
     */
    setIn<K1 extends keyof TProps, K2 extends keyof TProps[K1], K3 extends keyof TProps[K1][K2]>(keyPath: [K1, K2, K3], value: TProps[K1][K2][K3]): this;
    setIn<K1 extends keyof TProps, K2 extends keyof TProps[K1]>(keyPath: [K1, K2], value: TProps[K1][K2]): this;
    setIn<K1 extends keyof TProps>(keyPath: [K1], value: TProps[K1]): this;
    setIn(keyPath: unknown[], value: unknown): this;

    /**
     * Returns a copy of the collection with the value at key path set to the result of providing the existing value to the updating function.
     * @param keyPath an array representing the path to the nested value
     * @param updater function for updating the value for the nested key
     */
    updateIn<K1 extends keyof TProps, K2 extends keyof TProps[K1], K3 extends keyof TProps[K1][K2]>(keyPath: [K1, K2, K3], updater: (value: TProps[K1][K2][K3]) => TProps[K1][K2][K3]): this;
    updateIn<K1 extends keyof TProps, K2 extends keyof TProps[K1]>(keyPath: [K1, K2], updater: (value: TProps[K1][K2]) => TProps[K1][K2]): this;
    updateIn<K1 extends keyof TProps>(keyPath: [K1], updater: (value: TProps[K1]) => TProps[K1]): this;
    updateIn(keyPath: unknown[], updater: (value: unknown) => unknown): this;

    /**
     *
     * @param keyPath an array representing the path to the nested value
     * @param collections
     */
    mergeIn(keyPath: Iterable<unknown>, ...collections: unknown[]): this;

    /**
     *
     * @param keyPath an array representing the path to the nested value
     * @param collections
     */
    mergeDeepIn(keyPath: Iterable<unknown>, ...collections: unknown[]): this;

    /**
     * Returns a copy of the collection with the value at the key path removed.
     * @param keyPath an array representing the path to the nested value
     * @alias removeIn
     */
    deleteIn<K1 extends keyof TProps, K2 extends keyof TProps[K1], K3 extends keyof TProps[K1][K2]>(keyPath: [K1, K2, K3]): this;
    deleteIn<K1 extends keyof TProps, K2 extends keyof TProps[K1]>(keyPath: [K1, K2]): this;
    deleteIn<K1 extends keyof TProps>(keyPath: [K1]): this;
    deleteIn(keyPath: unknown[]): this;

    /**
     * Returns a copy of the collection with the value at the key path removed.
     * @param keyPath an array representing the path to the nested value
     */
    removeIn<K1 extends keyof TProps, K2 extends keyof TProps[K1], K3 extends keyof TProps[K1][K2]>(keyPath: [K1, K2, K3]): this;
    removeIn<K1 extends keyof TProps, K2 extends keyof TProps[K1]>(keyPath: [K1, K2]): this;
    removeIn<K1 extends keyof TProps>(keyPath: [K1]): this;
    removeIn(keyPath: unknown[]): this;

    // Conversion to JavaScript types

    /**
     * Deeply converts this Record to equivalent native JavaScript Object.
     *
     * Note: This method may not be overridden. Objects with custom
     * serialization to plain JS may override toJSON() instead.
     */
    toJS(): { [K in keyof TProps]: unknown };

    /**
     * Shallowly converts this Record to equivalent native JavaScript Object.
     */
    toJSON(): TProps;

    /**
     * Shallowly converts this Record to equivalent JavaScript Object.
     */
    toObject(): TProps;

    // Transient changes

    /**
     * Note: Not all methods can be used on a mutable collection or within
     * `withMutations`! Only `set` may be used mutatively.
     *
     * @see `Map#withMutations`
     */
    withMutations(mutator: (mutable: this) => unknown): this;

    /**
     * @see `Map#asMutable`
     */
    asMutable(): this;

    /**
     * @see `Map#wasAltered`
     */
    wasAltered(): boolean;

    /**
     * @see `Map#asImmutable`
     */
    asImmutable(): this;

    // Sequence algorithms

    toSeq(): Seq.Keyed<keyof TProps, TProps[keyof TProps]>;

    [Symbol.iterator](): IterableIterator<[keyof TProps, TProps[keyof TProps]]>;
}

interface Factory<TProps extends Object> {
    (values?: Partial<TProps> | Iterable<[string, unknown]>): IStaticallyTypedRecord<TProps> & Readonly<TProps>;
    new (values?: Partial<TProps> | Iterable<[string, unknown]>): IStaticallyTypedRecord<TProps> & Readonly<TProps>;
}

export const RecordFactory = <TProps extends Object>(defaultValues: TProps, name?: string): Factory<TProps> => {
    return <Factory<TProps>>(<any>Record(defaultValues, name));
};

/* eslint-enable @typescript-eslint/ban-types */
