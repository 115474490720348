"use strict";

import * as React from "react";

//------------------------------------------------------------------------
// Mark-up JSX/HTML parser/generator.
//------------------------------------------------------------------------
// Main function "parse" accepts a single parameter: either a single string
// or an array of strings. If the input is an array the output will also
// be an array.
//
//
// Mark-up syntax:
//
// > Code is enclosed in braces: f.i. "[[always]]" will be formatted
//   as "always" (inclusing quotes) in typewriter typeface.
// > [/Italicised text/]
// > [*Bolded text*]
// > [_Underline text_]
// > [-Strikethrough text-]
// > [^Super (footnote style) text^]
// > [vSub text (oppsite of super)v]
//
// Note that tags cannot be nestled.
//
//
// Array syntax:
//
// > Lines starting with "§" will be paragraphs.
// > Consequtive lines starting with "*" will be a bullet list
// > Consequtive lines starting with "#" will be a numbered list
// > All other lines will be separated by <br/> inside one paragraph.
//------------------------------------------------------------------------

interface ITagTypes {
    [key: string]: ITagType;
}

interface ITagType {
    id: number;
    length: number;
}

enum EBlockType {
    paragraph = 0,
    bullet = 1,
    ordered = 2,
    linebreak = 256,
}

export interface MarkupOptions {
    singleParagraphMarginTop?: string;
    lineBreaksMargin?: string;
    doNotWrapInParagraph?: boolean;
}

function MarkUp(markupOptions?: MarkupOptions) {
    let markupKey = 0;

    // Wrap argument in <p></p>
    //------------------------------------------------------------------------
    function makeLineBreaks(args: (JSX.Element | JSX.Element[])[]) {
        const a = [];
        for (const i in args) {
            if (parseInt(i) > 0) {
                a.push(<br />);
            }
            a.push(args[i]);
        }
        const style: React.CSSProperties = {};
        if (markupOptions?.lineBreaksMargin) {
            style.margin = markupOptions.lineBreaksMargin;
        }
        return markupOptions?.doNotWrapInParagraph ? (
            <>{a}</>
        ) : (
            <p key={markupKey++} style={style}>
                {a}
            </p>
        );
    }
    // Wrap argument in <p></p>
    //------------------------------------------------------------------------
    function makeParagraph(str: JSX.Element | JSX.Element[]) {
        return <p key={markupKey++}>{str}</p>;
    }

    // Wrap arguments in <p></p>
    //------------------------------------------------------------------------
    function makeParagraphs(args: (JSX.Element | JSX.Element[])[]) {
        const a = [];
        for (const i in args) {
            a.push(makeParagraph(args[i]));
        }
        return a;
    }

    // Turn array items into list items and wrap in <ul></ul>
    //------------------------------------------------------------------------
    function makeBulletList(args: (JSX.Element | JSX.Element[])[]) {
        const a = [];
        for (const i in args) {
            a.push(
                <li key={markupKey++}>
                    <p>{args[i]}</p>
                </li>
            );
        }
        return <ul key={markupKey++}>{a}</ul>;
    }

    // Turn array items into list items and wrap in <ol></ol>
    //------------------------------------------------------------------------
    function makeOrderedList(args: (JSX.Element | JSX.Element[])[]) {
        const a = [];
        for (const i in args) {
            a.push(
                <li key={markupKey++}>
                    <p style={{ margin: 0 }}>{args[i]}</p>
                </li>
            );
        }
        return <ol key={markupKey++}>{a}</ol>;
    }

    // Wrap argument in <span></span>, optionally decorated it with a
    // provided CSS class name.
    //------------------------------------------------------------------------
    function makeSpan(str: string, className?: string) {
        if (str || str.length > 0) {
            if (className && className.length > 0) return <span className={className}>{str}</span>;
            return <span key={markupKey++}>{str}</span>;
        }
        return null;
    }

    // Not exposed outside module.
    // Main workhorse method for markup-parsing an individual string.
    //------------------------------------------------------------------------
    function parseString(str: string): JSX.Element | JSX.Element[] {
        const output: JSX.Element[] = [];

        const TagType: ITagTypes = {
            unknown: { id: 0, length: 0 },
            code: { id: 1, length: 2 },
            bold: { id: 2, length: 2 },
            italic: { id: 3, length: 2 },
            underline: { id: 4, length: 2 },
            strike: { id: 5, length: 2 },
            super: { id: 6, length: 2 },
            sub: { id: 7, length: 2 },
        };

        if (typeof str === "string") {
            let startPos = 0;
            let pos = 0;
            const parseTag = (tagType: ITagType, endPos: number) => {
                const res: any = { length: 0, text: null };
                if (endPos !== -1) {
                    const code = str.substring(pos, endPos + tagType.length);
                    const text = code.substring(tagType.length, code.length - tagType.length);

                    res.length = code.length;

                    switch (tagType) {
                        case TagType.code:
                            res.text = (
                                <code className="markup_code" key={markupKey++}>
                                    {'"' + text + '"'}
                                </code>
                            );
                            break;
                        case TagType.italic:
                            res.text = (
                                <i className="markup_italic" key={markupKey++}>
                                    {text}
                                </i>
                            );
                            break;
                        case TagType.bold:
                            res.text = (
                                <b className="markup_bold" key={markupKey++}>
                                    {text}
                                </b>
                            );
                            break;
                        case TagType.underline:
                            res.text = (
                                <u className="markup_underline" key={markupKey++}>
                                    {text}
                                </u>
                            );
                            break;
                        case TagType.strike:
                            res.text = (
                                <s className="markup_strikethrough" key={markupKey++}>
                                    {text}
                                </s>
                            );
                            break;
                        case TagType.super:
                            res.text = (
                                <sup className="markup_strikethrough" key={markupKey++}>
                                    {text}
                                </sup>
                            );
                            break;
                        case TagType.sub:
                            res.text = (
                                <sub className="markup_strikethrough" key={markupKey++}>
                                    {text}
                                </sub>
                            );
                            break;
                        default:
                            break;
                    }
                }
                return res;
            };

            let log = 0;
            while ((pos = str.indexOf("[", startPos)) !== -1) {
                let res = { length: 0, text: null as JSX.Element | null };

                switch (str.charAt(pos + 1)) {
                    case "[":
                        res = parseTag(TagType.code, str.indexOf("]]", pos));
                        break;
                    case "/":
                        res = parseTag(TagType.italic, str.indexOf("/]", pos));
                        break;
                    case "*":
                        res = parseTag(TagType.bold, str.indexOf("*]", pos));
                        break;
                    case "_":
                        res = parseTag(TagType.underline, str.indexOf("_]", pos));
                        break;
                    case "-":
                        res = parseTag(TagType.strike, str.indexOf("-]", pos));
                        break;
                    case "^":
                        res = parseTag(TagType.super, str.indexOf("^]", pos));
                        break;
                    case "v":
                        res = parseTag(TagType.sub, str.indexOf("v]", pos));
                        break;
                    default:
                        break;
                }

                if (res.length > 0) {
                    if (log !== pos) {
                        const leading = makeSpan(str.substring(log, pos));
                        if (leading) output.push(leading);
                    }
                    output.push(res.text as JSX.Element);
                    log = startPos = pos + res.length;
                } else {
                    startPos = pos + 1;
                }
            }

            if (log < startPos) {
                startPos = log;
            }
            const span = makeSpan(str.substring(startPos, str.length));
            if (span) {
                output.push(span); // remainder of the string
            }
        }
        return output;
    }

    // Not exposed outside module.
    // Main workhorse method for markup-parsing an array into lists and
    // paragraphs.
    //------------------------------------------------------------------------
    function parseArray(arr: string[]) {
        const lines: JSX.Element[] = [];

        function blockType(str: string) {
            if (str === "") {
                return { type: EBlockType.linebreak, str: parseString(str) };
            }
            if (str && str.length > 0) {
                switch (str.charAt(0)) {
                    case "§":
                        return { type: EBlockType.paragraph, str: parseString(str.substring(1)) };
                    case "*":
                        return { type: EBlockType.bullet, str: parseString(str.substring(1)) };
                    case "#":
                        return { type: EBlockType.ordered, str: parseString(str.substring(1)) };

                    default:
                        return { type: EBlockType.linebreak, str: parseString(str) };
                }
            }
            return null;
        }

        function commitLines(collection: (JSX.Element | JSX.Element[])[], blockType: EBlockType | null) {
            switch (blockType) {
                case EBlockType.bullet:
                    lines.push(makeBulletList(collection));
                    break;
                case EBlockType.ordered:
                    lines.push(makeOrderedList(collection));
                    break;
                case EBlockType.paragraph:
                    const pars = makeParagraphs(collection);
                    for (const par of pars) {
                        lines.push(par);
                    }
                    break;
                default:
                    lines.push(makeLineBreaks(collection));
                    break;
            }
        }

        let lastBlockType = null;
        let curCollection: (JSX.Element | JSX.Element[])[] = [];
        for (const el of arr) {
            const curBlock = blockType(el);
            if (curBlock) {
                if (curBlock.type !== lastBlockType && curCollection.length > 0) {
                    commitLines(curCollection, lastBlockType);
                    curCollection = [];
                }

                curCollection.push(curBlock.str);
                lastBlockType = curBlock.type;
            }
        }
        commitLines(curCollection, lastBlockType);
        return lines;
    }

    // Main method and generally the only one called from client code.
    //------------------------------------------------------------------------
    function parse(source: string | string[]) {
        if (typeof source === "string") {
            const cooked = parseString(source);
            if (cooked) {
                return <p style={{ margin: `${markupOptions?.singleParagraphMarginTop ?? "5px"} 0 0 0` }}>{cooked}</p>;
            }
            return null;
        }

        if (Array.isArray(source)) {
            return parseArray(source);
        }
        // console.warn( "Unrecognised data type passed to markup parser:", source );
        return null;
    }

    return {
        parse: parse,
        makeParagraphs: makeParagraphs,
        makeBulletList: makeBulletList,
        makeOrderedList: makeOrderedList,
        makeSpan: makeSpan,
    };
}

export default MarkUp;
