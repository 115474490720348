import { Datetime } from "common/modules/datetime";

export enum IIdentityType {
    None = 0,
    User = 1,
}

export type TScope = string;

export interface IAccessToken {
    subject: string;
    name: string;
    lifetime: number;
    authTime: Datetime;
    expiresAt: Datetime;
    identityType: IIdentityType;
    amr: string[];
    scopes: TScope[];

    isValid(): boolean;
}

export interface ISavedCredentials {
    username: string | null;
    refreshToken: IRefreshToken | null;
}

export interface IRefreshToken {
    createdAt: Datetime | null;
    expiresAt: Datetime | null;
    handle: string | null;
    lifetime: number | null;

    isValid(): boolean;
}

export interface IRefreshTokenRequiredfields {
    createdAt: Datetime | null;
    expiresAt: Datetime | null;
    handle: string | null;
    lifetime: number | null;
}
