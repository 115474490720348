import * as EventEmitterBase from "events";

//EventEmitterBase.prototype.setMaxListeners(50);

// use 200 instead of default (10) to avoid false warnings of leaked subscriptions.
// The reason for two hundred is because if responseRender. It sets up a listener for every component that implements it.
const MAX_LISTENERS = 200;

class EventEmitter {
    private _ev: EventEmitterBase.EventEmitter;
    constructor() {
        this._ev = new EventEmitterBase.EventEmitter();
        this._ev.setMaxListeners(MAX_LISTENERS);
    }

    on = (type: string, listener: any) => {
        return this._ev.addListener(type, listener);
    };
    emit = (type: string, data?: any) => {
        return this._ev.emit(type, data);
    };
    off = (type: string, callback: any) => {
        return this._ev.removeListener(type, callback);
    };
}

export default EventEmitter;
