import * as React from "react";
import { ISVGCustomProps } from "ui/components/icon/customIcons/customIconsProps";

export const svgMinigolf = function (props: ISVGCustomProps): JSX.Element {
    return (
        <svg className="svg-inline--fa fa-w-11 fa-fw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" color={props.color}>
            <path
                fill="currentcolor"
                d="M221.84,169.83H150.05v-20h66.68L177.82-.17H62.19l-20.75,80h64.62v20H36.31L0,239.83H240Zm-101.65-130c-12,0-21.73-4.48-21.73-10s9.73-10,21.73-10,21.73,4.48,21.73,10S132.19,39.83,120.19,39.83Z"
            />
        </svg>
    );
};
