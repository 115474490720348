import * as Immutable from "immutable";

import Action from "framework/actions/action";

export class LastEventNumberUpdated extends Action {
    static actionType: string = "event_LastEventNumberUpdated";

    eventNumber: number;
    constructor(eventNumber: number) {
        super(false);
        this.eventNumber = eventNumber;
        this.doDispatch();
    }
}

export class GetLastEventNumberSuccess extends Action {
    static actionType: string = "event_GetLastEventNumberSuccess";

    eventNumber: number;
    constructor(eventNumber: number) {
        super(false);
        this.eventNumber = eventNumber;
        this.doDispatch();
    }
}

export class PendingOutOfSync extends Action {
    static actionType: string = "event_PendingOutOfSync";

    constructor() {
        super();
    }
}

// ----------- ReplayEvents (normal, success, fail) -----------
export class ReplayEvents extends Action {
    static actionType: string = "event_ReplayEvents";

    constructor() {
        super();
    }
}

export class ReplayEventsSuccess extends Action {
    static actionType: string = "event_ReplayEvents_success";

    events: Immutable.List<string>;
    constructor(events: Immutable.List<string>) {
        super(false);
        this.events = events;
        this.doDispatch();
    }
}

export class ReplayEventsFail extends Action {
    static actionType: string = "event_ReplayEvents_fail";

    constructor() {
        super();
    }
}

export class Event extends Action {
    static actionType: string = "event_Event";

    event: string;
    clientId: string;
    constructor(event: string, clientId: string) {
        super(false);
        this.event = event;
        this.clientId = clientId;
        this.doDispatch();
    }
}

export class SetConnectionId extends Action {
    static actionType: string = "event_set_connection_id";

    constructor(public connectionId: string) {
        super(false);
        this.doDispatch();
    }
}
