import * as React from "react";
import { ISVGCustomProps } from "ui/components/icon/customIcons/customIconsProps";

export const svgShooting = function (props: ISVGCustomProps): JSX.Element {
    return (
        <svg className="svg-inline--fa fa-w-11 fa-fw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" color={props.color}>
            <ellipse fill="currentcolor" cx="120.06" cy="120.12" rx="26.38" ry="26.26"></ellipse>
            <path fill="currentcolor" d="M6.16,114H71.84A6.07,6.07,0,0,1,78,120h0a6.08,6.08,0,0,1-6.16,6H6.16A6.08,6.08,0,0,1,0,120H0A6.07,6.07,0,0,1,6.16,114Z"></path>
            <path fill="currentcolor" d="M126,169v65.41a6.07,6.07,0,0,1-6,6.13h0a6.07,6.07,0,0,1-6-6.13V169a6.07,6.07,0,0,1,6-6.13h0A6.07,6.07,0,0,1,126,169Z"></path>
            <path fill="currentcolor" d="M126,6.64V72.05a6.07,6.07,0,0,1-6,6.13h0a6.07,6.07,0,0,1-6-6.13V6.64a6.07,6.07,0,0,1,6-6.13h0A6.07,6.07,0,0,1,126,6.64Z"></path>
            <path fill="currentcolor" d="M167.92,137.93A51.68,51.68,0,0,1,138,167.7v16.63a67.51,67.51,0,0,0,46.63-46.4Z"></path>
            <path fill="currentcolor" d="M232.5,102.08A114,114,0,0,0,138,8V37a85.61,85.61,0,0,1,65.44,65.13Z"></path>
            <path fill="currentcolor" d="M102,167.7a51.68,51.68,0,0,1-29.92-29.77H55.38A67.48,67.48,0,0,0,102,184.33Z"></path>
            <path fill="currentcolor" d="M167.92,102.08h16.71A67.49,67.49,0,0,0,138,55.68V72.3A51.67,51.67,0,0,1,167.92,102.08Z"></path>
            <path fill="currentcolor" d="M203.44,137.93A85.58,85.58,0,0,1,138,203.05V232a114.05,114.05,0,0,0,94.51-94Z"></path>
            <path fill="currentcolor" d="M102,203.05a85.58,85.58,0,0,1-65.44-65.12H7.49A114.06,114.06,0,0,0,102,232Z"></path>
            <path fill="currentcolor" d="M102,55.68a67.46,67.46,0,0,0-46.62,46.4h16.7A51.7,51.7,0,0,1,102,72.3Z"></path>
            <path fill="currentcolor" d="M102,8A114,114,0,0,0,7.49,102.08H36.56A85.61,85.61,0,0,1,102,37Z"></path>
            <path fill="currentcolor" d="M168.16,114h65.68a6.07,6.07,0,0,1,6.16,6h0a6.08,6.08,0,0,1-6.16,6H168.16a6.08,6.08,0,0,1-6.16-6h0A6.07,6.07,0,0,1,168.16,114Z"></path>
        </svg>
    );
};
