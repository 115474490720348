import * as bowser from "bowser";

export function isSafariOnMac() {
    return bowser.mac && bowser.safari;
}

export function isSafari() {
    return bowser.safari;
}

export function isIpad() {
    return navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
}

export function isInternetExplorer() {
    return bowser.msie && bowser.version === "11.0";
}

export function isAndroid() {
    return bowser.android;
}

export function isIOs() {
    return bowser.ios;
}
