import * as Moment from "moment-timezone";

import Insights from "framework/modules/insights";
import TypeEnum from "./toasterTypeEnum";
import SizeTypeEnum from "./toasterSizeTypeEnum";

import { RecordFactory } from "common/modules/recordFactory";
import rg4js from "raygun4js";

export interface IServerErrorInfo {
    messageKey?: string;
    errorCode?: string;
    code?: string;
    referenceId?: string;
    properties?: any;
}

export interface IToastValues {
    id?: number;
    /** If provided, only one toast with this name will be shown at a time. Use to avoid unwanted duplicates of some toast messages */
    uniqueName?: string | null;
    message?: string;
    heading?: string;
    type: TypeEnum;
    toasterSize?: SizeTypeEnum;
    component?: any;
    /** An optional image can be provided for large toasters. */
    imgSource?: string;
    detail?: any;
    timeStamp: IMoment;
    /** Timeout will only work if alwaysVisible is false. */
    timeout?: number | null;
    /** If true. Toaster will be visible til user clicks on it. */
    alwaysVisible?: boolean;
    /** If true, toaster will be hidden and moved to history on create */
    historyOnly?: boolean;
    serverErrorInfo?: IServerErrorInfo | null;
    onClick?: any;
    onClickRoute?: string | null;
}

export interface IToastRecord extends Toast {}

let _idCounter = 0;
function getId() {
    return _idCounter++;
}

const ToastRecord = RecordFactory<IToastValues>({
    id: -1,
    uniqueName: null,
    message: "",
    heading: "",
    type: TypeEnum.Information,
    toasterSize: SizeTypeEnum.Small,
    component: null,
    imgSource: "",
    detail: null,
    timeout: null,
    timeStamp: Moment(),
    serverErrorInfo: null,
    onClick: null,
    onClickRoute: null,
    alwaysVisible: false,
    historyOnly: false,
});
/**
 * @deprecated use new Toast() instead
 */
export function Record(values: Partial<IToastValues>) {
    return new Toast(values);
}

export class Toast extends ToastRecord implements IToastValues {
    public readonly id: number;
    constructor(values: Readonly<Partial<IToastValues>>) {
        let updatedValues: Partial<IToastValues> = {};
        if (values) {
            updatedValues.id = getId();
            if (values.type === TypeEnum.Error && values.detail) {
                let e;
                let customData;
                if (!values.detail.operation && !values.detail.response) {
                    customData = values;
                }
                try {
                    if (values.detail.error instanceof Error) {
                        e = values.detail.error;
                    } else if (values.detail.error.message) {
                        e = new Error(values.detail.error.message);
                        customData ||= values.detail.error;
                    } else {
                        e = new Error(values.detail.error);
                    }
                } catch (ex) {
                    e = new Error("Toast Error '" + values.heading + "'");
                }
                try {
                    rg4js("send", { error: e, customData, tags: ["toastError"] });
                } catch (error) {
                    console.log("Unable to initialize raygun!", error);
                }
                //appInsights.trackException(new Error("Toast Error '" + values.heading + "'"));
                Insights.trackException(e);
            }
            updatedValues.timeStamp = Moment();
        }
        super({ ...values, ...updatedValues });
    }
}
