import * as Immutable from "immutable";

import AppDispatcher from "framework/dispatcher/appDispatcher";
import Store from "framework/stores/store";
import { DevTools, devTools, isDev } from "framework/stores/reduxDevTools";

export type Reducer<S, A> = (state: S | undefined, action: A) => S;

type ReducersMapObject<S, A> = {
    [K in keyof S]: Reducer<S[K], A>;
};

export class ReduceStore<S, A> extends Store<S, S> {
    private rootReducer: Reducer<S, A>;
    private devTools: DevTools | undefined;

    constructor(reducer: Reducer<S, A>) {
        super();
        this.rootReducer = reducer;
        // hooks into browser extension if it is installed and activated
        this.devTools =
            devTools && isDev
                ? devTools.connect({
                      // sets up immutable typings
                      serialize: { immutable: Immutable },
                      // name of instance, otherwise store will get name like "Instance 10"
                      name: reducer.name,
                  })
                : undefined;
        this._internalState = this.rootReducer(undefined, <any>{ actionType: undefined });
        // passes initalstate of reducer to devtools, usually empty state
        this.devTools?.init(this._internalState);
    }

    dispatcherIndex: string = AppDispatcher.register((payload: any): void => {
        const actionType = payload.action?.type ?? payload.action?.actionType;
        const nextState = this.rootReducer(this._internalState, payload.action);
        if (nextState !== this._internalState) {
            this._internalState = nextState;
            this.emitChange(this._internalState);
            // Any time a new state is created we send this to devTools accociated with the dispatched action
            try {
                this.devTools?.send(actionType, nextState);
            } catch {
                // NOOP
            }
        }
    });
}

export const combineReducers = <S, A>(reducers: ReducersMapObject<S, A>): Reducer<S, A> => {
    const reducerKeys = Object.keys(reducers);
    const len = reducerKeys.length;

    return (state: S = <S>{}, action: A): S => {
        const nextState: any = {};

        let hasChanged = false;

        for (let i = 0; i < len; i++) {
            const key = reducerKeys[i];
            const reducer: Reducer<S, A> = (<any>reducers)[key];
            const prevStateForKey: S = (<any>state)[key];
            const nextStateForKey = reducer(prevStateForKey, action);

            nextState[key] = nextStateForKey;
            hasChanged = hasChanged || prevStateForKey !== nextStateForKey;
        }

        return hasChanged ? nextState : state;
    };
};

export const set = <K extends keyof S, S>(state: S, key: K, value: S[K]): S => {
    return {
        ...state,
        [key]: value,
    };
};

export const assign = <S>(state: S, values: Partial<S>): S => {
    return {
        ...state,
        ...values,
    };
};
