/* eslint-disable no-restricted-imports */
import { IsoCountryCode } from "application/models/management/user";
import { ILocaleProps } from "common/contexts/localeContext";
import { format, FormatType } from "common/modules/timeHelper";
import { LocaleStore } from "framework/stores/localeStore";

import IntlMessageFormat from "intl-messageformat";
import MarkUp, { MarkupOptions } from "common/modules/markup";

export interface ILocaleHelperParams {
    systemLanguage?: string;
    markUp?: never;
    [key: string]: unknown;
}

export interface ITranslationEntry {
    key: string;
    params?: ILocaleHelperParams;
}

export type TranslationEntry = { [key: string]: { id: string; defaultMessage: string | string[] } };

export enum DateFormat {
    short,
    long,
    shortWithDash,
    longWithDash,
}

export function translatedMarkup(key: string, params?: ILocaleHelperParams, markupOptions?: MarkupOptions) {
    return applyMarkup(translate(key, params), markupOptions);
}

export function applyMarkup(translatedText: string, markupOptions?: MarkupOptions) {
    try {
        return MarkUp(markupOptions).parse(translatedText);
    } catch (e) {
        return translatedText;
    }
}

/**
 * @deprecated For Common and UI: Use LocaleContext instead
 */
export function translate(key: string, params?: ILocaleHelperParams): string {
    let getMessages;
    const locale = LocaleStore.getLocaleString();
    getMessages = () => LocaleStore.getMessages();
    return <string>translateInternal(key, params, locale, getMessages);
}

export function translateInternal(key: string, params: ILocaleHelperParams | undefined, locale: string, getMessages: () => TranslationEntry) {
    const messages = getMessages();

    let text;
    try {
        text = messages[key].defaultMessage;
        if (!params && !Array.isArray(text)) {
            return text;
        }
        if (Array.isArray(text)) {
            return text.map((textPart) => (params ? new IntlMessageFormat(textPart, locale).format(params) : textPart));
        } else {
            if (!params) return text;
            return new IntlMessageFormat(text, locale).format(params || {});
        }
    } catch (e) {
        // Key is always the same regardless which locale is used
        return <string>(<unknown>text) || key;
    }
}

const translateWithCurrency = (currency: ReturnType<typeof currencyFactory>) => {
    const currencySymbol = currency("symbol");
    const currencyShort = currency("short");
    const currencyLong = currency("long");
    return (key: string, params: ILocaleHelperParams = {}) =>
        translate(key, {
            currencySymbol,
            currencyShort,
            currencyLong,
            ...params,
        });
};

const currencyFactory = (currencyCode: string, prefix?: string) => {
    prefix = prefix ? `${prefix}.` : "";
    return (currencyFormat: "short" | "long" | "symbol") => {
        return translate(`${prefix}currency.${currencyCode}.${currencyFormat}`);
    };
};

export const numberFormat = (value: number, decimals?: number, minDecimals?: number, minDecimalsIfNoDecimals?: boolean): string => {
    try {
        if (decimals === undefined || decimals === null) {
            decimals = 2;
        }
        if (minDecimals === undefined || minDecimals === null) {
            minDecimals = decimals;
        }
        if (minDecimalsIfNoDecimals && value % 1 === 0) {
            minDecimals = 0;
        }

        const locale = LocaleStore.getLocaleString();
        return Intl.NumberFormat(locale, { minimumFractionDigits: minDecimals, maximumFractionDigits: decimals }).format(value).replace("−", "-");
    } catch (e) {
        return "[" + value + " + " + decimals + "]";
    }
};

export const dateFormatString = (dateFormat: DateFormat, prefix?: string) => {
    prefix = prefix ? `${prefix}.` : "";
    const separator = translate(`${prefix}dateSeparator`);
    switch (dateFormat) {
        case DateFormat.short:
            return translate(`${prefix}dateFormatShort`).split(separator).join("");
        case DateFormat.shortWithDash:
            return translate(`${prefix}dateFormatShort`);
        case DateFormat.long:
            return translate(`${prefix}dateFormat`).split(separator).join("");
        default:
            return translate(`${prefix}dateFormat`);
    }
};

export const dateFormatRegexp = (dateFormat: DateFormat) => {
    const separator = translate("dateSeparator");
    let regexStr = "";
    switch (dateFormat) {
        case DateFormat.short:
            regexStr = translate("dateFormatShortRegexp").split(separator).join("");
            break;
        case DateFormat.shortWithDash:
            regexStr = translate("dateFormatShortRegexp");
            break;
        case DateFormat.long:
            regexStr = translate("dateFormatRegexp").split(separator).join("");
            break;
        default:
            regexStr = translate("dateFormatRegexp");
            break;
    }
    regexStr = regexStr.replace(/d/g, "\\d").replace(/\./g, "\\.");
    return new RegExp(regexStr);
};

/**
 * Get formats: YY-MM-DD / YYYY-MM-DD / YYYY-MM-DD HH:mm/ss
 * @param format Our own date format types
 */
export const getLocaleFormat = (formatType: FormatType): Intl.DateTimeFormatOptions => {
    switch (formatType) {
        // YY-MM-DD
        case FormatType.ShortDate:
            return { year: "2-digit", month: "2-digit", day: "2-digit" };
        // YYYY-MM-DD
        case FormatType.Date:
        case FormatType.InternalDate:
            return { year: "numeric", month: "2-digit", day: "2-digit" };
        // YYYY-MM-DD HH:mm
        case FormatType.DateTime:
        case FormatType.Time:
        case FormatType.InternalFullDateTime:
            return {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
            };
        // YYYY-MM-DD HH:mm:ss
        case FormatType.FullDateTime:
            return {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            };
        // HH:mm
        default:
            return { hour: "2-digit", minute: "2-digit" };
    }
};

/**
 * Not sure how when this is supposed to be used
 * @param format Our own date format types
 */
export const getLocaleFormatEmptyString = (formatType: FormatType): string => {
    switch (formatType) {
        case FormatType.Date:
        case FormatType.ShortDate:
            return "-";
        case FormatType.DateTime:
        case FormatType.Time:
            return "- --:--";
        case FormatType.FullDateTime:
            return "- --:--:--";
        case FormatType.InternalFullDateTime:
        case FormatType.InternalDate:
            return "";
        default:
            return "--:--";
    }
};

/**
 * Get separator between dates ('/'/'-'/'.') to produce shortdate: YYMMDD
 * @param date Date used to get current locale format
 */
const getDateSeparator = (date: IMoment) => {
    const locale = LocaleStore.getLocaleString();
    const dateParts = new Intl.DateTimeFormat(locale).formatToParts(date.toDate());
    const separator = dateParts.find((part) => part.type === "literal");
    return separator ? separator.value : "-";
};

/**
 * Format date using current locale and Intl.DateTimeFormat
 * @param date Date to format
 * @param format Our own date format types
 * @param timeZoneName If timezone must be set explicitly (default is "Europe/Stockholm")
 */
export const localeContextDateFormat = (date: IMoment | undefined, formatType: FormatType, timeZoneName?: string, _useMomentTimezone?: boolean, locale?: string): string => {
    if (!date) return "";
    const localeFormat = getLocaleFormat(formatType);
    localeFormat.timeZone = timeZoneName || "Europe/Stockholm";
    const currentLocale = locale ? locale : LocaleStore.getLocaleString();
    const dtf = new Intl.DateTimeFormat(currentLocale, localeFormat);
    if (formatType === FormatType.ShortDate) {
        const separator = getDateSeparator(date);
        return dtf.format(date.toDate()).split(separator).join("");
    }
    return dtf.format(date.toDate()).replace(/\,/g, "");
};

/**
 * Wrapper around localeContextDateFormat to send locale explicitly
 * @param locale pass in the locale, 'sv-SE' by default
 */
export const dateFormatWithLocale = (locale: string = "sv-SE") => {
    return (date: IMoment | undefined, formatType: FormatType, timeZoneName?: string, _useMomentTimezone?: boolean) => {
        return localeContextDateFormat(date, formatType, timeZoneName, _useMomentTimezone, locale);
    };
};

export const currentLocaleDateFormat = dateFormatWithLocale(LocaleStore.getLocaleString());

export const formatWithPrefix = (prefix?: string) => {
    return (moment: IMoment | undefined, formatType?: FormatType, timeZoneName?: string, _useMomentTimezone?: boolean) => {
        return format(moment, formatType, timeZoneName, _useMomentTimezone, prefix);
    };
};

export const getCurrentLocale = () => {
    return LocaleStore.getLocaleString();
};

export const contextProps = (currencyCode: string, contextOptions?: Partial<ILocaleProps>, prefix?: string) => {
    const currency = currencyFactory(currencyCode, prefix);
    const dateFormat = formatWithPrefix(prefix);
    return {
        translate: translateWithCurrency(currency),
        translatedMarkup,
        numberFormat,
        currency: currency,
        dateFormat: dateFormat,
        getCurrentLocale: getCurrentLocale,
        ...contextOptions,
    };
};

export const getLocaleString = translate;

export default {
    translate: translate,
    getLocaleString: translate,
    numberFormat: numberFormat,
    dateFormatString: dateFormatString,
    dateFormatRegexp: dateFormatRegexp,
    getCurrentLocale: getCurrentLocale,
    DateFormat: DateFormat,
};

export const vatItems = new Map<IsoCountryCode, { id: number; text: string }[]>([
    [
        IsoCountryCode.SE,
        [
            { id: 0, text: "0.00%" },
            { id: 6, text: "6.00%" },
            { id: 12, text: "12.00%" },
            { id: 25, text: "25.00%" },
        ],
    ],
    [
        IsoCountryCode.NO,
        [
            { id: 0, text: "0.00%" },
            { id: 12, text: "12.00%" },
            { id: 15, text: "15.00%" },
            { id: 25, text: "25.00%" },
        ],
    ],
    [
        IsoCountryCode.NL,
        [
            { id: 0, text: "0.00%" },
            { id: 9, text: "9.00%" },
            { id: 21, text: "21.00%" },
        ],
    ],
    [
        IsoCountryCode.EE,
        [
            { id: 0, text: "0.00%" },
            { id: 9, text: "9.00%" },
            { id: 2, text: "20.00%" },
        ],
    ],
    [
        IsoCountryCode.FI,
        [
            { id: 0, text: "0.00%" },
            { id: 10, text: "10.00%" },
            { id: 14, text: "14.00%" },
            { id: 24, text: "24.00%" },
        ],
    ],
    [
        IsoCountryCode.ES,
        [
            { id: 0, text: "0.00%" },
            { id: 4, text: "4.00%" },
            { id: 10, text: "10.00%" },
            { id: 21, text: "21.00%" },
        ],
    ],
    [
        IsoCountryCode.LT,
        [
            { id: 0, text: "0.00%" },
            { id: 5, text: "5.00%" },
            { id: 9, text: "9.00%" },
            { id: 21, text: "21.00%" },
        ],
    ],
    [
        IsoCountryCode.LV,
        [
            { id: 0, text: "0.00%" },
            { id: 5, text: "5.00%" },
            { id: 12, text: "12.00%" },
            { id: 21, text: "21.00%" },
        ],
    ],
    [
        IsoCountryCode.BE,
        [
            { id: 0, text: "0.00%" },
            { id: 6, text: "6.00%" },
            { id: 12, text: "12.00%" },
            { id: 21, text: "21.00%" },
        ],
    ],
    [
        IsoCountryCode.DK,
        [
            { id: 0, text: "0.00%" },
            { id: 25, text: "25.00%" },
        ],
    ],
]);

export const getVatItems = (countryCode: IsoCountryCode, defaultCountryCode: IsoCountryCode = IsoCountryCode.SE) => {
    const _vatItems = vatItems.get(countryCode);
    return _vatItems ? _vatItems : vatItems.get(defaultCountryCode);
};
