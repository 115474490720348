import type { UserManagerSettings } from "oidc-client-ts";
import { EStorageType, StorageManager } from "application/storageManager/storageManager";
import { parse } from "qs";
import { isApp } from "framework/modules/browserCheckHelper";

declare const SSO_HOSTS: string[];

const query = parse(document.location.search, { ignoreQueryPrefix: true });
const baseUrl = window.location.origin;
const client_id = "cloudApp";

const _storageManager: StorageManager = StorageManager.GetInstance();
let otpUsername = query.username?.toString();
let otpPassword = query.password?.toString();
let authority = query.ssoHost?.toString() ?? _storageManager.retrieve("ssoHost", EStorageType.SessionStorage);
if (authority) {
    _storageManager.store("ssoHost", authority, EStorageType.SessionStorage);
}
if (!authority || !SSO_HOSTS.includes(authority)) {
    authority = SSO_HOSTS[0]; // reset to default, first in array
}
if (otpUsername && !otpUsername.startsWith("otp:")) {
    // if username is not of otp-type, clear it to avoid sending user password in query
    otpUsername = undefined;
    otpPassword = undefined;
}
const lang = query.lang?.toString() ?? _storageManager.retrieve("locale", EStorageType.LocalStorage, true);

let extraQueryParams: UserManagerSettings["extraQueryParams"];

if (otpUsername && otpPassword) {
    extraQueryParams = {
        otpUsername: otpUsername,
        otpPassword: otpPassword,
    };
}

if (lang) {
    extraQueryParams = {
        ...(extraQueryParams ?? {}),
        culture: lang,
    };
}

if (isApp()) {
    extraQueryParams = {
        ...(extraQueryParams ?? {}),
        isApp: 1,
    };
}

export const settings: UserManagerSettings = {
    authority: authority,
    client_id: client_id,
    response_type: "code",
    scope: "openid profile api1",
    automaticSilentRenew: true,
    stopCheckSessionOnError: false,
    redirect_uri: `${baseUrl}/oidc.html?redirect`,
    popup_redirect_uri: `${baseUrl}/oidc.html?popup`,
    post_logout_redirect_uri: `${baseUrl}/index.html`,
    silent_redirect_uri: `${baseUrl}/oidc.html?silent`,
    extraQueryParams,
};
