import { appDispatcher } from "framework/dispatcher/appDispatcher";
import { AnyAction } from "@reduxjs/toolkit";
import { reduxStoreWrapperDispatchers } from "framework/stores/reduxStoreWrapper";

export class Action {
    constructor(performDispatch: boolean = true) {
        if (performDispatch) {
            Action.dispatch(this);
        }
    }

    doDispatch() {
        Action.dispatch(this);
    }

    static dispatch<Taction extends Action>(action: Taction) {
        // NOTE: Hack to prevent "dispatch in middle of dispatch" in actions
        if (!appDispatcher.isDispatching()) appDispatcher.dispatchViewAction(action);
        else
            setTimeout(() => {
                appDispatcher.dispatchViewAction(action);
            }, 0);
    }
}

export abstract class ReduceAction extends Action {
    public abstract actionType: string;

    constructor() {
        super(false);
    }

    doDispatch() {
        if (!appDispatcher.isDispatching()) Action.dispatch(this);
    }
}

export const dispatch = (dispatchAction: AnyAction, ignoreReduxStoreWrapperDispatchers?: boolean): void => {
    if (!ignoreReduxStoreWrapperDispatchers) reduxStoreWrapperDispatchers.forEach((d) => d(dispatchAction));
    if (!appDispatcher.isDispatching()) appDispatcher.dispatchViewAction(dispatchAction);
    else
        setTimeout(() => {
            appDispatcher.dispatchViewAction(dispatchAction);
        }, 0);
};

export default Action;
