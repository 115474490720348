import * as React from "react";

import { IDeviceDataProps, Orientation, RenderMode, withDeviceData } from "common/hocs/withDeviceData";

export type RenderData = {
    device?: RenderMode;
    deviceOrientation?: Orientation;
};

interface IResponsiveRenderProps<T> extends IDeviceDataProps {
    renderData?: T; // TODO: Should be Object, but then we can't assign new props, as we do in render, hmmm
    renderDesktop: (data?: T) => JSX.Element | JSX.Element[] | null;
    renderMobile: (data?: T) => JSX.Element | JSX.Element[] | null;
    renderTablet?: (data?: T) => JSX.Element | JSX.Element[] | null;
}

class ResponsiveRenderer<T extends RenderData> extends React.Component<IResponsiveRenderProps<T>, {}> {
    render() {
        const data: T = this.props.renderData || ({} as T);
        data.device = this.props.renderModeDevice;
        data.deviceOrientation = this.props.renderModeOrientation;

        if (this.props.isMobile) {
            return this.props.renderMobile(data);
        } else if (this.props.isTablet && this.props.renderTablet) {
            return this.props.renderTablet(data);
        } else {
            return this.props.renderDesktop(data);
        }
    }
}

export default withDeviceData<IResponsiveRenderProps<unknown>>(ResponsiveRenderer);
