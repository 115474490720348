import * as React from "react";
import classnames from "classnames";
import { CaspecoColors } from "ui/caspecoColors";

import "./header.less";

export type HeaderLevel = 1 | 2 | 3 | 4 | 5;

interface IHeaderProps {
    /**
     * A number between 1-5. Level for header, the greater the number the smaller the font size.
     * Think of it as a <header> element. Meaning level={1} will equal h1, the largest header.
     */
    level?: HeaderLevel;
    /** Optional color for text. */
    color?: string;
    /** Optional text alignment. */
    align?: "left" | "right" | "center";
}

export const Header: React.FC<PropsWithImmutableChildren<IHeaderProps>> = ({ children, color, level, align }) => {
    const className = classnames({
        header: true,
        [`header_level-${level}`]: true,
        [`header-align-${align}`]: align,
    });

    return (
        <div className={className} style={{ color: color ? color : CaspecoColors.GraphiteGrey }}>
            {children}
        </div>
    );
};

Header.defaultProps = {
    level: 1,
};
