import { toSystemTime } from "common/modules/timeHelper";
import * as moment from "moment-timezone";

export const setMomentTime = (momentToSet: IMoment, sourceMoment: IMoment, setTimeZone: boolean = false): IMoment => {
    //clone the moment
    let returnMoment = moment(momentToSet);
    let tz = 0;
    if (setTimeZone) {
        returnMoment = toSystemTime(momentToSet);
        tz = returnMoment.tz.length;
    }
    returnMoment.set("hour", sourceMoment.get("hour") + tz);
    returnMoment.set("minute", sourceMoment.get("minute"));
    returnMoment.set("second", sourceMoment.get("second"));
    returnMoment.set("milliseconds", 0);

    return returnMoment;
};

export const setMomentDate = (momentToSet: IMoment, sourceMoment: IMoment): IMoment => {
    //clone the moment
    const returnMoment = moment(momentToSet);
    returnMoment.set("year", sourceMoment.get("year"));
    returnMoment.set("month", sourceMoment.get("month"));
    returnMoment.set("date", sourceMoment.get("date"));

    return returnMoment;
};

//wait for new moment release, this will be added
export const roundToMinute = (m: IMoment, roundTo: number): IMoment => {
    const returnMoment = moment(m);
    roundTo = roundTo || 1;
    let crap = Math.round(returnMoment.minutes() / roundTo);
    crap = crap * roundTo;

    returnMoment.seconds(0);
    returnMoment.minutes(crap);

    return returnMoment;
};

export const discardTimeZone = (m: IMoment | null | undefined): IMoment | null => {
    return m ? moment(m.format("YYYY-MM-DDTHH:mm:ss") + ".000Z") : null;
};
