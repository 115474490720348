import moment, * as Moment from "moment-timezone";
import { List } from "immutable";
import { DateFormat, dateFormatRegexp, dateFormatString, translate } from "common/mixins/localeHelper";
import { defaultMemoize } from "reselect";
import { DateRange } from "common/modules/dateRange";

function setYearForBirthDate(date: IMoment): void {
    if (date && date.isValid()) {
        const currentYear = Moment().year();
        const currentDecennium = currentYear % 100;
        const decennium = date.year() % 100;
        const currentCentury = currentYear - currentDecennium;
        const year = decennium + (decennium >= currentDecennium ? currentCentury - 100 : currentCentury);
        date.year(year);
    }
}

export function isDayIn(day: IMoment, days: IMoment[]): boolean {
    if (!days) return false;
    for (let i = 0; i < days.length; i++) {
        if (days[i].isSame(day, "day")) return true;
    }
    return false;
}

export function isDayOutsideOfInterval(day: IMoment, minDay: IMoment, maxDay: IMoment): boolean {
    return (minDay && minDay.isAfter(day, "day")) || (maxDay && maxDay.isBefore(day, "day"));
}

export function isDayInInterval(day: IMoment, fromDateInclusive: IMoment, toDateExclusive: IMoment): boolean {
    return day && (!fromDateInclusive || fromDateInclusive.isBefore(day, "days") || fromDateInclusive.isSame(day, "days")) && (!toDateExclusive || toDateExclusive.isAfter(day, "days"));
}

export function parseDate(textDate: string | IMoment | null, allowUnset: boolean, isBirthDate: boolean = false): IMoment | null {
    if (textDate instanceof Moment || textDate === null) {
        return <IMoment>textDate;
    }
    if (textDate === "" && allowUnset) {
        return null;
    }

    textDate = <string>textDate;
    let date: IMoment | null = null;

    if (textDate.match(dateFormatRegexp(DateFormat.short))) {
        date = Moment(<string>textDate, dateFormatString(DateFormat.short));
        if (isBirthDate) {
            setYearForBirthDate(date);
        }
        return date.isValid() ? date : null;
    }

    if (textDate.match(dateFormatRegexp(DateFormat.long))) {
        date = Moment(textDate, dateFormatString(DateFormat.long));
        return date.isValid() ? date : null;
    }

    if (textDate.match(dateFormatRegexp(DateFormat.shortWithDash))) {
        date = Moment(textDate, dateFormatString(DateFormat.shortWithDash));
        if (isBirthDate) {
            setYearForBirthDate(date);
        }
        return date.isValid() ? date : null;
    }

    if (textDate.match(dateFormatRegexp(DateFormat.longWithDash))) {
        date = Moment(textDate, dateFormatString(DateFormat.longWithDash));
        return date.isValid() ? date : null;
    }

    if (textDate.match(/^\d\d\d\d-\d\d-\d\d$/)) {
        date = Moment(textDate, "YYYY-MM-DD");
        return date.isValid() ? date : null;
    }

    return null;
}

export function parseWeek(text: string): IMoment | null {
    if (!text) {
        return null;
    }
    const week: string = text.replace(/\D*/g, ""); // Remove all non digit chars
    if (week && +week > 0 && +week < 54) {
        return Moment.utc().startOf("isoWeek").isoWeek(+week);
    }
    return null;
}

export function getMonthDateFormat(moment: IMoment | undefined) {
    if (moment) {
        const toSelectedDateText: string = moment.format("MMMM YYYY");
        return toSelectedDateText[0].toUpperCase() + toSelectedDateText.substring(1);
    } else {
        return translate("noDateSelected");
    }
}

export function monthsBetweenRange(fromDate: IMoment, toDate: IMoment) {
    const timeValues = [];
    while (toDate > fromDate || fromDate.format("M") === toDate.format("M")) {
        timeValues.push(fromDate.format("MMM"));
        fromDate.add(1, "month");
    }
    return timeValues;
}

export const getDatesBetweenDates = defaultMemoize((fromDate: IMoment, toDate: IMoment, format?: string) => {
    let datesToRender = List<string | IMoment>();
    let current = fromDate.clone().startOf("day");
    const stop = toDate.clone().endOf("day");
    while (current.isBefore(stop)) {
        const date = format ? current.format(format) : current;
        datesToRender = datesToRender.push(date);
        current = current.clone().add(1, "day");
    }
    return datesToRender;
});

export const clearTime = (value: IMoment) => {
    return value.hours(0).minutes(0).seconds(0).milliseconds(0);
};

export const setTime = (date: IMoment, time: IMoment) => {
    return date.set({
        hour: time.get("hour"),
        minute: time.get("minute"),
        second: time.get("second"),
        milliseconds: time.get("milliseconds"),
    });
};

export function getWeekStartAndEndDates(date: IMoment) {
    const startOfWeek = moment(date).startOf("week");
    const endOfWeek = moment(date).endOf("week");

    return new DateRange({ start: startOfWeek, end: endOfWeek });
}

export class Weekday {
    public index: number;
    public name: string;
    public momentIndex: number;
}

export function getWeekday(): List<Weekday> {
    return List([
        {
            index: 1,
            momentIndex: 1,
            name: translate("common.weekdays.monday"),
        },
        {
            index: 2,
            momentIndex: 2,
            name: translate("common.weekdays.tuesday"),
        },
        {
            index: 3,
            momentIndex: 3,
            name: translate("common.weekdays.wednesday"),
        },
        {
            index: 4,
            momentIndex: 4,
            name: translate("common.weekdays.thursday"),
        },
        {
            index: 5,
            momentIndex: 5,
            name: translate("common.weekdays.friday"),
        },
        {
            index: 6,
            momentIndex: 6,
            name: translate("common.weekdays.saturday"),
        },
        {
            index: 7,
            momentIndex: 0,
            name: translate("common.weekdays.sunday"),
        },
    ]);
}

export enum IsoDayOfWeek {
    None = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7,
}
