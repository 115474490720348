/// <reference path="../typings/tsd.d.ts" />
/// <reference path="../typings/slate.d.ts" />

import * as React from "react";

import App from "framework/app";
(() => {
    import("framework/modules/events/events");
})();

import { createRoot } from "react-dom/client";

import { setLanguage } from "framework/modules/setLanguage";

window.React = React;

import "./index.less";

const rootEl = document.getElementById("application");

const root = createRoot(rootEl!);

root.render(<App />);

setLanguage();
