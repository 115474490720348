import * as React from "react";

import * as copy from "copy-to-clipboard";

import css from "common/modules/cssBuilder";

import { Icon } from "ui/components/icon/icon";
import { CaspecoColors } from "ui/caspecoColors";
import { LocaleContext } from "common/contexts/localeContext";
import { Popup } from "ui/components/popup/popup";

import "./text.less";

export type FontWeight = "regular" | "medium" | "bold";
export type FontSizeType = "small" | "medium" | "large" | "Xlarge" | "XXlarge";
export type ElementType = "span" | "div" | "p";

export interface ITextProps {
    /** This is an id. */
    id?: string;
    /** Adds possibility to align text to the right. */
    align?: string;
    /** Color */
    color?: string;
    /** Gives text a light yellow background. */
    highlight?: boolean;
    /** Sets font weight for text. Can be "regular", "medium" or "bold", defaults to "regular". */
    fontWeight?: FontWeight;
    /** Makes the text italic. */
    italic?: boolean;
    /** Gives the text underline. */
    underline?: boolean;
    /** Gives the text a strikethrough line */
    strikethrough?: boolean;
    /** Formats text into code block with monospace and border */
    code?: boolean;
    /** Changes text font family to monospace */
    monospace?: boolean;
    /** Adds text to clipboard on click */
    copyable?: boolean;
    /** Adds text transform "uppercase" */
    uppercase?: boolean;
    /** Value to pass to further action saved as string or JSX-element. */
    value?: string | JSX.Element;
    /** Optional onClick */
    onClick?: (value?: unknown) => void;
    /** Render as a different element type than span */
    as?: ElementType;
    /** Sets font size. Choose between "small", "medium" or "large" */
    fontSize?: FontSizeType;
}

/** Simple text element.
 * Can be found in Personal --> Personregister --> (Choose person).
 */

export const Text: React.FunctionComponent<PropsWithImmutableChildren<ITextProps>> = (props) => {
    Text.displayName = "Text";
    const context = React.useContext(LocaleContext);

    const [copied, setCopied] = React.useState<string>("");

    const handleClick = () => {
        if (props.copyable && props.value) {
            copyToClipboard(props.value?.toString());
        }
        if (props.onClick) {
            props.onClick();
        }
    };

    const copyToClipboard = (value: string) => {
        if (copy(value)) {
            setCopied(value);
            setTimeout(() => {
                setCopied("");
            }, 1000);
        }
    };

    const className = css(
        "text",
        false,
        "highlight",
        props.highlight,
        props.fontWeight ?? "regular",
        true,
        "italic",
        props.italic,
        "underline",
        props.underline,
        "strikethrough",
        props.strikethrough,
        "code",
        props.code,
        "monospace",
        props.monospace,
        "copyable",
        props.copyable,
        "uppercase",
        props.uppercase,
        props.align,
        props.align,
        `fontSize-${props.fontSize}`,
        props.fontSize
    );

    let value = props.value || props.children;

    if (props.code && props.value) {
        return (
            <code id={props.id} className={className}>
                {props.value?.toString()}
            </code>
        );
    }

    if (props.copyable) {
        const hasCopied = copied === value;
        return (
            <span id={props.id} className={className} onClick={handleClick} style={{ color: props.color }}>
                {value}
                {hasCopied && (
                    <Popup placement={["bottom-start", "bottom-end"]} disablePointerEvents={true}>
                        {context.translate("edit.copied")}
                    </Popup>
                )}
                <Icon id={props.id + ".icon"} color={hasCopied ? CaspecoColors.Green : "inherit"} preset={hasCopied ? "check-circle" : "copy"} />
            </span>
        );
    }

    const tagName = props.as ?? "span";

    return React.createElement(
        tagName,
        {
            id: props.id,
            onClick: handleClick,
            style: { color: props.color },
            className,
        },
        value
    );
};
