import Action from "framework/actions/action";

// ----------- load (normal, success, fail) -----------
export class Load extends Action {
    static actionType: string = "permission_load";
    constructor() {
        super();
    }
}

export class LoadSuccess extends Action {
    static actionType: string = "permission_load_success";
    permissions: any;

    constructor(permissions: any) {
        super(false);
        this.permissions = permissions;
        this.doDispatch();
    }
}

export class LoadFail extends Action {
    static actionType: string = "permission_load_fail";

    constructor() {
        super();
    }
}
