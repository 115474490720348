import { RecordFactory } from "common/modules/recordFactory";
import { SystemMode, systemModeToCommaSeparatedString } from "application/models/management/system";
import { PayrollDataValue, IPayrollDataValue, IPayrollDataValueValuesWithType } from "application/models/payroll/payrollDataValue";
import { Map, Iterable } from "immutable";
import { IDiff } from "common/modules/diffHelper";
import { IPayrollDataChoiceDefinition } from "application/models/payrollDataChoiceDefinition";

export interface IPersistentStationSettingsValues {
    systemMode: SystemMode | null;
    billableValues?: Record<string, IPayrollDataValue> | Map<string, PayrollDataValue>;
    billable?: boolean;
}

const PersistentStationSettingsRecord = RecordFactory<IPersistentStationSettingsValues>({
    systemMode: null,
    billableValues: Map<string, PayrollDataValue>(),
    billable: false,
});

export class PersistentStationSettings extends PersistentStationSettingsRecord implements IPersistentStationSettingsValues {
    readonly systemMode: SystemMode;
    readonly billableValues?: Map<string, PayrollDataValue>;
    readonly billable?: boolean;
    constructor(values: Partial<IPersistentStationSettingsValues>) {
        if (values.billableValues && !Iterable.isIterable(values.billableValues)) {
            values.billableValues = Map<string, IPayrollDataValue>(
                Object.entries(values.billableValues).map(([propName, propValue]: [string, IPayrollDataValueValuesWithType]) => [propName, new PayrollDataValue(propValue)])
            );
        }
        super(values);
    }

    diff(before: PersistentStationSettings, prefix: string = "") {
        let diffs: IDiff[] = [];
        const toSkip: string[] = [];
        this.keySeq().forEach((key) => {
            if (toSkip.includes(key)) {
                return true;
            }
            const propAfter = this.get(key);
            const propBefore = before && before.get(key);
            if (propBefore !== propAfter) {
                if (key === "systemMode") {
                    const propAfterS = propAfter ? systemModeToCommaSeparatedString(<SystemMode>propAfter) : undefined;
                    const propBeforeS = propBefore ? systemModeToCommaSeparatedString(<SystemMode>propBefore) : undefined;
                    diffs.push({ key: prefix + "." + key, before: propBeforeS ?? null, after: propAfterS ?? null });
                } else if (key === "billableValues") {
                    (<PersistentStationSettings["billableValues"]>propAfter)?.forEach((value, feature) => {
                        const b = (<PersistentStationSettings["billableValues"]>propBefore)?.get(feature)?.value();
                        const beforeValue = (<IPayrollDataChoiceDefinition>b)?.description ?? <string | number | boolean | IMoment>b;
                        const afterValue = (<IPayrollDataChoiceDefinition>value.value())?.description ?? <string | number | boolean | IMoment>value.value();
                        if (beforeValue !== afterValue) {
                            diffs.push({ key: `${prefix}.${key}.${feature}`, before: beforeValue ?? null, after: afterValue });
                        }
                    });
                } else {
                    diffs.push({ key: prefix + "." + key, before: <boolean>propBefore ?? null, after: <boolean>propAfter ?? null });
                }
            }
            return true;
        });
        return diffs;
    }
}
