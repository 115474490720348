import * as actions from "../actions/renderModeActions";

import Store from "./store";

import { EStorageType, StorageManager } from "application/storageManager/storageManager";
import { IDeviceDataProps, Orientation, RenderMode } from "common/contexts/renderModeContext";

interface IRenderModeStore {
    getMode(): RenderMode;
    getOrientation(): Orientation;
    getDeviceData(): IDeviceDataProps;
}

const DEFAULT_RENDERMODE: RenderMode = "desktop";

class RenderModeStore extends Store implements IRenderModeStore {
    private readonly _storageManager: StorageManager;

    private _mode: RenderMode = DEFAULT_RENDERMODE;
    private _orientation: Orientation = "landscape";

    constructor() {
        super();
        this._storageManager = StorageManager.GetInstance();

        this.handle(actions.Change.actionType, this._onModeChange);
        this.handle(actions.ChangedScreen.actionType, this._onScreenChange);
        this.handle(actions.ChangedOrientation.actionType, this._onAppOrientationChange);
        this._mode = <RenderMode>this._storageManager.retrieve("renderMode", EStorageType.LocalStorage, true) || this.decideMode();
        this._orientation = this.decideOrientation(window.innerWidth, window.innerHeight);
    }

    private shouldAdapt() {
        return sessionStorage.getItem("renderMode") === null;
    }

    private _onScreenChange = (action: actions.ChangedScreen): void => {
        if (!this.shouldAdapt()) return;
        const newMode: RenderMode = this.decideMode();
        const newOrientation: Orientation = this.decideOrientation(action.width, action.height);
        if (newMode !== this._mode || newOrientation !== this._orientation) {
            this._mode = newMode;
            this._orientation = newOrientation;
            this.emitChange();
        }
    };

    private _onAppOrientationChange = (action: actions.ChangedOrientation): void => {
        this._orientation = action.orientation;
        this.emitChange();
    };

    private _onModeChange = (action: actions.Change): void => {
        this._mode = action.mode;
        this._storageManager.store("renderMode", this._mode, EStorageType.LocalStorage, true);
        this.emitChange();
    };

    private decideOrientation(width: number, height: number): Orientation {
        if (width >= height) {
            return "landscape";
        }
        return "portrait";
    }

    private decideMode(/*width: number, height: number*/): RenderMode {
        if (window.navigatorDetect) {
            return window.navigatorDetect.type();
        }
        return DEFAULT_RENDERMODE;
    }

    getMode(): RenderMode {
        return this._mode;
    }

    getOrientation(): Orientation {
        return this._orientation;
    }

    getDeviceData(): IDeviceDataProps {
        return {
            renderModeDevice: this._mode,
            renderModeOrientation: this._orientation,
            isMobile: this.isMobile(this._mode),
            isTablet: this.isTablet(this._mode),
            isDesktop: this.isDesktop(this._mode),
        };
    }

    isMobile = (device: RenderMode): boolean => device === "mobile";
    isTablet = (device: RenderMode): boolean => device === "tablet";
    isDesktop = (device: RenderMode): boolean => device === "desktop";

    getState(): IDeviceDataProps {
        return this.getDeviceData();
    }
}

const renderModeStore = new RenderModeStore();
export default renderModeStore;
