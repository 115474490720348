import * as React from "react";

import { FormatType } from "common/models/formatType";
import { MarkupOptions } from "common/modules/markup";

export interface ILocaleProps {
    translate(key: string, params?: { [key: string]: unknown }): string;
    numberFormat(value: number, decimals?: number, minDecimals?: number, minDecimalsIfNoDecimals?: boolean): string;
    currency(format: "short" | "long" | "symbol"): string;
    dateFormat(value: IMoment, formatType?: FormatType, timeZoneName?: string, _useMomentTimezone?: boolean, locale?: string): string;
    translatedMarkup(key: string, params?: { [key: string]: unknown }, markupOptions?: MarkupOptions): JSX.Element | JSX.Element[] | null | string;
    getCurrentLocale(): string;
}

export const LocaleContext = React.createContext<ILocaleProps>({
    translate: (key: string) => key,
    translatedMarkup: (key: string) => key,
    numberFormat: (value: number, decimals?: number, _minDecimals?: number, _minDecimalsIfNoDecimals?: boolean) => value.toFixed(decimals),
    currency: (_format: "short" | "long" | "symbol") => "",
    dateFormat: (value: IMoment) => Intl.DateTimeFormat("sv-SE").format(value.toDate()),
    getCurrentLocale: () => "",
});
