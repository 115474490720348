import cx from "classnames";
import * as React from "react";

import { IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertOldIcon, IOldIcon, OldIconTagPreset, oldIconTags } from "ui/components/icon/oldIconPreset";
import { IStackIconProps } from "ui/components/stackIcon/stackIcon";
import { CaspecoColors } from "ui/caspecoColors";
import { CustomIconPreset, CustomIcons } from "./customIcons";
import { useFloating, useHover, useInteractions, FloatingPortal, autoPlacement } from "@floating-ui/react";
import ResponsiveRenderer from "common/modules/responsiveRenderer";

import "./icon.less";
import "../tooltip/tooltip.less";
import { ZoomContext } from "common/contexts/zoomContext";

export type IconScale = "small" | "smaller" | "smallest" | "large" | "larger" | "largest";
export type IconStyle = "solid" | "regular" | "light" | "duotone" | "brand";

type AnyType<T = unknown> = T;

export const IconStyleMap: { [key: string]: IconPrefix } = {
    solid: "fas",
    regular: "far",
    light: "fal",
    brand: "fab",
    duotone: "fad",
};

export interface IDuotoneColors {
    primaryColor: string;
    secondaryColor: string;
    primaryOpacity?: number;
    secondaryOpacity?: number;
}

type TooltipType = string | string[] | (string[] | string)[];

type IconBorderType = "dark" | "light";

export interface IIconProps<T> {
    id: string;
    /** Gets and sets the values of the class attributes of the element. */
    className?: string;
    /** Used to set a class on the icon element (className sets it on a wrapper element). */
    options?: string;
    /** Sets a color */
    color?: string;
    /** Executing an event when user clicks an element. */
    onClick?: (event: React.MouseEvent<HTMLElement>, value: T) => void;
    /** Value to pass to further action. */
    value?: T;
    /** Ensures icons are set to the same width and thus aligned in lists and listgroups if set to true. */
    fixed?: boolean;
    /** Ensures icons are set to the same width and thus aligned in lists and listgroups if set to false. */
    notFixedWidth?: boolean;
    /** On hover, tooltip is visible. */
    tooltip?: string | string[] | (string[] | string)[];
    /** Solid, regular, light, brand or duotone. */
    iconStyle?: IconStyle;
    /** Used to layer two icons on top of each other.
     * The first <StackIcon /> in the array is automatic the first layer icon and will take up the whole size of its parent container if nothing else is specified.
     * The second <StackIcon /> is the second layer and so on.
     * The second <StackIcon /> and furthermore will have a standard size of "xs" and will be absolutely positioned in the bottom corner if nothing else is specified in the transform prop.
     */
    stackIcons?: React.ReactElement<IStackIconProps>[];
    /** Changes appearance through css. */
    style?: React.CSSProperties;
    /** Name of icon. For example "chevron-up". See all available icons in docs or preset type. */
    preset: IconPreset | CustomIconPreset;
    /** Used to set two different colors on one icon. */
    duotoneColors?: IDuotoneColors;
    /** Can choose from "large", "larger", "largest", "small", "smaller" and "smallest". */
    size?: IconScale;
    /** Removes interaction possibilities. If the component has no custom color it will get a default grey color. */
    disabled?: boolean;
    /** None visible element. */
    hidden?: boolean;
    allowPropagation?: boolean;
    forceTooltipLayer?: boolean;

    spin?: boolean;
    inverse?: boolean;
    /** Sets a border. Can choose from black and white. */
    border?: IconBorderType;
    /** Sets a shadow to the icon path */
    shadow?: boolean;
    /** Transform can be used for stacking icons and absolute positioning them inside its parent container.
     *  We use fontawesome own guidlines for doing so and in return no CSS is needed. Check the page for more examples: https://fontawesome.com/how-to-use/on-the-web/styling/power-transforms
     */
    transform?: string;
    children?: React.ReactNode;
    stopPropagationOnMouseDown?: boolean;
}

export const Icon = <T extends AnyType>(props: React.PropsWithChildren<IIconProps<T>>) => {
    const [showTooltip, setShowTooltip] = React.useState(false);
    const { zoomLevel } = React.useContext(ZoomContext);
    const { x, y, refs, context } = useFloating({
        middleware: [
            autoPlacement({
                allowedPlacements: ["bottom-start", "bottom-end", "top-start", "top-end"],
            }),
        ],
        open: showTooltip,
        onOpenChange: setShowTooltip,
    });
    const hover = useHover(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        if (props.onClick && !props.disabled) {
            props.onClick(event, props.value as T);
            if (!props.allowPropagation) event.stopPropagation();
        }
    };

    const toArray = (text: TooltipType) => {
        return Array.isArray(text) ? text : [text];
    };

    const getNewLineTextComponents = (text: TooltipType): JSX.Element[] => {
        return toArray(text).reduce((result, chunk, index) => {
            if (Array.isArray(chunk)) {
                const nestedChunk = chunk.map((part, partIndex) => (
                    <React.Fragment key={`${index}_${partIndex}`}>
                        {part} <br />
                    </React.Fragment>
                ));
                return [...result, <div key={index}>{nestedChunk}</div>];
            } else {
                const textOutput = chunk && (typeof chunk !== "string" || chunk.trim()) ? chunk : "\u00a0";
                return [...result, <div key={index}>{textOutput}</div>];
            }
        }, [] as JSX.Element[]);
    };

    const isAlreadyFormatted = (tooltip: TooltipType | undefined): boolean => {
        return !Array.isArray(tooltip) && typeof tooltip !== "string";
    };

    const getTooltipDropDown = (tooltip: TooltipType | undefined, isMobile: boolean) => {
        if (!tooltip) return <></>;
        if (isAlreadyFormatted(tooltip) && tooltip) {
            return tooltip as unknown as JSX.Element;
        }

        const element = getNewLineTextComponents(tooltip);

        if (isMobile) {
            return (
                <div className="tooltip-container_root" onClick={() => setShowTooltip(false)}>
                    <div className="tooltip-container_content">{element}</div>
                </div>
            );
        }
        const top = y && zoomLevel ? y * zoomLevel : y ?? 0;
        const left = x && zoomLevel ? x * zoomLevel : x ?? 0;
        return (
            <FloatingPortal>
                <div ref={refs.setFloating} style={{ top, left }} {...getFloatingProps()} className="tooltip-container">
                    <div style={{ zoom: zoomLevel ?? 1.0 }}>{element}</div>
                </div>
            </FloatingPortal>
        );
    };

    const getIconClass = (): { [id: string]: boolean } => {
        return {
            [props.options as string]: Boolean(props.options),
            [props.preset as string]: true,
            "icon--shadow": !!props.shadow,
            "icon--disabled": !!props.disabled,
            [`icon--${props.border}Border`]: !!props.border,
            //-TEMP FIX- Spin stopped working when upgraded to font awesome V6. Seems to be a issue with the FA react component. Remove when FA fixed
            "fa-spin": !!props.spin,
        };
    };

    const getLayerIconClass = (preset: IconPreset, className: string) => {
        return {
            [className]: Boolean(className),
            [preset]: true,
        };
    };

    const getIconWrapClasses = (): { [id: string]: boolean } => {
        return {
            icon: true,
            "fa-layers": props.stackIcons ? props.stackIcons.length > 1 : false,
            [`icon--${props.size}`]: Boolean(props.size),
            [props.className as string]: Boolean(props.className),
            "icon--clickable": !!props.onClick,
            "icon--disabled": !!props.disabled,
            "icon--hidden": !!props.hidden,
        };
    };

    const getTooltip = (): JSX.Element | null => {
        if (!!props.tooltip && !showTooltip) return null;
        return (
            <ResponsiveRenderer
                renderDesktop={() => getTooltipDropDown(props.tooltip, false)}
                renderTablet={() => getTooltipDropDown(props.tooltip, true)}
                renderMobile={() => getTooltipDropDown(props.tooltip, true)}
            />
        );
    };

    const getStackDefaultTransform = (index: number) => {
        const firstIcon = index === 0;
        if (!firstIcon) {
            return "shrink-7 down-5 right-6";
        } else {
            return "";
        }
    };

    const getStackDefaultSize = (index: number) => {
        const firstIcon = index === 0;
        if (firstIcon) {
            return "1x";
        } else {
            return "sm";
        }
    };

    const getDuotoneStyle = (incDuotone: IDuotoneColors): any => {
        const isDisabled = props.disabled && CaspecoColors.Red;

        if (incDuotone) {
            return {
                "--fa-primary-color": isDisabled || incDuotone.primaryColor,
                "--fa-secondary-color": isDisabled || incDuotone.secondaryColor,
                "--fa-primary-opacity": incDuotone.primaryOpacity || 1,
                "--fa-secondary-opacity": incDuotone.secondaryOpacity || 1,
            };
        }
        return null;
    };

    const getStackedIcons: any = (stackIcons: React.ReactElement<IStackIconProps>[]) =>
        React.Children.map(stackIcons, (child, index) => {
            if (!child) {
                return null;
            }

            const c = child as React.ReactElement<IStackIconProps>;

            return React.isValidElement(c) ? (
                React.cloneElement(c, {
                    size: c.props.size || getStackDefaultSize(index),
                    className: cx(getLayerIconClass(c.props.preset, c.props.className || "")),
                    style: (c.props.duotoneColors && getDuotoneStyle(c.props.duotoneColors)) || c.props.style,
                    transform: c.props.transform || getStackDefaultTransform(index),
                })
            ) : (
                <></>
            );
        });

    const renderContent = () => {
        const hasStackedIcons = props.stackIcons && props.stackIcons.length > 0;
        let presetName = props.preset as IconPreset;
        let presetStyle = props.iconStyle as IconStyle;
        let newIcon: IOldIcon;
        if (oldIconTags.includes(props.preset)) {
            // Is needed for tags with old preset names.
            newIcon = convertOldIcon(presetName as OldIconTagPreset);
            presetName = newIcon.name;
            presetStyle = newIcon.iconStyle;
        }

        if (props.duotoneColors) {
            presetStyle = "duotone";
        }

        const { color, id, style, spin, inverse, preset } = props;

        const customIcon = CustomIcons.get(preset);

        return (
            <span
                ref={refs.setReference}
                {...getReferenceProps()}
                id={id ? `${id}.icon` : ""}
                data-testid={id ? `testid-${id}` : "icon-test"}
                className={cx(getIconWrapClasses())}
                onClick={handleClick}
                onMouseDown={(event) => (props.stopPropagationOnMouseDown ? event.stopPropagation() : null)}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                style={style || { color }}>
                {customIcon ? (
                    <>{customIcon({ color })}</>
                ) : hasStackedIcons ? (
                    getStackedIcons(props.stackIcons)
                ) : presetName !== "blank" ? (
                    <FontAwesomeIcon
                        className={cx(getIconClass())}
                        fixedWidth={props.fixed || !props.notFixedWidth}
                        color={preset === "info-circle" && !color ? CaspecoColors.AstronautBlue : color}
                        style={props.duotoneColors && getDuotoneStyle(props.duotoneColors)}
                        //-TEMP FIX- Spin stopped working when upgraded to font awesome V6. Seems to be a issue with the FA react component. Uncomment when FA fixed
                        // spin={spin}
                        inverse={inverse}
                        icon={[IconStyleMap[presetStyle], presetName]}
                        transform={props.transform}
                    />
                ) : null}
                {getTooltip()}
                {props.children}
            </span>
        );
    };

    return renderContent();
};

interface IIconState {
    showTooltip: boolean;
}

// eslint-disable-next-line
export class IconComponent<T extends AnyType> extends React.Component<IIconProps<T>, IIconState> {
    constructor(props: IIconProps<T>) {
        super(props);
    }
    static defaultProps = {
        fixed: false,
        disabled: false,
        iconStyle: "solid",
        preset: "blank",
    };

    render() {
        return <Icon {...this.props} />;
    }
}

Icon.defaultProps = {
    id: "",
    fixed: false,
    disabled: false,
    iconStyle: "solid",
    preset: "blank",
};
