import * as React from "react";
import { ISVGCustomProps } from "ui/components/icon/customIcons/customIconsProps";

export const svgKaraoke = function (props: ISVGCustomProps): JSX.Element {
    return (
        <svg className="svg-inline--fa fa-w-11 fa-fw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" color={props.color}>
            <path fill="currentcolor" d="M237.45,215.88,104.71,64.6a59.4,59.4,0,0,1-40.37,40.18L215.88,237.45a10.12,10.12,0,0,0,13.81-.36l7.4-7.4A10.11,10.11,0,0,0,237.45,215.88Z"></path>
            <path fill="currentcolor" d="M89,22.7A105,105,0,0,1,63.33,63.29,104.83,104.83,0,0,1,22.69,88.92,48.09,48.09,0,0,0,96.17,48.08,47.84,47.84,0,0,0,89,22.7Z"></path>
            <path fill="currentcolor" d="M55.44,55.41C68,42.82,76.71,27.73,80.29,12.37A48.09,48.09,0,0,0,0,48.08,47.73,47.73,0,0,0,12.36,80.26C27.73,76.68,42.84,68,55.44,55.41Z"></path>
        </svg>
    );
};
