import * as React from "react";
import "./spinner.less";

interface ISpinnerProps {
    /** By default true, sets blue color for spinner. When false the spinner will be white. */
    invert?: boolean;
    /** Gets and sets the values of the class attributes of the element. */
    className?: string;
}

export const Spinner: React.FC<PropsWithImmutableChildren<ISpinnerProps>> = ({ invert = true, className = "" }) => {
    let classNames = "spinner isLoading" + (className ? " spinner_" + className : "");
    if (invert) {
        classNames += " spinner_dark";
    }
    return (
        <div className={classNames} data-testid="spinner">
            <div className="spinner_rect1"></div>
            <div className="spinner_rect2"></div>
            <div className="spinner_rect3"></div>
            <div className="spinner_rect4"></div>
            <div className="spinner_rect5"></div>
        </div>
    );
};
