export enum CaspecoColors {
    BabyBlue = "hsl(180, 100%, 94%)",
    MalibuSkyBlue = "hsl(198, 100%, 68%)",
    PictonBlissBlue = "hsl(198, 82%, 57%)",
    PacificBlue = "hsl(199, 100%, 41%)",
    CeruleanBlue = "hsl(198, 100%, 35%)",
    KelpBlue = "hsl(200, 100%, 30%)",
    LightestGreen = "hsl(80, 100%, 95%)",
    LighterGreen = "hsl(90, 71%, 64%)",
    LightGreen = "hsl(90, 54%, 53%)",
    Green = "hsl(90, 62%, 42%)",
    DarkGreen = "hsl(88, 99%, 29%)",
    DarkestGreen = "hsl(96, 100%, 24%)",
    LightestYellow = "hsl(60, 100%, 88%)",
    LighterYellow = "hsl(45, 100%, 66%)",
    LightYellow = "hsl(39, 100%, 61%)",
    Yellow = "hsl(37, 90%, 51%)",
    DarkYellow = "hsl(37, 100%, 42%)",
    DarkestYellow = "hsl(32, 100%, 30%)",
    LightestRed = "hsl(9, 100%, 96%)",
    LighterRed = "hsl(10, 100%, 71%)",
    LightRed = "hsl(7, 100%, 66%)",
    Red = "hsl(6, 78%, 57%)",
    DarkRed = "hsl(3, 69%, 46%)",
    DarkestRed = "hsl(355, 100%, 33%)",
    White = "hsl(0, 0%, 100%)",
    WildSandGrey = "hsl(0, 0%, 96%)",
    WhiteSmokeGrey = "hsl(0, 0%, 93.3%)",
    AltoGrey = "hsl(0, 0%, 85%)",
    Silver = "hsl(0, 0%, 74%)",
    SilverChalice = "hsl(0, 0%, 64%)",
    Grey = "hsl(0, 0%, 53%)",
    DoveGrey = "hsl(0, 0%, 43%)",
    ScorpionGrey = "hsl(0, 0%, 34%)",
    MineShaftGrey = "hsl(0, 0%, 25%)",
    GraphiteGrey = "hsl(0, 0%, 16%)",
    CodGrey = "hsl(0, 0%, 8%)",
    Black = "hsl(0, 0%, 0%)",
    LightWarmGrey = "hsl(9, 18%, 96%)",
    WarmGrey = "hsl(9, 19%, 93%)",
    MysticGrey = "hsl(198, 26.3%, 92.5%)",
    ColdGrey = "hsl(200, 22%, 87%)",
    TwilightBlue = "hsl(180, 100%, 97%)",
    OysterBayBlue = "hsl(180, 100%, 92%)",
    FrenchPassBlue = "hsl(180, 100%, 86%)",
    AnakiwaBlue = "hsl(188, 100%, 80%)",
    LightCyanBlue = "hsl(193.7, 100%, 88.8%)",
    PelorousBlue = "hsl(197, 56%, 51%)",
    GlacierBlue = "hsl(195.6, 38.6%, 62.9%)",
    PictonBlue = "hsl(199, 73%, 63%)",
    MalibuBlue = "hsl(198, 100%, 74%)",
    CaspecoBlue = "hsl(194, 100%, 34%)",
    BahamaBlue = "hsl(196, 100%, 29%)",
    OrientBlue = "hsl(199, 100%, 24%)",
    AstronautBlue = "hsl(202, 100%, 19%)",
    DeepOceanBlue = "hsl(208, 100%, 14%)",
    MidnightBlue = "hsl(223, 100%, 10%)",
    BlackRussianBlue = "hsl(238, 100%, 5%)",
}
