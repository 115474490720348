import * as Moment from "moment-timezone";

import { DateRange } from "common/modules/dateRange";
import { getLocaleString, translate as t } from "common/mixins/localeHelper";
import { IDatetimeRule, IDatetimeRules } from "common/modules/datetimeRuleHelper";
import { List } from "immutable";
/**
 * These interfaces will define rules that are not already
 * supposed to be implemented, like a global minDate or similar.
 * Making sure that fromDate < toDate should be handled internally
 * by the date here.
 *
 * They also define configuration- and input objects for creation of
 * the classes.
 *
 */

type IDatetimeInputOutputFunction<Input, Return> = (value: Input) => Return;

type IDatetimeOptionalInputOutputFunction<Input, Return> = (value?: Input) => Return;

type IDatetimeGetterFunction<Return> = () => Return;

// Giving the different possible date types an enum name/value
export enum EDatetimeType {
    Date,
    RangeFrom,
    RangeTo,
}
export enum ETimeTypes {
    MilliSeconds,
    Seconds,
    Minutes,
    Hours,
    Days,
    Weeks,
    Months,
    Quarters,
    Years,
}

/**
 *
 * These two classes will be Immutable. Any setter will create a new
 * object and return that instead, keeping the old one intact.
 *
 */

export interface IDatetimeRangeRules {
    from?: IDatetimeRule;
    to?: IDatetimeRule;
}

interface IDatetimeConfig {
    type: EDatetimeType;
    rules: IDatetimeRule;
    format: string;
    isUTC: boolean;
    allowOngoing: boolean;
}

export type IDatetimeInput = Partial<IDatetimeConfig>;

interface IDatetimeRangeConfig {
    isUTC: boolean;
    rules: IDatetimeRules;
    allowOngoing: boolean;
}

type TDatetimeRangeInput = Partial<IDatetimeRangeConfig>;

export class Datetime {
    private static readonly DefaultConfig: IDatetimeConfig = {
        rules: { min: { day: null, msg: null }, max: { day: null, msg: null }, locked: false },
        isUTC: true,
        type: EDatetimeType.Date,
        format: "YYYY-MM-DD",
        allowOngoing: false,
    };

    protected _isOngoing: boolean = false;
    protected _m: IMoment | null;
    /* Settings */
    protected _config: IDatetimeConfig;

    constructor(input: IDatetimeInput = {}, initialValue: any = null) {
        // if (input) {
        //     if (input.type)
        //         this._config.type = input.type;
        //     if (input.format)
        //         this._config.format = input.format;
        //     if (input.isUTC)
        //         this._config.isUTC = input.isUTC;
        //     if (input.allowOngoing)
        //         this._config.allowOngoing = input.allowOngoing;
        //     if (input.rules) {
        //         if (input.rules.locked)
        //             this._config.rules.locked = input.rules.locked;
        //         if (input.rules.min)
        //             this._config.rules.min = input.rules.min;
        //         if (input.rules.max)
        //             this._config.rules.max = input.rules.max;
        //     }
        // }
        this._config = { ...Datetime.DefaultConfig, ...input };

        if (typeof initialValue !== "boolean") {
            if (this._config.isUTC) this._m = Moment.utc(initialValue);
            else this._m = Moment(initialValue);
        } else if (initialValue) {
            if (this._config.isUTC) this._m = Moment.utc();
            else this._m = Moment();
        }
    }

    public static now(input: IDatetimeInput = {}): Datetime {
        // Use defaults, override with input if it exists.
        const config: IDatetimeInput = { ...Datetime.DefaultConfig, ...input };
        return new Datetime(config, true);
    }

    public unix = (ts: number): Datetime => {
        const newDatetime: Datetime = this.copy();
        newDatetime._m = Moment.unix(ts);
        if (!this._config.isUTC) newDatetime._m.local();
        return newDatetime;
    };

    public updateRules(newRules: IDatetimeRule | undefined) {
        const returnValue = this.copy();
        if (newRules) returnValue._config.rules = newRules;
        return returnValue;
    }

    /**
     * Copy helper
     */
    public copy = () => {
        const newDatetime: Datetime = new Datetime({
            type: this._config.type,
            format: this._config.format,
            isUTC: this._config.isUTC,
            allowOngoing: this._config.allowOngoing,
            rules: { min: this._config.rules.min, max: this._config.rules.max, locked: this._config.rules.locked },
        });
        newDatetime._isOngoing = this._isOngoing;
        newDatetime._m = this._m && this._m.clone();
        return newDatetime;
    };

    public isLocked = (): boolean => {
        return !!this._config.rules.locked;
    };

    /**
     * Math functions
     */

    public add = (value: number, type: ETimeTypes) => {
        return this.handle_math(value, type, true);
    };

    public sub = (value: number, type: ETimeTypes) => {
        return this.handle_math(value, type, false);
    };

    public diff = (other: Datetime, precision: ETimeTypes): number | null => {
        if (!this._m || !other._m) return null;
        return this._m.diff(other._m, <Moment.unitOfTime.DurationConstructor>precision.toString());
    };

    public convertInto = (targetType: EDatetimeType): Datetime => {
        const returnValue: Datetime = this.copy();
        switch (targetType) {
            case EDatetimeType.RangeFrom:
                returnValue._m?.subtract(1, "day");
                break;
            case EDatetimeType.RangeTo:
                returnValue._m?.add(1, "day");
                break;
            case EDatetimeType.Date:
                throw new Error("Unhandled case");
            default:
                return returnValue;
        }
        return returnValue;
    };

    private enumToString = (enumInput: ETimeTypes): Moment.unitOfTime.DurationConstructor => {
        let value: Moment.unitOfTime.DurationConstructor;
        switch (enumInput) {
            case ETimeTypes.MilliSeconds:
                value = "milliseconds";
                break;
            case ETimeTypes.Seconds:
                value = "seconds";
                break;
            case ETimeTypes.Minutes:
                value = "minutes";
                break;
            case ETimeTypes.Hours:
                value = "hours";
                break;
            case ETimeTypes.Days:
                value = "days";
                break;
            case ETimeTypes.Weeks:
                value = "weeks";
                break;
            case ETimeTypes.Months:
                value = "months";
                break;
            case ETimeTypes.Quarters:
                value = "quarters";
                break;
            case ETimeTypes.Years:
                value = "years";
                break;
            default:
                console.error("Throw exception here, please");
                throw new Error("Unknown unit of time");
        }
        return value;
    };

    private handle_math = (value: number, type: ETimeTypes, add: boolean = true) => {
        const newDatetime = this.copy();
        if (this._m === null) return null;
        const typeString = this.enumToString(type);
        let newMoment: IMoment;
        if (add) newMoment = this._m.clone().add(value, typeString);
        else newMoment = this._m.clone().subtract(value, typeString);
        newDatetime._m = newMoment;
        return newDatetime;
    };

    /**
     * Getters and functions that DOES NOT cause a copy
     */
    public partOfRange: IDatetimeGetterFunction<boolean> = () => {
        const thisType = this._config.type;
        return thisType === EDatetimeType.RangeFrom || thisType === EDatetimeType.RangeTo;
    };

    public isUTC: IDatetimeGetterFunction<boolean> = () => {
        return this._config.isUTC;
    };

    public isValid: IDatetimeGetterFunction<boolean> = () => {
        // TODO: Implement validation for this date. (might be unnecessary)
        return (this._m !== null && this._m.isValid()) || this._isOngoing;
    };

    public printable: IDatetimeGetterFunction<string | null> = () => {
        let returnValue;
        if (this._isOngoing) returnValue = getLocaleString("untilFurtherNotice");
        else if (this._config.type === EDatetimeType.RangeTo)
            returnValue =
                this._m &&
                this._m
                    .clone()
                    .subtract(1, "days")
                    .format(this._config.format || this._config.format);
        else returnValue = this._m && this._m.format(this._config.format || this._config.format);
        return returnValue;
    };

    public isExclusive: IDatetimeGetterFunction<boolean> = () => {
        return this._config.type === EDatetimeType.RangeTo;
    };

    /**
     * Setters and functions that DO cause a copy
     */
    public fromString: IDatetimeInputOutputFunction<string, Datetime | null> = (value: string, locked: boolean = false) => {
        let returnValue = null;
        let newMoment: IMoment;
        if (this._config.isUTC) newMoment = Moment.utc(value);
        else newMoment = Moment(value);
        if ((newMoment && newMoment.isValid()) || (newMoment === null && this._config.allowOngoing)) {
            returnValue = new Datetime({ ...this._config, rules: { locked: locked } });
            returnValue._m = newMoment;
            returnValue._isOngoing = false;
        }
        return returnValue;
    };

    public fromMoment = (value: IMoment | undefined, locked?: boolean) => {
        const newDatetime = new Datetime();
        newDatetime._m = (value && value.clone()) ?? null;
        newDatetime._config = this._config;
        newDatetime._isOngoing = false;
        newDatetime._config.rules.locked = !!locked;
        return newDatetime;
    };

    public setAllowOngoing = (value: boolean): Datetime => {
        const returnValue: Datetime = this.copy();
        returnValue._config.allowOngoing = value;
        return returnValue;
    };

    public getAllowOngoing = (): boolean => {
        return this._config.allowOngoing;
    };

    public setFormat(format: string): Datetime {
        const newDatetime: Datetime = this.copy();
        newDatetime._config.format = format;
        return newDatetime;
    }

    public getFormat(): string {
        return this._config.format;
    }

    public setUTC(value: boolean): Datetime {
        const newDatetime: Datetime = this.copy();
        newDatetime._config.isUTC = value;
        if (newDatetime._m) {
            if (value) newDatetime._m.utc();
            else newDatetime._m.local();
        }
        return newDatetime;
    }

    public setOngoing: IDatetimeInputOutputFunction<boolean, Datetime> = (value: boolean) => {
        const newDatetime: Datetime = this.copy();
        if (value && this._config.allowOngoing && this._config.type === EDatetimeType.RangeTo) {
            newDatetime._m = null;
            newDatetime._isOngoing = value;
        }
        return newDatetime;
    };

    public getMoment: IDatetimeOptionalInputOutputFunction<boolean, IMoment | null> = (makeInclusive?: boolean) => {
        if (makeInclusive) return this._m && this._m.clone().subtract(1, "days");
        return this._m && this._m.clone();
    };

    public isOngoing: IDatetimeGetterFunction<boolean> = () => {
        return this._isOngoing;
    };

    public validate(): string[] {
        const errors: string[] = [];
        if (this._m && !this._config.rules.locked) {
            if (this._config.rules.min?.day) {
                if (this._config.rules.min.day.isAfter(this._m)) {
                    errors.push(this._config.rules.min.msg || "DEBUG: Min message is null");
                }
            }
            if (this._config.rules.max?.day) {
                if (this._config.rules.max.day.isBefore(this._m)) {
                    errors.push(this._config.rules.max.msg || "Max message is null");
                }
            }
        }
        return errors;
    }

    public isAfter(comp: Datetime, precision: ETimeTypes = ETimeTypes.Seconds): boolean {
        const precisionString = this.enumToString(precision);
        if (this._m && comp && comp._m) return this._m.isAfter(comp._m, precisionString);
        return true;
    }

    public isBefore(comp: Datetime, precision: ETimeTypes = ETimeTypes.Seconds): boolean {
        const precisionString = this.enumToString(precision);
        if (this._m && comp && comp._m) return this._m.isBefore(comp._m, precisionString);
        return true;
    }

    public isSame(comp: Datetime, precision: ETimeTypes = ETimeTypes.Seconds): boolean {
        const precisionString = this.enumToString(precision);
        if (this._m && comp && comp._m) return this._m.isSame(comp._m, precisionString);
        return false;
    }
}

export class DatetimeRange {
    private static readonly _cDefaultConfig: IDatetimeRangeConfig = {
        isUTC: true,
        rules: {
            toRules: { min: { day: null, msg: null }, max: { day: null, msg: null }, locked: false },
            fromRules: { min: { day: null, msg: null }, max: { day: null, msg: null }, locked: false },
        },
        allowOngoing: false,
    };

    protected _from: Datetime;
    protected _to: Datetime;
    protected _config: IDatetimeRangeConfig;

    constructor(input: TDatetimeRangeInput = {}) {
        this._config = { ...DatetimeRange._cDefaultConfig, ...input };
        // if (input) {
        //     if (input.rules)
        //         this._config.rules = input.rules;
        //     if (input.isUTC)
        //         this._config.isUTC = input.isUTC;
        //     if (input.allowOngoing)
        //         this._config.allowOngoing = input.allowOngoing;
        // }
        this._from = new Datetime({ type: EDatetimeType.RangeFrom, isUTC: input.isUTC, rules: this._config.rules.fromRules });
        this._to = new Datetime({ type: EDatetimeType.RangeTo, isUTC: input.isUTC, allowOngoing: this._config.allowOngoing, rules: this._config.rules.toRules });
    }

    public static FromDateRange(dateRange: DateRange, input: TDatetimeRangeInput = {}): DatetimeRange {
        return new DatetimeRange(input).toFromMoment(dateRange.end?.clone(), true).fromFromMoment(dateRange.start?.clone());
    }

    public getDateRange(): DateRange {
        return new DateRange({ start: this._from.getMoment(), end: this._to.getMoment(true) });
    }

    public getDates(): List<IMoment> {
        let dates = List<IMoment>();

        const fromDate = this.getFromMoment()?.clone().startOf("day");
        const toDate = this.getToMoment()?.clone().subtract(1, "days").startOf("day");
        if (!fromDate) return dates;

        for (const date = fromDate; date.isSameOrBefore(toDate); date.add(1, "days")) {
            dates = dates.push(date.clone());
        }

        return dates;
    }

    public updateRules = (newRules: IDatetimeRules) => {
        const returnValue = this.copy(false);
        returnValue._from = this._from.updateRules(newRules.fromRules);
        returnValue._to = this._to.updateRules(newRules.toRules);
        return returnValue;
    };

    public copy = (dontCopyDatetimes?: boolean) => {
        const copy: DatetimeRange = new DatetimeRange({
            isUTC: this._config.isUTC,
            rules: { toRules: this._config.rules.toRules, fromRules: this._config.rules.fromRules },
            allowOngoing: this._config.allowOngoing,
        });
        if (!dontCopyDatetimes) {
            copy._to = this._to.copy();
            copy._from = this._from.copy();
        }
        return copy;
    };

    /**
     * Public functions
     *
     *
     */

    public isUTC: IDatetimeGetterFunction<boolean> = () => {
        return this._config.isUTC;
    };

    public setAllowOngoing = (value: boolean): DatetimeRange => {
        const returnValue: DatetimeRange = this.copy();
        returnValue._config.allowOngoing = value;
        returnValue._to = returnValue._to.setAllowOngoing(value);
        return returnValue;
    };

    public getAllowOngoing = (): boolean => {
        return this._config.allowOngoing && this._to.getAllowOngoing();
    };

    public setOngoing(value: boolean): DatetimeRange {
        const newDateTimeRange = this.copy();
        newDateTimeRange._to = this._to.setOngoing(value);
        return newDateTimeRange;
    }

    public fromFromString = (value: string, locked?: boolean) => {
        const newFrom: IMoment = this._config.isUTC ? Moment(value).utc() : Moment(value);
        return this.fromMoment(newFrom, EDatetimeType.RangeFrom, locked);
    };

    public fromFromMoment = (value: IMoment | undefined, locked?: boolean) => {
        return this.fromMoment(value, EDatetimeType.RangeFrom, locked);
    };

    // TODO: Currently assumes INCLUSIVE toDate. Pass boolean as second argument to override...
    public toFromString = (value: string, makeExclusive?: boolean, locked?: boolean) => {
        let newTo: IMoment = this._config.isUTC ? Moment(value).utc() : Moment(value);
        if (makeExclusive && newTo) {
            newTo = newTo.clone().add(1, "days");
        }
        return this.fromMoment(newTo, EDatetimeType.RangeTo, locked);
    };

    // TODO: Currently assumes INCLUSIVE toDate. Pass boolean as second argument to override...
    public toFromMoment = (value: IMoment | undefined, makeExclusive?: boolean, locked?: boolean) => {
        if (makeExclusive && value) {
            value = value.clone().add(1, "days");
        }
        return this.fromMoment(value, EDatetimeType.RangeTo, locked);
    };

    public fromFromDatetime: IDatetimeInputOutputFunction<Datetime, DatetimeRange> = (value: Datetime) => {
        return this.fromDatetime(value, EDatetimeType.RangeFrom);
    };

    public toFromDatetime: IDatetimeInputOutputFunction<Datetime, DatetimeRange> = (value: Datetime) => {
        return this.fromDatetime(value, EDatetimeType.RangeTo);
    };

    public isValid: IDatetimeGetterFunction<boolean> = () => {
        return this.validate().length === 0;
    };

    public getFromMoment: IDatetimeGetterFunction<IMoment | null> = () => {
        return this._from.getMoment(false);
    };

    public getNonNullFromMoment: IDatetimeGetterFunction<IMoment> = () => {
        const maybeDate = this.getFromMoment();
        if (maybeDate === null) {
            throw new Error("fromDate was unexpectedly null");
        }
        return maybeDate;
    };

    public getToMoment = (makeInclusive: boolean = false) => {
        return this._to.getMoment(makeInclusive);
    };

    public getNonNullToMoment: IDatetimeGetterFunction<IMoment> = () => {
        const maybeDate = this.getToMoment();
        if (maybeDate === null) {
            throw new Error("toDate was unexpectedly null");
        }
        return maybeDate;
    };

    public print: IDatetimeInputOutputFunction<EDatetimeType, string | null> = (which: EDatetimeType) => {
        let returnValue;
        switch (which) {
            case EDatetimeType.RangeFrom:
                returnValue = this._from.printable();
                break;
            case EDatetimeType.RangeTo:
                returnValue = this._to.printable();
                break;
            default:
                throw new Error("Unhandled DatetimeType provided: " + which);
        }
        return returnValue;
    };

    public isOngoing: IDatetimeGetterFunction<boolean> = () => {
        return this._to.isOngoing();
    };

    public toString = (): string => {
        const fromString = this.getFromMoment()?.format("YYYY-MM-DD");
        const toString = this.getToMoment()?.format("YYYY-MM-DD");
        return fromString + " - " + toString;
    };

    // public fromMoments: DatetimeRangeInputMoments = (fromValue: IMoment, toValue: IMoment) => {
    //     return this.fromMoment(fromValue, DatetimeType.RangeFrom).fromMoment(toValue, DatetimeType.RangeTo);
    // }
    /**
     * Private functions
     *
     *
     */
    private fromMoment(value: IMoment | undefined, type: EDatetimeType, locked?: boolean): DatetimeRange {
        const returnValue: DatetimeRange = this.copy();
        let newDatetime: Datetime;
        switch (type) {
            case EDatetimeType.RangeFrom:
                newDatetime = this._from.fromMoment(value, locked);
                returnValue._from = newDatetime;
                if (
                    returnValue._from.isValid() &&
                    returnValue._to.isValid() &&
                    !returnValue._to.isOngoing() &&
                    (returnValue._to.isBefore(returnValue._from) || returnValue._to.isSame(returnValue._from))
                )
                    returnValue._to = returnValue._from.convertInto(EDatetimeType.RangeTo);
                break;
            case EDatetimeType.RangeTo:
                newDatetime = this._to.fromMoment(value, locked);
                returnValue._to = newDatetime;
                if (returnValue._from.isValid() && returnValue._to.isValid() && (returnValue._to.isBefore(returnValue._from) || returnValue._to.isSame(returnValue._from)))
                    returnValue._from = returnValue._to.convertInto(EDatetimeType.RangeFrom);
                break;
            default:
                console.error("Cast DatetimeRange exception.");
                break;
        }
        // Want to insert null as values.
        return returnValue;
    }

    private fromDatetime(value: Datetime, type: EDatetimeType): DatetimeRange {
        const returnValue: DatetimeRange = this.copy();
        const newDatetime: Datetime = value.copy();
        switch (type) {
            case EDatetimeType.RangeFrom:
                returnValue._from = newDatetime;
                break;
            case EDatetimeType.RangeTo:
                returnValue._to = newDatetime;
                break;
            default:
                console.error("Cast DatetimeRange exception.");
                break;
        }
        // Want to insert null as values.
        return returnValue;
    }

    public validate(): string[] {
        let errors: string[] = [];
        if (!this._from.isLocked()) errors = errors.concat(this._from.validate());
        if (!this._to.isLocked()) errors = errors.concat(this._to.validate());
        if (!(this._from.isBefore(this._to) || this._from.isLocked())) {
            errors.push(t("datetime.fromIsAfterTo"));
        }
        return errors;
    }

    public overlaps(datetimeRange: DatetimeRange): boolean {
        const inputFrom = datetimeRange.getFromMoment();
        const inputTo = datetimeRange.isOngoing() ? undefined : datetimeRange.getToMoment();
        const thisFrom = this.getFromMoment();
        const thisTo = this.isOngoing() ? undefined : this.getToMoment();
        const returnValue =
            (!inputTo ? thisFrom?.isAfter(inputFrom) : thisFrom?.isBetween(inputFrom, inputTo)) || !!(!thisTo ? inputFrom?.isAfter(thisFrom) : !!inputFrom?.isBetween(thisFrom, thisTo));
        return returnValue;
    }
}
