/* eslint-disable react-hooks/exhaustive-deps */
import { IStore } from "framework/stores/istore";
import { useEffect, useState, useCallback } from "react";

/**
 * Use this to listen to a store implementing getState() in Functional Components
 *
 * @param {S} store The store to listen to
 * @param initLoadStore Optional function to run your first load store function.
 */
export function useGetStore<S extends IStore, U extends ReturnType<S["getState"]>>(store: S, initLoadStore?: Function): NonNullable<ReturnType<S["getState"]>> {
    const getState = useCallback((s: S): U => s.getState(), []);
    useEffect(() => {
        if (initLoadStore) initLoadStore();
    }, []);
    return useStore(store, getState);
}

/**
 * Use this to listen to a store in Functional Components
 *
 * @param {S} store The store to listen to
 * @param {(store: S, data?: D) => T} storeOnChange Callback that returns the state from the store
 */
export function useStore<T, D, S extends IStore>(store: S, storeOnChange: (store: S, data?: D) => T): NonNullable<T> {
    const [state, setState] = useState<T>(store.getState());

    useEffect(() => {
        const storeOnChangeInternal = (data?: D) => {
            const updatedState = storeOnChange(store, data);
            setState(updatedState);
        };
        store.onChange(storeOnChangeInternal);
        storeOnChangeInternal();
        return () => {
            store.offChange(storeOnChangeInternal);
        };
    }, [store, storeOnChange]);
    return <NonNullable<T>>state;
}

/**
 * Use this to listen to a specific method in a store
 *
 * @param {S} store The store to listen to
 * @param {() => T} foo Function that does some sideeffect that mutates the store
 */
export function useStoreGetMethod<T, S extends IStore>(store: S, foo: () => T, dependencies?: any[]): T {
    const [state, setState] = useState<T>(foo());

    useEffect(() => {
        const storeOnChangeInternal = () => {
            const updatedState = foo();
            setState(updatedState);
        };
        store.onChange(storeOnChangeInternal);
        storeOnChangeInternal();
        return () => {
            store.offChange(storeOnChangeInternal);
        };
    }, dependencies ?? []);
    return state;
}

/**
 * Use this to listen to all changes to a store and run the foo function when it does
 *
 * @param {S} store The store to listen to
 * @param {() => T} foo Function that gets called when the store updates
 */
export function useOnStoreChange<T, S extends IStore>(store: S, foo: () => T): void {
    useEffect(() => {
        store.onChange(foo);
        return () => {
            store.offChange(foo);
        };
    }, [foo]);
}
