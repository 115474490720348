import { numberFormat } from "common/mixins/localeHelper";
import { List, OrderedMap } from "immutable";
import { translate } from "common/mixins/localeHelper";

export function isBetween(x: number, min: number, max: number): boolean {
    return x >= min && x <= max;
}
export function roundToTwoDecimals(value: number): number {
    return roundToPrecision(value, 2);
}
export const clamp = (v: number, min: number, max: number) => Math.min(Math.max(v, min), max);

export function roundToPrecision(value: number, precision: number): number {
    if (!value.toString().includes("e")) {
        return +(Math.round(<any>(value + "e+" + precision)) + "e-" + precision);
    }
    let parts = value.toString().split("e");
    let sig = +parts[1] + precision > 0 ? "+" : "";
    return +(Math.round(<any>(+parts[0] + "e" + sig + (+parts[1] + precision))) + "e-" + precision);
}

export function numberToString(value: number, isInteger: boolean): string {
    if (typeof value !== "number") {
        value = parseFloat(<any>value);
    }

    if (value !== 0 && !isNaN(value)) {
        if (isInteger) {
            return Math.round(value).toString();
        }
        return numberFormat(value);
    } else {
        return "";
    }
}

export function numberToStringRounded(value: number, isInteger: boolean, precision: number): string {
    if (typeof value !== "number") {
        value = parseFloat(<any>value);
    }

    if (!isNaN(value)) {
        if (isInteger) {
            return Math.round(value).toString();
        }
        return numberFormat(roundToPrecision(value, precision), 2, 0);
    } else {
        return "";
    }
}

export function zeroPad(value: number): string {
    return value < 10 ? "0" + value : "" + value;
}

const romanNumbersLookup = OrderedMap<string, number>({ M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 });
export function romanize(decimalNumber: number): string {
    if (decimalNumber < 1) {
        throw new Error("Zero or negative decimal numbers can not be represented by roman numbers.");
    } else if (decimalNumber > 4999) {
        throw new Error("Decimal numbers above 4999 can not be represented by roman numbers.");
    }
    let roman = "";
    romanNumbersLookup.forEach((value, key) => {
        while (decimalNumber >= romanNumbersLookup.get(key)) {
            roman += key;
            decimalNumber -= value;
        }
    });
    return roman;
}

export class ordinalNumber {
    public index: number;
    public name: string;
}

export function getOrdinalNumbers(): List<ordinalNumber> {
    return List([
        {
            index: 1,
            name: translate("common.ordinalnumber.first"),
        },
        {
            index: 2,
            name: translate("common.ordinalnumber.second"),
        },
        {
            index: 3,
            name: translate("common.ordinalnumber.third"),
        },
        {
            index: 4,
            name: translate("common.ordinalnumber.fourth"),
        },
        {
            index: 5,
            name: translate("common.ordinalnumber.fifth"),
        },
        {
            index: 6,
            name: translate("common.ordinalnumber.sixth"),
        },
        {
            index: 7,
            name: translate("common.ordinalnumber.seventh"),
        },
        {
            index: 8,
            name: translate("common.ordinalnumber.eighth"),
        },
        {
            index: 9,
            name: translate("common.ordinalnumber.ninth"),
        },
        {
            index: 10,
            name: translate("common.ordinalnumber.tenth"),
        },
    ]);
}
