import classnames from "classnames";
import * as React from "react";
import { SpaceSize } from "ui/components/spacer/spacer";

import "./flex.less";

type WrapType = "nowrap" | "wrap" | "wrap-reverse";

export interface IFlexProps {
    /** Centers the content in the middle (same as align="center" + justify="center") */
    center?: boolean;
    /** Default is row, pass 'column' to stack elements or 'reverse' to change order in other direction. */
    direction?: "column" | "row" | "row-reverse" | "column-reverse";
    /** Align items according to the flex-direction axis */
    align?: "stretch" | "flex-start" | "center" | "flex-end" | "baseline";
    /** justify items according to the flex-direction axis */
    justify?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
    /** Tell items to wrap, default is no wrap */
    wrap?: WrapType;
    equalSizeChildren?: boolean;
    fillParentHeight?: boolean;
    fillParentWidth?: boolean;
    flex?: string;
    removeWidth?: boolean;
    /** Set a gap between rows and columns */
    gap?: SpaceSize;
}
export const Flex = ({
    center,
    direction,
    wrap,
    align,
    justify,
    children,
    equalSizeChildren = false,
    fillParentHeight,
    fillParentWidth,
    flex,
    removeWidth = false,
    gap,
}: PropsWithImmutableChildren<IFlexProps>) => {
    const classes = classnames({
        flex: true,
        "flex--center": center,
        "flex--column": direction === "column",
        "flex--row-reverse": direction === "row-reverse",
        "flex--column-reverse": direction === "column-reverse",
        [`flex--${wrap}`]: wrap,
        [`flex--align-${align}`]: align,
        [`flex--justify-${justify}`]: justify,
        "flex--equal-size": equalSizeChildren,
        "flex--remove-width": removeWidth,
        "flex--fill-height": fillParentHeight,
        "flex--fill-width": fillParentWidth,
        [`flex--gap-${gap}`]: gap,
    });
    return (
        <div className={classes} style={{ flex }}>
            {children}
        </div>
    );
};

export default Flex;
