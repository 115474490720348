import * as CompanyInformation from "application/models/management/companyInformation";
import * as SystemTag from "application/models/management/systemTag";
import * as Immutable from "immutable";

import { ISystemMetaInformation } from "framework/models/systemMetaInformation";

export type DeploymentSuffixType = "heavy" | "Inget" | null;

export enum CreateSystemType {
    Create = 1,
    Migrate = 2,
}

export enum SystemMode {
    Undefined = 0,
    Time = 1 << 0,
    Salary = 1 << 1,
    Booking = 1 << 2,
    //Benchmark = 1 << 3, NOTE: Obsolete
    //Caddie = 1 << 4, NOTE: Obsolete
    Checkout = 1 << 5,
    //TopLists = 1 << 6, NOTE: Obsolete
    //SalesBenchmark = 1 << 7, NOTE: Obsolete
    Tip = 1 << 9,
    Schedule = 1 << 11,
    Locked = 1 << 12,
    KB = 1 << 13,
    Corporate = 1 << 14,
    Air = 1 << 15,
    ReadOnly = 1 << 16,
}

/** 32-bit integer that should cover all system modes. */
export const SystemModeAll = <SystemMode>0xffffffff;

/**
 * Returns true if system is a Clone (as determined by the system name).
 * @param systemName Incl country prefix!
 */
export function systemIsClone(systemName: string): boolean {
    return systemName.length > 4 && systemName[3] === "_";
}

export function hasSystemFlag(systemMode: SystemMode, testFlag: SystemMode): boolean {
    return (systemMode & testFlag) === testFlag;
}

export function hasTimeFlag(systemMode: SystemMode): boolean {
    return hasSystemFlag(systemMode, SystemMode.Time);
}

export function hasSalaryFlag(systemMode: SystemMode): boolean {
    return hasSystemFlag(systemMode, SystemMode.Salary);
}

export function hasScheduleFlag(systemMode: SystemMode): boolean {
    return hasSystemFlag(systemMode, SystemMode.Schedule);
}

export function hasBookingFlag(systemMode: SystemMode): boolean {
    return hasSystemFlag(systemMode, SystemMode.Booking);
}

export function hasCheckoutFlag(systemMode: SystemMode): boolean {
    return hasSystemFlag(systemMode, SystemMode.Checkout);
}

export function hasTipFlag(systemMode: SystemMode): boolean {
    return hasSystemFlag(systemMode, SystemMode.Tip);
}

export function hasCorporateFlag(systemMode: SystemMode): boolean {
    return hasSystemFlag(systemMode, SystemMode.Corporate);
}

export function hasAirFlag(systemMode: SystemMode): boolean {
    return hasSystemFlag(systemMode, SystemMode.Air);
}

export function setFlag(systemMode: SystemMode, flag: SystemMode): SystemMode {
    return systemMode | flag;
}

export function clearFlag(systemMode: SystemMode, flag: SystemMode): SystemMode {
    return systemMode & ~flag;
}

export function systemFlagsIntersects(flag1: SystemMode, flag2: SystemMode): boolean {
    return (flag1 & flag2) !== 0;
}

export function isTimeScheduleSalarySystem(selectedSystem: ISystemMetaInformation): boolean {
    if (!selectedSystem || !selectedSystem.mode) {
        return false;
    }
    const systemMode = systemModeFromCommaSeparatedString(selectedSystem.mode);
    return hasSystemFlag(systemMode, SystemMode.Salary) || hasSystemFlag(systemMode, SystemMode.Time) || hasSystemFlag(systemMode, SystemMode.Schedule);
}

export function isLocked(systemMode: SystemMode): boolean {
    return hasSystemFlag(systemMode, SystemMode.Locked);
}

export function isReadOnly(systemMode: SystemMode): boolean {
    return hasSystemFlag(systemMode, SystemMode.ReadOnly);
}

/// In SystemMetaInformation system flags are represented as comma separated strings, and in System it is a bit flag.
/// Convert comma separated string to system mode enum.
export function systemModeFromCommaSeparatedString(systemModeString: string): SystemMode {
    const items = Immutable.Set<string>(systemModeString ? systemModeString.split(",").map((s) => s.trim()) : []);
    let result = 0;
    for (let ii = 0; ii < 32; ii++) {
        const testFlag = 1 << ii;
        if (items.contains(SystemMode[testFlag])) {
            result |= testFlag;
        }
    }

    return result;
}

export function getAllSystemModes(): Immutable.Set<string> {
    return Immutable.Set<string>(Object.keys(SystemMode));
}

export function systemModeToSet(systemMode: SystemMode): Immutable.Set<string> {
    let items = Immutable.Set<string>();
    for (let ii = 0; ii < 32; ii++) {
        const testFlag = 1 << ii;
        if (hasSystemFlag(systemMode, testFlag)) {
            items = items.add(SystemMode[testFlag]);
        }
    }
    return items;
}

/// In SystemMetaInformation system flags are represented as comma separated strings, and in System it is a bit flag.
/// Convert system mode enum to comma separated string.
export function systemModeToCommaSeparatedString(systemMode: SystemMode): string {
    return systemModeToSet(systemMode).join(",");
}

export interface ISystemParameters {}

export interface ISystemCreateParameters {
    name: string;
    commonName: string;
    deploymentMarc: string | null;
    deploymentWebPlatform: string | null;
    systemMode: SystemMode;
    companyInformation: Partial<CompanyInformation.ICompanyInformationValues>;
    firstClockInDate: string | undefined;
    systemTags: Immutable.List<SystemTag.ISystemTagRecord>;
    isProtected: boolean;
    expiryDate: IMoment | null | undefined;
}

export interface ISystemUpdateParameters {
    name: string;
    commonName: string;
    deploymentMarc: string | null;
    deploymentWebPlatform: string | null;
    systemMode: SystemMode;
    systemTags: Immutable.List<SystemTag.ISystemTagRecord>;
    isProtected: boolean;
    expiryDate: IMoment | null | undefined;
    deploymentMarcSuffix: string | null;
    startDate: string | null | undefined;
    firstClockInDate: string | undefined;
}

export interface ISystemDeleteParameters {
    name: string;
}

export enum MigrationSource {
    BC = 1,
    BCBlob = 2,
}

export interface ISystemMigrateParameters extends Except<ISystemCreateParameters, "commonName"> {
    sourceSystemName: string;
    migrationSource: MigrationSource;
    startDate: IMoment | null | undefined;
    excludeWorkingShifts: boolean;
    includeMigrationPayrollArticles: boolean;
    includeAdditionDeductions: boolean;
    includeBookkeepingSettings: boolean;
    updateExistingEmployees: boolean;
    includeStations: boolean;
    addDataToExistingSystem: boolean;
    addDataToPreviouslyMigratedSystem: boolean;
    commonName: ISystemCreateParameters["commonName"] | null;
}

export interface ISystemCloneParameters extends ISystemCreateParameters {
    systemToClone: string | null;
    restorePointInTime: IMoment;
}

export interface ISystemCreateParametersRecord extends ISystemCreateParameters, Immutable.Map<string, any> {}

export interface ISystemUpdateParametersRecord extends ISystemUpdateParameters, Immutable.Map<string, any> {}

export interface ISystemDeleteParametersRecord extends ISystemDeleteParameters, Immutable.Map<string, any> {}

export interface ISystemMigrateParametersRecord extends ISystemMigrateParameters, Immutable.Map<string, any> {}

export interface ISystemCloneParametersRecord extends ISystemCloneParameters, Immutable.Map<string, any> {}

const SystemCreateParameters = Immutable.Record({
    name: "",
    commonName: "",
    deploymentMarc: null,
    deploymentWebPlatform: null,
    systemMode: 0,
    companyInformation: null,
    systemTags: null,
    firstClockInDate: null,
    isProtected: false,
    expiryDate: null,
});

const SystemUpdateParameters = Immutable.Record({
    name: "",
    commonName: "",
    deploymentMarc: null,
    deploymentWebPlatform: null,
    systemMode: 0,
    systemTags: null,
    isProtected: false,
    expiryDate: null,
    deploymentMarcSuffix: null,
    startDate: undefined,
    firstClockInDate: undefined,
});

const SystemDeleteParameters = Immutable.Record({
    name: "",
});

const SystemMigrateParameters = Immutable.Record({
    name: "",
    commonName: "",
    deploymentMarc: null,
    deploymentWebPlatform: null,
    systemMode: 0,
    companyInformation: null,
    sourceSystemName: "",
    migrationSource: MigrationSource.BC,
    systemTags: null,
    startDate: null,
    firstClockInDate: null,
    updateExistingEmployees: false,
    includeStations: true,
    excludeWorkingShifts: false,
    includeMigrationPayrollArticles: false,
    includeAdditionDeductions: false,
    includeBookkeepingSettings: false,
    addDataToExistingSystem: false,
    addDataToPreviouslyMigratedSystem: false,
});

const SystemCloneParameters = Immutable.Record({
    name: "",
    restorePointInTime: null,
    commonName: "",
    deploymentMarc: null,
    deploymentWebPlatform: null,
    systemMode: 0,
    companyInformation: null,
    systemTags: null,
    systemToClone: "",
    expiryDate: null,
    firstClockInDate: undefined,
    isProtected: false,
});

export function SystemCreateParametersRecord(values: ISystemCreateParameters): ISystemCreateParametersRecord {
    const record: ISystemCreateParametersRecord = <any>new SystemCreateParameters(<any>values);
    return record;
}

export function SystemUpdateParametersRecord(values: ISystemUpdateParameters): ISystemUpdateParametersRecord {
    const record: ISystemUpdateParametersRecord = <any>new SystemUpdateParameters(<any>values);
    return record;
}

export function SystemDeleteParametersRecord(values: ISystemDeleteParameters): ISystemDeleteParametersRecord {
    const record: ISystemDeleteParametersRecord = <any>new SystemDeleteParameters(<any>values);
    return record;
}

export function SystemMigrateParametersRecord(values: ISystemMigrateParameters): ISystemMigrateParametersRecord {
    values.migrationSource = values.migrationSource || MigrationSource.BC;
    const record: ISystemMigrateParametersRecord = <any>new SystemMigrateParameters(<any>values);
    return record;
}

export function SystemCloneParametersRecord(values: ISystemCloneParameters): ISystemCloneParametersRecord {
    const record: ISystemCloneParametersRecord = <any>new SystemCloneParameters(<any>values);
    return record;
}

export function ValidateSystemParameters(values: Partial<ISystemCreateParameters>) {
    const record: ISystemCreateParametersRecord = <any>new SystemCreateParameters(<any>values);
    return record;
}

// ----------- System -----------
export interface ISystemValues {
    name: string;
    commonName: string;
    state: number;
    isDeleted: boolean;
    isClone?: boolean;
    isProtected?: boolean;
    expiryDate?: IMoment;
    companyInformation?: CompanyInformation.ICompanyInformationValues;
    deploymentMarcSuffix?: string | null;
    deploymentWebPlatform?: string;
    deploymentMarc?: string;
}

export interface ISystemRecord extends ISystemValues, Immutable.Map<string, any> {}

export enum SystemState {
    Ready = 0,
    Copying = 1,
}

const System = Immutable.Record({
    name: "",
    commonName: "",
    state: SystemState.Ready,
    isClone: false,
    isDeleted: false,
    isProtected: false,
    expiryDate: null,
    companyInformation: null,
    deploymentMarcSuffix: null,
});

export function SystemRecord(values: ISystemValues): ISystemRecord {
    return <ISystemRecord>(<any>new System(<any>values));
}

// ----------- System Map ------------
export interface ISystemRecordMap extends Immutable.Map<string, ISystemRecord> {}
