import * as React from "react";
import Insights from "framework/modules/insights";

import { translate as t } from "common/mixins/localeHelper";
import { Button } from "ui/components/button/button";
import { Card } from "ui/components/card/card";
import { Header } from "ui/components/header/header";
import { Text } from "ui/components/text/text";
import { LocaleStore } from "framework/stores/localeStore";
import { useGetStore } from "framework/hooks/useStore";

import "./refreshPrompt.less";

declare const CDN: string;

export const RefreshPrompt: React.FC<PropsWithImmutableChildren<{}>> = () => {
    //updates to refresh translations
    const state = useGetStore(LocaleStore);

    const reload = () => {
        location.reload();
    };

    const getRandomImageUrl = (): string => {
        const random = Math.floor(Math.random() * 2);

        if (random > 0) {
            return `${CDN}/webplatform/assets/img/person_screen.svg`;
        } else {
            return `${CDN}/webplatform/assets/img/person_screen_woman.svg`;
        }
    };

    //logs once whenever this prompt is shown
    React.useEffect(() => {
        Insights.trackEvent("refreshPrompt.render");
    }, []);

    return (
        <Card id="errorCard" centerContent m="medium">
            <img id="reloadImage" src={getRandomImageUrl()} alt="Caspeco employee demonstrating how the site works" />
            <Header level={1}>{t("application.errors.couldNotLoad")}</Header>
            <Header level={2}>{t("application.errors.couldNotLoadPrompt")}</Header>
            <Button type="primary" id="reloadButton" onClick={reload} icon="sync-alt" text={t("application.errors.reload") as string} />
            <Text id="reloadText">{t("application.errors.couldNotLoadPointers")}</Text>
        </Card>
    );
};
