import * as React from "react";
import { ISVGCustomProps } from "ui/components/icon/customIcons/customIconsProps";

export const svgGokart = function (props: ISVGCustomProps): JSX.Element {
    return (
        <svg className="svg-inline--fa fa-w-11 fa-fw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" color={props.color}>
            <rect fill="currentcolor" x="198" width="42" height="70" rx="10"></rect>
            <rect fill="currentcolor" width="42" height="70" rx="10"></rect>
            <rect fill="currentcolor" x="10" y="25" width="220" height="20"></rect>
            <rect fill="currentcolor" y="170" width="42" height="70" rx="10"></rect>
            <rect fill="currentcolor" x="198" y="170" width="42" height="70" rx="10"></rect>
            <rect fill="currentcolor" x="10" y="195" width="220" height="20"></rect>
            <path
                fill="currentcolor"
                d="M160,0H80A20,20,0,0,0,60,20V220a20,20,0,0,0,20,20h80a20,20,0,0,0,20-20V20A20,20,0,0,0,160,0ZM150,140a20,20,0,0,1-20,20H110a20,20,0,0,1-20-20V100a20,20,0,0,1,20-20h20a20,20,0,0,1,20,20Z"></path>
            <circle fill="currentcolor" cx="120" cy="110" r="20"></circle>
        </svg>
    );
};
