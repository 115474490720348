import * as Moment from "moment-timezone";

import IStore from "framework/stores/istore";
import Store from "./store";

import { ILocale, LocaleIdString, TranslationEntry } from "application/models/management/translation";
import { LoadingLocaleMessages, Change as LocaleChangeAction, LocaleMessagesFetched, AddLocaleOverrides } from "../actions/localeActions";
import { LoadingStatus } from "framework/models/loadingStatusEnum";
import { Map, OrderedSet } from "immutable";

type MessageBlob = { [key: string]: string | string[] | MessageBlob };

//#newcountry Update needed here when adding a new country
export const languageCodes: { [code: string]: string } = {
    "en-GB": "en",
    "en-SE": "en",
    "en-US": "en",
    "da-DK": "da",
    "nb-NO": "nb",
    "no-NO": "nb",
    "nn-NO": "nb",
    "sv-SE": "sv",
    "et-EE": "et",
    "nl-BE": "nlBe",
    "fr-BE": "frBe",
    "ru-RU": "ru",
    "fi-FI": "fi",
    "es-ES": "es",
    "nl-NL": "nlBe", //Change this if we want to add a nlMessages.json instead of nlBE
    "lt-LT": "lt",
    "lv-LV": "lv",
};

const languagesArray = ["da", "sv", "en", "nb", "no", "nn", "et", "es", "lt", "lv"];
export const languagesToDisplay = ["da", "sv", "en", "nb"];

//#newcountry Update needed here when adding a new country
export const languageToPossibleCountry: { [language: string]: LocaleIdString } = {
    en: "en-GB",
    sv: "sv-SE",
    da: "da-DK",
    no: "nb-NO",
    nb: "nb-NO",
    nn: "nb-NO",
    et: "et-EE",
    ru: "ru-RU",
    fi: "fi-FI",
    nl: "nl-NL",
    es: "es-ES",
    lt: "lt-LT",
    lv: "lv-LV",
};

const supportedLanguages: OrderedSet<string> = OrderedSet<string>(languagesArray);

export function flattenMessages(msgs: MessageBlob, parentId?: string): TranslationEntry {
    const result: TranslationEntry = {};
    let atHand;

    Object.keys(msgs).forEach((id) => {
        atHand = msgs[id];
        let tempId = id;
        if (parentId) tempId = parentId + "." + tempId;

        if (typeof atHand === "string" || Array.isArray(atHand)) {
            result[tempId] = { id: tempId, defaultMessage: <string>msgs[id] };
        } else {
            const innerResult = flattenMessages(atHand, tempId);
            Object.keys(innerResult).forEach((innerId) => {
                result[innerId] = innerResult[innerId];
            });
        }
    });

    msgs = {};

    return result;
}

interface ILocaleStore extends IStore {
    isLanguageSupported(language: LocaleIdString): boolean;
    getLocaleString(): string;
    getMessages(): TranslationEntry;
    getLanguageCode(locale?: LocaleIdString): string | null;
    //getMessagesFromLocale(locale: string): TranslationEntry;
    getCountryFromLanguage(language: string): LocaleIdString;
    hasMessagesForLocale(locale: ILocale): boolean;
    isLoading(): boolean;
}

export interface ILocaleState {
    locale: ILocale;
    messages: Map<LocaleIdString, TranslationEntry>;
    loading: boolean;
    status: LoadingStatus;
}

class LocaleStoreClass extends Store<ILocaleState> implements ILocaleStore {
    private static instance: ILocaleStore | null = null;
    // private _locale: string = "sv-SE";
    private _locale: ILocale = { id: "sv-SE", name: "sv-SE", sortOrder: 1 };
    private _messages: Map<LocaleIdString, TranslationEntry>;
    private _messageOverrides: Map<LocaleIdString, TranslationEntry>;
    private _loading: boolean;

    private constructor() {
        super();
        this._messages = Map<LocaleIdString, TranslationEntry>();
        this._messageOverrides = Map<LocaleIdString, TranslationEntry>();
        this._loading = false;
        this.handle(LocaleChangeAction.actionType, this.handleLocaleChange);
        this.handle(LocaleMessagesFetched.actionType, this.handleMessagesFetched);
        this.handle(LoadingLocaleMessages.actionType, this.handleLoadingLocaleMessages);
        this.handle(AddLocaleOverrides.actionType, this.handleSetOverrides);
    }

    public static GetInstance() {
        if (LocaleStoreClass.instance === null) LocaleStoreClass.instance = new LocaleStoreClass();
        return LocaleStoreClass.instance;
    }

    private handleLocaleChange = (action: LocaleChangeAction) => {
        this._locale = action.locale;

        if (typeof action.messages !== undefined && action.messages != null) {
            this._messages = this._messages.set(action.locale.id, flattenMessages(action.messages, action.part));
        }

        Moment.locale(action.locale.id);
        this.emitChange();
    };

    private handleSetOverrides = (action: AddLocaleOverrides) => {
        this._messageOverrides = this._messageOverrides.set(this._locale.id, action.messages);
        this.emitChange();
    };

    private handleLoadingLocaleMessages = (_action: LoadingLocaleMessages) => {
        this._loading = true;
        this.emitChange();
    };

    private handleMessagesFetched = (action: LocaleMessagesFetched) => {
        this._loading = false;
        this._messages = this._messages.set(action.locale.id, flattenMessages(action.messages, action.part));
        this.emitChange();
    };

    getCountryFromLanguage(language: string): LocaleIdString {
        return languageToPossibleCountry[language];
    }

    isLanguageSupported(language: LocaleIdString): boolean {
        if (language === undefined || language === null) {
            return false;
        }
        return supportedLanguages.has(language.substring(0, 2));
    }

    // getMessagesFromLocale(locale: LocaleIdString) {
    //     let langCode = this.getLanguageCode(locale);
    //     if (langCode == null) langCode = "en";
    //     return this.getFlatMessages(langCode);
    // }

    getLocaleString(): LocaleIdString {
        return this._locale.id;
    }

    getLanguageCode(locale: LocaleIdString = this._locale.id): string | null {
        if (languageCodes[locale]) return languageCodes[locale];
        return null;
    }

    getMessages(locale: ILocale = this._locale): TranslationEntry {
        if (this._messageOverrides.get(locale.id)) {
            return this._messageOverrides.get(locale.id);
        }
        return this._messages.get(locale.id);
    }

    hasMessagesForLocale(locale: ILocale): boolean {
        return this._messages.has(locale.id);
    }

    isLoading(): boolean {
        return this._loading;
    }

    getState(): ILocaleState {
        return {
            locale: this._locale,
            loading: this._loading,
            messages: this._messages,
            status: this.getStatus(),
        };
    }
}

export const LocaleStore = LocaleStoreClass.GetInstance();
