import JwtDecode from "jwt-decode";

import { Datetime, ETimeTypes } from "common/modules/datetime";

import { IAccessToken, IIdentityType, TScope } from "../stores/userStoreInterfaces";

type DecodedToken = {
    sub: string;
    name: string;
    exp: string;
    auth_time: Datetime;
    identity_type: string[];
    amr: string[];
    scopes: string[];
};

export class AccessToken implements IAccessToken {
    private static readonly constDatetimeFormat: string = "HH:mm:ss, YYYY-MM-DD";

    private encoded: string;

    public subject: string;
    public name: string;
    public lifetime: number;
    public authTime: Datetime;
    public expiresAt: Datetime;
    public identityType: IIdentityType;
    public amr: string[];
    public scopes: TScope[];

    constructor(jwt: string, expiresIn: number) {
        try {
            this.parseJwt(jwt, expiresIn);
            this.encoded = jwt;
        } catch (error) {
            console.error("Error:", error.message);
            throw new Error(error.message);
        }
    }

    /**
     *
     * Public functions
     *
     */
    public getEncoded(): string {
        return this.encoded;
    }

    public isValid(): boolean {
        const now: Datetime = Datetime.now();
        const isBefore: boolean = !!this.expiresAt && now.isBefore(this.expiresAt, ETimeTypes.Seconds);
        return isBefore;
    }

    /**
     *
     * Private functions
     *
     */
    private parseIdentityTypeString(input: string): IIdentityType {
        let value: IIdentityType;
        switch (input) {
            case "U":
                value = IIdentityType.User;
                break;
            default:
                throw new IdentityTypeError('Invalid identity_type input: "' + input + '"');
        }
        return value;
    }

    private parseJwt(accessTokenString: string, expiresIn: number) {
        const accessTokenDecoded = JwtDecode<DecodedToken>(accessTokenString);
        this.subject = accessTokenDecoded.sub;
        this.name = accessTokenDecoded.name;
        this.lifetime = expiresIn;
        this.expiresAt = new Datetime({ format: AccessToken.constDatetimeFormat }).unix(parseInt(accessTokenDecoded.exp, 10)).setUTC(false);
        this.authTime = accessTokenDecoded.auth_time;
        this.identityType = this.parseIdentityTypeString(accessTokenDecoded.identity_type[0]);
        this.amr = accessTokenDecoded.amr;
        this.scopes = accessTokenDecoded.scopes;
    }
}

export class GrantTypeError extends Error {
    constructor(m: string) {
        super(m);
    }

    getMessage(): string {
        return this.message;
    }
}

export class IdentityTypeError extends Error {
    constructor(m: string) {
        super(m);
    }

    getMessage(): string {
        return this.message;
    }
}
