import * as React from "react";

import css from "common/modules/cssBuilder";
import prefix from "common/modules/prefixer";

import { Icon } from "ui/components/icon/icon";
import { Spinner } from "ui/components/spinner/spinner";
import { Flex } from "ui/components/flex/flex";

import "./flashBox.less";

const pf = prefix("flashBox_");

export type FlashBoxType = "info" | "warning" | "error" | "success" | "loading";

interface IFlashBoxProps {
    /**
     * Type controls the appearance of flashbox, and decides what icon to show.
     * You can use "info", "warning", "error", "success" and "loading".
     */
    type: FlashBoxType;
    id?: string;
    /** Specifies that the box has a filled background. Color is determined by the type property */
    filled?: boolean;
    /** Changes the information icons size. If true it's small. */
    small?: boolean;
    /** Flashbox container fills up parent width when true. */
    stretch?: boolean;
    /** Display the flashbox without an icon. Preferably used only when filled is set to true. */
    noIcon?: boolean;
    /** Optional text placed next to the flashbox icon. Cant be used togheter with textComponent. Will override textComponent. */
    text?: string;
    /** Will turn text color from black to white. */
    invertTextColor?: boolean;
    /**  Optional title placed next to the icon. Cant be used togheter with titleComponent. Will override titleComponent. */
    title?: string;
    /**  Optional element placed next to the flashbox icon. Cant be used togheter with title.*/
    titleComponent?: JSX.Element;
    /**  Optional element placed next to the icon. Cant be used togheter with text. */
    textComponent?: JSX.Element;
    /** Make i able to hide comonent */
    canHide?: boolean;
    /** place the hide icon on the left side of the flashbox */
    hideIconLeft?: boolean;
}

export const FlashBox = (props: IFlashBoxProps) => {
    const [hide, setHide] = React.useState(false);

    const renderIcon = () => {
        if (props.noIcon) return null;

        const flashBoxId = props.id || "flashbox";
        let icon = null;
        switch (props.type) {
            case "info":
                icon = <Icon preset="info" id={flashBoxId + ".typeIcon"} />;
                break;
            case "warning":
                icon = <Icon preset="exclamation-triangle" id={flashBoxId + ".typeIcon"} />;
                break;
            case "error":
                icon = <Icon preset="exclamation" id={flashBoxId + ".typeIcon"} />;
                break;
            case "success":
                icon = <Icon preset="check-circle" id={flashBoxId + ".typeIcon"} />;
                break;
            case "loading":
                icon = <Spinner invert={false} />;
                break;
            default:
                icon = null;
        }

        return <div className={`${pf("iconWrapper")} ${pf(`iconWrapper-${props.type}`)}`}>{icon}</div>;
    };

    const renderHideIcon = (): IconPreset => {
        switch (props.type) {
            case "info":
                return "info";
            case "warning":
                return "exclamation-triangle";
            case "error":
                return "exclamation";
            case "success":
                return "check-circle";
            default:
                return "info";
        }
    };

    const renderContent = () => {
        if (!props.title && !props.text && !props.textComponent) return null;

        const title = props.title && <p className={pf("title")}>{props.title}</p>;

        const text = props.text && <p className={pf("text")}>{props.text}</p>;

        const titleComponent = props.titleComponent && !props.title && <div className={pf("title")}>{props.titleComponent}</div>;

        const textComponent = props.textComponent && !props.text && <div className={pf("text")}>{props.textComponent}</div>;

        const textWrapperClass = css("flashBox_textWrapper", true, "invertedColor", props.invertTextColor);
        return (
            <div className={textWrapperClass}>
                {title || titleComponent}
                {text || textComponent}
            </div>
        );
    };

    const wrapperClass = css(
        "flashBox",
        true,
        "info",
        props.type === "info",
        "warning",
        props.type === "warning",
        "error",
        props.type === "error",
        "success",
        props.type === "success",
        "loading",
        props.type === "loading",
        "small",
        props.small
    );

    const containerClass = css("flashBox_container", true, "filled", props.filled, "stretch", props.stretch);

    if (props.canHide && props.type !== "loading") {
        return (
            <div className={wrapperClass} id={props.id}>
                <div className={containerClass}>
                    <Flex direction="column">
                        <Flex justify={props.hideIconLeft ? "flex-start" : "flex-end"} align="center">
                            <Icon id="hide.icon" onClick={() => setHide(!hide)} style={hide ? {} : { transform: "rotate(180deg)" }} preset={hide ? renderHideIcon() : "window-minimize"} />
                        </Flex>
                        <Flex align="center">
                            {!hide && renderIcon()}
                            {!hide && renderContent()}
                        </Flex>
                    </Flex>
                </div>
            </div>
        );
    }

    return (
        <div className={wrapperClass} id={props.id}>
            <div className={containerClass}>
                {!hide && renderIcon()}
                {!hide && renderContent()}
            </div>
        </div>
    );
};
