import * as ToastActions from "framework/actions/toastActions";
import * as Toast from "framework/models/toaster/toast";
import * as PermissionHelper from "framework/modules/permissionHelper";
import * as actions from "../actions/applicationActions";

import AppAPI from "framework/api/applicationAPI";
import MenuItem from "framework/models/MenuItem";
import ToastType from "framework/models/toaster/toasterTypeEnum";
import Insights from "framework/modules/insights";
import Store from "./store"; // just to make sure it gets included in the bundle

import { EStorageType, StorageManager } from "application/storageManager/storageManager";
import { Result } from "framework/models/result";

type UseCache = { prefix: string; value: boolean };

export type ApplicationStoreState = {
    isFullscreen: boolean;
    showClonesFirstInSystemSelector: boolean;
    showDebugInfo: boolean;
    showDebugTooltips: boolean;
    showFreezer: boolean;
};

interface IApplicationStore {
    getFullScreen(): boolean;
    getLinks(): MenuItem[];
    getShowClonesFirstInSystemSelector(): boolean;
    getDebugInfo(): boolean;
    getDebugTranslations(): boolean;
    getInactivateEvents(): boolean;
    getUseCacheResult(): Result<UseCache>;
    getState(): ApplicationStoreState;
}

class ApplicationStore extends Store implements IApplicationStore {
    private readonly _storageManager: StorageManager;
    private readonly _showClonesFirstInSystemSelectorKey = "app.settings.showClonesFirstInSystemSelector";

    private _showClonesFirstInSystemSelector: boolean = false;
    private _showDebugInfo: boolean = false;
    private _showFreezer: boolean = false;
    private _showDebugTooltips: boolean = false;
    private _debugTranslations: boolean = false;
    private _inactivateEvents: boolean = false;
    private _inactivateTentativeEmployeeBanner: boolean = false;
    private _fullScreen: boolean = false;
    private _links: MenuItem[] = [];
    private _useCache: UseCache = { prefix: "", value: true };
    private flaggedUpdate: boolean;

    constructor() {
        super();
        this._storageManager = StorageManager.GetInstance();

        this._showClonesFirstInSystemSelector = this._getStoredShowClonesFirstInSystemSelector();
        this._showDebugInfo = this._storageManager.retrieve("app.settings.debugBar", EStorageType.LocalStorage, true) === "1" || this._showDebugInfo;
        this._showDebugTooltips = this._storageManager.retrieve("app.settings.debugTooltips", EStorageType.LocalStorage, true) === "1" && this._showDebugInfo;
        this._showFreezer = this._storageManager.retrieve("app.settings.freezer", EStorageType.LocalStorage, true) === "1" || this._showFreezer;
        this._debugTranslations = this._storageManager.retrieve("app.settings.debugTranslations", EStorageType.LocalStorage, true) === "1" || this._debugTranslations;
        this._inactivateEvents = this._storageManager.retrieve("app.settings.inactivateEvents", EStorageType.LocalStorage, true) === "1" || this._inactivateEvents;
        this._inactivateTentativeEmployeeBanner =
            this._storageManager.retrieve("app.settings.inactivateTentativeEmployeeBanner", EStorageType.LocalStorage, true) === "1" || this._inactivateTentativeEmployeeBanner;
        this.flaggedUpdate = false;
        this.handle(actions.Change.actionType, this._handleChange);
        this.handle(actions.ToggleFullScreen.actionType, this._handleToggleFullScreen);
        this.handle(actions.SetShowDebugInfo.actionType, this._handleSetShowDebugInfo);
        this.handle(actions.SetShowFreezer.actionType, this._handleSetShowFreezer);
        this.handle(actions.SetShowDebugTooltips.actionType, this._handleSetShowDebugTooltips);
        this.handle(actions.SetDebugTranslations.actionType, this._handleSetDebugTranslations);
        this.handle(actions.SetInactivateEvents.actionType, this._handleSetInactivateEvents);
        this.handle(actions.SetInactivateTentativeEmployeeBanner.actionType, this._handleSetInactivateTentativeEmployeeBanner);
        this.handle(actions.SetShowClonesFirstInSystemSelector.actionType, this._handleShowClonesFirstInSystemSelector);
        // Disable handle Reload to get soft refresh instead of page reload
        this.handle(actions.FullReload.actionType, this._handleReload);
        this.handle(actions.OutOfSync.actionType, this._handleOutOfSync);
        this.handle(actions.UseCacheLoaded.actionType, this._handleUseCacheLoaded);
        this.handle(actions.SetUseCache.actionType, this._handleSetUseCache);
        this.handle(actions.FlushCache.actionType, this._handleFlushCache);
        this.handle(actions.FlushSystemCache.actionType, this._handleFlushSystemCache);
        this.handle(actions.FlushNewsCache.actionType, this._handleFlushNewsCache);

        this.handle(actions.UpdateDetected.actionType, this._handleUpdateDetected);
    }

    getLinks() {
        // Only show links that the user has permission for
        return this._links.filter((l) => !l.permissions || l.permissions.find((p: any) => PermissionHelper.hasPermission(p)));
    }

    getFullScreen(): boolean {
        return this._fullScreen;
    }

    private _getStoredShowClonesFirstInSystemSelector(): boolean {
        const storedValue = this._storageManager.retrieve(this._showClonesFirstInSystemSelectorKey, EStorageType.LocalStorage, true);
        /**
         * If user with caspeco support role is actually support and not developer
         * they are using probably 'cloud.caspeco.se'. They will always want to have live systems
         * at the top. Any other URL is probably 'localhost' or 'trackingdev' which is what
         * developers and PO use and we assume they want to see the clones first in the list
         * This is only when no option has been selected in advanced settings.
         */
        const isLive = document.location.href.indexOf("cloud.caspeco.se") > -1;
        if (!storedValue && isLive) return false;
        if (!storedValue && !isLive) return true;
        return storedValue === "1";
    }

    public getShowClonesFirstInSystemSelector(): boolean {
        return this._showClonesFirstInSystemSelector;
    }

    getDebugInfo(): boolean {
        return this._showDebugInfo;
    }

    showFreezer(): boolean {
        return this._showFreezer;
    }

    getDebugTooltipsSetting(): boolean {
        return this._showDebugTooltips;
    }

    getDebugTranslations(): boolean {
        return this._debugTranslations;
    }

    getInactivateEvents(): boolean {
        return this._inactivateEvents;
    }

    getInactivateTentativeEmployeeBanner(): boolean {
        return this._inactivateTentativeEmployeeBanner;
    }

    getUseCacheResult(): Result<UseCache> {
        if (this.getStatus("useCache") === this.NotLoaded) {
            AppAPI.loadCacheState();
            this.setStatus(this.Loading, "useCache");
        }
        return new Result({ status: this.getStatus("useCache"), value: this._useCache });
    }

    private _handleUseCacheLoaded = (action: actions.UseCacheLoaded) => {
        this._useCache = action.cacheState;
        this.setStatus(this.Loaded, "useCache");
        this.emitChange();
    };

    private _handleFlushCache = (_action: actions.FlushCache) => {
        AppAPI.flushCache();
    };

    private _handleFlushSystemCache = (_action: actions.FlushSystemCache) => {
        AppAPI.flushSystemCache();
    };

    private _handleFlushNewsCache = (_action: actions.FlushNewsCache) => {
        AppAPI.flushNewsCache();
    };

    private _handleSetUseCache = (action: actions.SetUseCache) => {
        if (action.useCache !== this._useCache.value) {
            AppAPI.setUseCache(action.useCache);
            this._useCache.value = action.useCache;
            this.setStatus(this.Loading, "useCache");
            this.emitChange();
        } else {
            this.setStatus(this.NotLoaded, "useCache");
            this.emitChange();
        }
    };

    private _handleToggleFullScreen = (action: actions.ToggleFullScreen) => {
        this._fullScreen = action.fullScreen;
        this.emitChange();
    };

    private _handleShowClonesFirstInSystemSelector = (action: actions.SetShowClonesFirstInSystemSelector) => {
        this._showClonesFirstInSystemSelector = action.showClonesFirstInSystemSelector;
        this._storageManager.store(this._showClonesFirstInSystemSelectorKey, this._showClonesFirstInSystemSelector ? "1" : "0", EStorageType.LocalStorage, true);
        this.emitChange();
    };

    private _handleSetShowDebugInfo = (action: actions.SetShowDebugInfo) => {
        this._showDebugInfo = action.showDebugInfo;
        this._storageManager.store("app.settings.debugBar", this._showDebugInfo ? "1" : "0", EStorageType.LocalStorage, true);
        if (!action.showDebugInfo) {
            this._showDebugTooltips = false; // When showDebugInfo is being disabled we want to disable the debugtooltips as well
        }
        this.emitChange();
    };

    private _handleSetShowFreezer = (action: actions.SetShowFreezer) => {
        this._showFreezer = action.showFreezer;
        this._storageManager.store("app.settings.freezer", action.showFreezer ? "1" : "0", EStorageType.LocalStorage, true);
        this.emitChange();
    };

    private _handleSetShowDebugTooltips = (action: actions.SetShowDebugTooltips) => {
        if (!this._showDebugInfo) return; // Can't be seen without debugInfo setting also being true
        this._showDebugTooltips = action.showDebugTooltips;
        this._storageManager.store("app.settings.debugTooltips", this._showDebugTooltips ? "1" : "0", EStorageType.LocalStorage, true);
        this.emitChange();
    };

    private _handleSetDebugTranslations = (action: actions.SetDebugTranslations) => {
        this._debugTranslations = action.debugTranslations;
        this._storageManager.store("app.settings.debugTranslations", this._debugTranslations ? "1" : "0", EStorageType.LocalStorage, true);
        this.emitChange();
    };

    private _handleSetInactivateEvents = (action: actions.SetInactivateEvents) => {
        this._inactivateEvents = action.inactivateEvents;
        this._storageManager.store("app.settings.inactivateEvents", this._inactivateEvents ? "1" : "0", EStorageType.LocalStorage, true);
        this.emitChange();
    };

    private _handleSetInactivateTentativeEmployeeBanner = (action: actions.SetInactivateTentativeEmployeeBanner) => {
        this._inactivateTentativeEmployeeBanner = action.inactivateTentativeEmployeeBanner;
        this._storageManager.store("app.settings.inactivateTentativeEmployeeBanner", this._inactivateTentativeEmployeeBanner ? "1" : "0", EStorageType.LocalStorage, true);
        this.emitChange();
    };

    private _handleChange = (action: actions.Change) => {
        this._links = action.links;
        this.emitChange();
    };

    private _handleUpdateDetected = (_action: actions.UpdateDetected) => {
        if (this.flaggedUpdate) return;
        this.flaggedUpdate = true;
        setTimeout(() => {
            new ToastActions.Add(
                Toast.Record({
                    heading: "application.updateDetectedHeading",
                    message: "application.updateDetectedMessage",
                    type: ToastType.Information,
                    alwaysVisible: true,
                    onClick: () => {
                        try {
                            new actions.FullReload();
                        } catch (error) {
                            // Nothing
                        }
                    },
                })
            );
        }, 1000);
    };

    private _handleReload = (_action: actions.FullReload) => {
        window.location.reload();
    };

    private _handleOutOfSync = (_action: actions.OutOfSync) => {
        setTimeout(() => {
            new ToastActions.Add(
                Toast.Record({
                    heading: "application.outOfSyncHeading",
                    message: "application.outOfSyncMessage",
                    type: ToastType.Information,
                    alwaysVisible: true,
                    onClick: () => {
                        try {
                            new actions.Reload();
                        } catch (error) {
                            // Nothing
                        }
                    },
                })
            );
            // setTimeout(function() {
            //     new actions.Reload();
            // }, 5000);
        }, 0);
        Insights.trackEvent("OutOfSync");
    };

    getState(): ApplicationStoreState {
        return {
            isFullscreen: this._fullScreen,
            showClonesFirstInSystemSelector: this._showClonesFirstInSystemSelector,
            showDebugInfo: this._showDebugInfo,
            showDebugTooltips: this._showDebugTooltips,
            showFreezer: this._showFreezer,
        };
    }
}

const applicationStore = new ApplicationStore();
export default applicationStore;
