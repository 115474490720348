import * as React from "react";
import classNames from "classnames";

import "./spacer.less";

/**
 * none = 0px (0rem)
 * x-small = 4px (0.25rem)
 * small = 8px (0.5rem)
 * medium = 16px (1rem)
 * large = 20px (1.25rem)
 * x-large = 24px (1.5rem)
 * xx-large = 32px (2rem)
 * xxx-large = 38px (2.5rem)
 */

export type SpaceSize = "none" | "xx-small" | "x-small" | "small" | "medium" | "large" | "x-large" | "xx-large" | "xxx-large";

export interface IMarginProps {
    /** Margin set on all sides */
    m?: SpaceSize;
    /** Horizontal margin (left and right) */
    mx?: SpaceSize;
    /** Vertical margin (top and bottom) */
    my?: SpaceSize;
    /** Margin right */
    mr?: SpaceSize;
    /** Margin left */
    ml?: SpaceSize;
    /** Margin top */
    mt?: SpaceSize;
    /** Margin bottom */
    mb?: SpaceSize;
}

interface ISpacerProps extends IMarginProps {
    /** Optional id. */
    id?: string;
    /** Fill parent element width (sets width of spacer to 100%) */
    fillParentWidth?: boolean;
    /** Fill parent element height (sets height of spacer to 100%) */
    fillParentHeight?: boolean;
    /** Set a custom tag (span etc.), default is 'div' */
    tagName?: string;
}

export const Spacer: React.FC<PropsWithImmutableChildren<ISpacerProps>> = ({ children, fillParentWidth, fillParentHeight, id, my, mx, mt, mb, mr, ml, m, tagName = "div" }) => {
    const getMargin = (): string => {
        let overwriteMx: boolean = false;
        let overwriteMy: boolean = false;

        // HORIZONTALLY
        // ml (margin left) or mr (margin right) has higher specificity than mx (margin horizontally) and should overwrite mx
        if (ml || mr) {
            overwriteMx = true;
        }

        // VERTICALLY
        // mt (margin top) or mb (margin bottom) has higher specificity than my (margin vertically) and should overwrite my
        if (mt || mb) {
            overwriteMy = true;
        }
        // BOTH HORIZONTALLY and VERTICALLY
        // size (the combination of mx and my) has the highest specificity and should overwrite all other classes
        if (m) {
            return `mx-${m} my-${m}`;
        }

        return classNames({
            [`mx-${mx}`]: mx && !overwriteMx,
            [`my-${my}`]: my && !overwriteMy,
            [`mb-${mb}`]: mb,
            [`mt-${mt}`]: mt,
            [`ml-${ml}`]: ml,
            [`mr-${mr}`]: mr,
        });
    };

    return React.createElement(
        tagName,
        {
            id,
            className: getMargin(),
            style: { width: fillParentWidth && "100%", height: fillParentHeight && "100%" },
        },
        children
    );
};
