import MenuItem from "framework/models/MenuItem";
import Action from "./action";

export class Change extends Action {
    static actionType: string = "application_desc_change";

    public links: MenuItem[];

    constructor(links: MenuItem[]) {
        super(false);
        this.links = links;
        this.doDispatch();
    }
}

export class ToggleFullScreen extends Action {
    static actionType: string = "application_toggle_fullscreen";

    public fullScreen: boolean;

    constructor(fullScreen: boolean) {
        super(false);
        this.fullScreen = fullScreen;
        this.doDispatch();
    }
}

export class SetShowClonesFirstInSystemSelector extends Action {
    static actionType: string = "application_set_showClonesFirstInSystemSelector";

    constructor(public showClonesFirstInSystemSelector: boolean) {
        super(false);
        this.showClonesFirstInSystemSelector = showClonesFirstInSystemSelector;
        this.doDispatch();
    }
}

export class SetShowDebugInfo extends Action {
    static actionType: string = "application_set_showDebugInfo";

    public showDebugInfo: boolean;

    constructor(showDebugInfo: boolean) {
        super(false);
        this.showDebugInfo = showDebugInfo;
        this.doDispatch();
    }
}

export class SetShowFreezer extends Action {
    static actionType: string = "application_set_showFreezer";

    public showFreezer: boolean;

    constructor(showFreezer: boolean) {
        super(false);
        this.showFreezer = showFreezer;
        this.doDispatch();
    }
}

export class SetShowDebugTooltips extends Action {
    static actionType: string = "application_set_showDebugTooltips";

    public showDebugTooltips: boolean;

    constructor(showDebugTooltips: boolean) {
        super(false);
        this.showDebugTooltips = showDebugTooltips;
        this.doDispatch();
    }
}

export class SetDebugTranslations extends Action {
    static actionType: string = "application_SetDebugTranslations";

    constructor(public debugTranslations: boolean) {
        super(false);
        this.doDispatch();
    }
}

export class SetInactivateEvents extends Action {
    static actionType: string = "application_set_inactivateEvents";

    public inactivateEvents: boolean;

    constructor(inactivateEvents: boolean) {
        super(false);
        this.inactivateEvents = inactivateEvents;
        this.doDispatch();
    }
}

export class SetInactivateTentativeEmployeeBanner extends Action {
    static actionType: string = "application_set_inactivateTentativeEmployeeBanner";

    public inactivateTentativeEmployeeBanner: boolean;

    constructor(inactivateTentativeEmployeeBanner: boolean) {
        super(false);
        this.inactivateTentativeEmployeeBanner = inactivateTentativeEmployeeBanner;
        this.doDispatch();
    }
}

export class OutOfSync extends Action {
    static actionType: string = "application_outOfSync";

    constructor() {
        super();
    }
}

export class Reload extends Action {
    static actionType: string = "application_reload";

    constructor() {
        super();
    }
}

export class FullReload extends Action {
    static actionType: string = "application_HardReload";

    constructor() {
        super(false);
        this.doDispatch();
    }
}

export class SetUseCache extends Action {
    static actionType: string = "application_SetUseCache";

    useCache: boolean;
    constructor(useCache: boolean) {
        super(false);
        this.useCache = useCache;
        this.doDispatch();
    }
}

export class FlushCache extends Action {
    static actionType: string = "application_FlushCache";

    constructor() {
        super();
    }
}

export class FlushSystemCache extends Action {
    static actionType: string = "application_FlushSystemCache";

    constructor() {
        super();
    }
}

export class FlushNewsCache extends Action {
    static actionType: string = "application_FlushNewsCache";

    constructor() {
        super();
    }
}
type UseCache = { prefix: string; value: boolean };

export class UseCacheLoaded extends Action {
    static actionType: string = "application_UseCacheLoaded";

    cacheState: UseCache;
    constructor(cacheState: UseCache) {
        super(false);
        this.cacheState = cacheState;
        this.doDispatch();
    }
}

export class UpdateDetected extends Action {
    static actionType: string = "application_UpdateDetected";

    version: string;
    constructor(version: string) {
        super(false);
        this.version = version;
        this.doDispatch();
    }
}
