import ev from "./eventEmitter";
import IStore from "./istore";
import { Action, AnyAction, EnhancedStore, Dispatch } from "@reduxjs/toolkit";

export const reduxStoreWrapperDispatchers = Array<Dispatch>();

export class ReduxStoreWrapper<S = any> extends ev implements IStore<S> {
    private store: any;
    private selector: string;
    private previousState: any;
    constructor(store: any, selector: string) {
        super();

        this.store = store;
        this.selector = selector;
        this.previousState = store.getState[selector];

        this.store.subscribe(() => {
            const state = store.getState()[this.selector];
            if (this.previousState !== state) {
                this.emitChange(this.store.getState()[this.selector]);
            }
            this.previousState = state;
        });

        if (!reduxStoreWrapperDispatchers.includes(this.store.dispatch)) {
            reduxStoreWrapperDispatchers.push(this.store.dispatch);
        }
    }

    onChange(callback: (data?: any) => void): void {
        this.on("change", callback);
    }

    offChange(callback: (data?: any) => void): void {
        this.off("change", callback);
    }

    getState(): S {
        return (this.store.getState() as any)[this.selector];
    }

    emitChange = (data?: S) => {
        this.emit("change", data);
    };

    getStore() {
        return this.store;
    }
}
