import StationAPI from "application/api/stationAPI";
import Action from "framework/actions/action";

import { IStationValues, Station } from "application/models/station";
import { List } from "immutable";

export class LoadedAllBySystemSuccessfully extends Action {
    static actionType: string = "station_loaded_all_by_system_successfully";
    stationsBySystem: Immutable.Map<string, List<IStationValues>>;
    system: string;

    constructor(stationsBySystem: Immutable.Map<string, List<IStationValues>>, system: string) {
        super(false);
        this.stationsBySystem = stationsBySystem;
        this.system = system;
        this.doDispatch();
    }
}

// Station sucessfully created (API)
export class CreatedSuccessfully extends Action {
    static actionType: string = "station_created_successfully";

    // The temporary ID that the station was assigned when created.
    temporaryId: number;

    // The station from the API (with actual ID).
    station: Station;

    constructor(temporaryId: number, station: Station) {
        super(false);
        this.temporaryId = temporaryId;
        this.station = station;
        this.doDispatch();
    }
}

export class CreateFailed extends Action {
    static actionType: string = "station_create_failed";

    station: Station;

    constructor(station: Station) {
        super(false);
        this.station = station;
        this.doDispatch();
    }
}

export class LoadedAllSuccessfully extends Action {
    static actionType: string = "station_loaded_all_successfully";

    stations: List<IStationValues>;

    constructor(stations: List<IStationValues>) {
        super(false);
        this.stations = stations;
        this.doDispatch();
    }
}

// Station successfully updated (API)
export class UpdatedSuccessfully extends Action {
    static actionType: string = "station_updated_successfully";

    station: Station;

    constructor(station: Station) {
        super(false);
        this.station = station;
        this.doDispatch();
    }
}

export class UpdateFailed extends Action {
    static actionType: string = "station_update_failed";

    station: Station;

    constructor(station: Station) {
        super(false);
        this.station = station;
        this.doDispatch();
    }
}

export class DeletedSuccessfully extends Action {
    static actionType: string = "station_deleted_successfully";

    station: Station;

    constructor(station: Station) {
        super(false);
        this.station = station;
        this.doDispatch();
    }
}

export class DeleteFailed extends Action {
    static actionType: string = "station_delete_failed";

    station: Station;

    constructor(station: Station) {
        super(false);
        this.station = station;
        this.doDispatch();
    }
}

// ----------- Move (normal, success, fail) -----------
export class Move extends Action {
    static actionType: string = "station_Move";

    station: Station;
    constructor(station: Station) {
        super(false);
        this.station = station;
        this.doDispatch();
    }
}

export class MoveSuccess extends Action {
    static actionType: string = "station_Move_success";

    stations: List<Station>;
    constructor(stations: List<Station>) {
        super(false);
        this.stations = stations;
        this.doDispatch();
    }
}

export class MoveFail extends Action {
    static actionType: string = "station_Move_fail";

    constructor() {
        super(false);
        this.doDispatch();
    }
}

// ----------- Remove (normal, success, fail) -----------
export class Remove extends Action {
    static actionType: string = "station_Remove";

    station: Station;
    constructor(station: Station) {
        super(false);
        this.station = station;
        this.doDispatch();
    }
}

export class RemoveSuccess extends Action {
    static actionType: string = "station_Remove_success";

    stations: List<Station>;
    constructor(stations: List<Station>) {
        super(false);
        this.stations = stations;
        this.doDispatch();
    }
}

export class RemoveFail extends Action {
    static actionType: string = "station_Remove_fail";

    constructor() {
        super(false);
        this.doDispatch();
    }
}
// ----------- Check (normal, success, fail) -----------
export class Check extends Action {
    static actionType: string = "station_check";

    constructor(station: Station, fromDate: IMoment) {
        super(false);
        StationAPI.check(station, fromDate);
        this.doDispatch();
    }
}

export class CheckSuccess extends Action {
    static actionType: string = "station_check_success";

    result: any;
    constructor(result: any) {
        super(false);
        this.result = result;
        this.doDispatch();
    }
}

export class CheckFail extends Action {
    static actionType: string = "station_check_fail";

    constructor(_station: Station) {
        super();
    }
}

// ----------- Check allow delete (normal, success, fail) -----------
export class CheckAllowDelete extends Action {
    static actionType: string = "station_checkAllowDelete";

    constructor(station: Station) {
        super(false);
        StationAPI.checkStationAllowDelete(station);
        this.doDispatch();
    }
}

export class CheckAllowDeleteSuccess extends Action {
    static actionType: string = "station_checkAllowDelete_success";
    allowDelete: boolean;
    usedInLockedPayrollRun: boolean;
    constructor(result: any, public stationId: number) {
        super(false);
        this.allowDelete = result.allowDelete;
        this.usedInLockedPayrollRun = result.usedInLockedPayrollRun;
        this.doDispatch();
    }
}

export class CheckAllowDeleteFail extends Action {
    static actionType: string = "station_checkAllowDelete_fail";

    constructor(_station: Station) {
        super();
    }
}
