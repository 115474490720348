import { IconPack } from "@fortawesome/fontawesome-common-types";

// Use yarn gen fa-brand-icon to add new icon to the library
/* PLOP_INJECT_IMPORT */
import { faChrome } from "@fortawesome/free-brands-svg-icons/faChrome";
import { faEdge } from "@fortawesome/free-brands-svg-icons/faEdge";
import { faInternetExplorer } from "@fortawesome/free-brands-svg-icons/faInternetExplorer";
import { faSafari } from "@fortawesome/free-brands-svg-icons/faSafari";

export default <IconPack>{
    /* PLOP_INJECT_EXPORT */
    faChrome,
    faEdge,
    faInternetExplorer,
    faSafari,
};
