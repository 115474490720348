import classNames from "classnames";
import * as React from "react";

import { Map } from "immutable";
import { Icon, IconScale } from "ui/components/icon/icon";

import "./spinnerCircle.less";

interface ISpinnerCircleProps {
    /** Changes size of spinner, default is "small". Possible properties are "large", "medium", "small" and "tiny". */
    size?: "large" | "medium" | "small" | "tiny";
    /** Can be used as an alternative icon color. If set to true the spinner should turn white. */
    inverse?: boolean;
    /** Sets the spinner slightly to the right and gives space around the spinner (this is also the case on the right side if space is needed there).
     * Height is also influenced and the way it spinns is slightly different from other spinners = not recommended to use. */
    setRight?: boolean;
    /** Gets and sets the values of the class attributes of the element. */
    className?: string;
}

const SizeToIconScale = Map<"large" | "medium" | "small" | "tiny", IconScale>({
    tiny: "smaller",
    small: "small",
    medium: "large",
    large: "larger",
});

export const SpinnerCircle: React.FC<PropsWithImmutableChildren<ISpinnerCircleProps>> = ({ size = "small", inverse = false, setRight = false, className }) => {
    const iconClassNames = classNames({
        spinnerCircle: true,
        isLoading: true,
        spinnerCircle_setRight: setRight,
        spinnerCircle_inverted: inverse,
        [`spinnerCircle_${className}`]: Boolean(className),
        [`spinnerCircle_${size}`]: Boolean(size),
    });
    return <Icon id={"spinner"} preset={"spinner"} className={iconClassNames} spin={true} inverse={inverse} size={SizeToIconScale.get(size)} />;
};

export default SpinnerCircle;
