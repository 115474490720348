import { RenderMode } from "common/contexts/renderModeContext";
import PermissionStore from "framework/stores/permissionStore";
import RenderStore from "framework/stores/renderModeStore";

export function hasPermission(key: string, stationId?: number): boolean {
    if (stationId) {
        return PermissionStore.hasPermissionForStation(key, stationId);
    }
    return PermissionStore.hasPermission(key);
}

export interface IPermissionsProps {
    /** This permission will be required in order to see this. In case several permissions should be checked, see anyPermission/allPermissions */
    permission?: string;
    /** All of these permissions will be required in order to see this. In case only one permission are needed, see permission/anyPermission */
    allPermissions?: string[];
    /** Any of these permissions will be required in order to see this. In case several permissions are needed, see allPermissions */
    anyPermission?: string[];
    /** All of these permissions will be required to be missing in order to see this menu item */
    exceptPermissions?: string[];
    devices?: RenderMode | RenderMode[];
}

export function hasPermissions(permissions: IPermissionsProps, stationId?: number): boolean {
    if (permissions.permission && !hasPermission(permissions.permission, stationId)) {
        return false;
    } else if (permissions.anyPermission && !permissions.anyPermission.some((p) => hasPermission(p, stationId))) {
        return false;
    } else if (permissions.allPermissions && !permissions.allPermissions.every((p) => hasPermission(p, stationId))) {
        return false;
    } else if (permissions.exceptPermissions && permissions.exceptPermissions.some((p) => hasPermission(p, stationId))) {
        return false;
    }

    if (permissions.devices) {
        const currentDevice = RenderStore.getMode();
        if (typeof permissions.devices === "string") {
            if (permissions.devices !== currentDevice) {
                return false;
            }
        } else {
            if (permissions.devices.indexOf(currentDevice) < 0) {
                return false;
            }
        }
    }
    return true;
}

export function getStationsForPermission(key: string) {
    return PermissionStore.getStationsForPermission(key);
}

export function ifElsePermission<T, U>(key: string, ifPermitted: T, ifDenied: U) {
    if (hasPermission(key)) {
        return ifPermitted;
    } else {
        return ifDenied;
    }
}

export function ifElsePermissionForStation<T, U>(key: string, stationId: number, ifPermitted: T, ifDenied: U) {
    if (hasPermission(key, stationId)) {
        return ifPermitted;
    } else {
        return ifDenied;
    }
}

export function permissionsLoaded() {
    return PermissionStore.permissionsLoaded();
}
