import { IconStyle } from "ui/components/icon/icon";

export type OldIconTagPreset =
    | "blank"
    | "beer"
    | "bath"
    | "snowflake"
    | "car"
    | "diamond"
    | "binoculars"
    | "anchor"
    | "utensils"
    | "music"
    | "moon-o"
    | "star"
    | "star-o"
    | "snowflake-o"
    | "cutlery"
    | "star-half-o"
    | "bicycle"
    | "wheelchair"
    | "tree"
    | "magnet"
    | "heart"
    | "heart-o"
    | "bomb"
    | "glass";

export const oldIconTags = ["bath", "snowflake", "snowflake-o", "diamond", "moon-o", "star-o", "star-half-o", "heart-o", "glass", "cutlery"];

export interface IOldIcon {
    name: IconPreset;
    iconStyle: IconStyle;
}

const oldIconMap: { [key in OldIconTagPreset]: IconPreset } = {
    blank: "blank",
    beer: "beer",
    car: "car",
    binoculars: "binoculars",
    anchor: "anchor",
    utensils: "utensils",
    cutlery: "utensils",
    music: "music",
    star: "star",
    bicycle: "bicycle",
    wheelchair: "wheelchair",
    tree: "tree",
    magnet: "magnet",
    heart: "heart",
    bomb: "bomb",
    bath: "tint",
    snowflake: "asterisk",
    diamond: "gem",
    "moon-o": "moon",
    "star-o": "star",
    "star-half-o": "star",
    "heart-o": "heart",
    glass: "cocktail",
    "snowflake-o": "asterisk",
};

export const convertOldIcon = (oldIconName: OldIconTagPreset): IOldIcon => {
    if (oldIconName === "moon-o" || oldIconName === "star-o" || oldIconName === "heart-o") {
        return {
            name: oldIconName.replace("-o", "") as IconPreset,
            iconStyle: "regular",
        };
    } else if (oldIconName === "star-half-o") {
        return {
            name: oldIconName.replace("-o", "-alt") as IconPreset,
            iconStyle: "regular",
        };
    } else {
        return {
            name: oldIconMap[oldIconName],
            iconStyle: "solid",
        };
    }
};
