import * as Immutable from "immutable";

export enum CompanyContactResponsibility {
    /** Lön */
    Salary = 1,

    /** Ekonomi */
    Financial = 2,

    /** System */
    System = 3,
}

export interface ICompanyContact extends Immutable.Map<string, any> {
    /** Indicates which station this contact is responsible of
     *  (if this is null, this is the default contact for all stations that does not have a specific contact for this responsibility)*/
    stationId?: number;

    /** Indicates which responsibility this contact is responsible of (Lön, Ekonomi, System etc.) */
    responsibility: CompanyContactResponsibility;

    /** References the user this is connected to, required. */
    userSubject: string;

    /** Name of user (this is fetched from user when getting companyinformation, unable to be set here, the only way to change this is to change UserSubject) */
    name: string;

    /** Email of user (this is fetched from user when getting companyinformation, unable to be set here, the only way to change this is to change UserSubject) */
    email: string;

    /** Phone number of user (this is fetched from user when getting companyinformation, unable to be set here, the only way to change this is to change UserSubject) */
    mobilePhone: string;
}

const CompanyContactRecord = Immutable.Record({
    stationId: null,
    responsibility: null,
    userSubject: null,
    name: null,
    email: null,
    mobilePhone: null,
});

export function CompanyContact(values: any): ICompanyContact {
    const record: ICompanyContact = <any>new CompanyContactRecord(values);
    return record;
}
