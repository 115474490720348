import { Placement, autoPlacement, useFloating, useHover, useInteractions } from "@floating-ui/react";
import { PropsWithChildren } from "react";
import "./popup.less";
interface IPopupProps {
    disablePointerEvents?: boolean;
    /** Placement for the popover content
     * @NOTE It's restraint to the placement array. If you want it more responsive use all the possible placements.
     * If you have ["right"] and the content will not fit when window size changes it will still place the content on the right,
     * you will then need to use ["right", "left"]
     */
    placement: Placement[];
}

export const Popup = ({ children, disablePointerEvents, placement }: PropsWithChildren<IPopupProps>) => {
    const { x, y, refs, context } = useFloating({
        middleware: [
            autoPlacement({
                allowedPlacements: placement,
            }),
        ],
        open: true,
    });
    const hover = useHover(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);
    return (
        <div className="popup" ref={refs.setReference} {...getReferenceProps()} style={{ top: y ?? 0, left: x ?? 0, pointerEvents: disablePointerEvents ? "none" : "auto" }}>
            <div className="content" ref={refs.setFloating} {...getFloatingProps()} style={{ top: y ?? 0, left: x ?? 0 }}>
                <>{children}</>
            </div>
        </div>
    );
};
