import * as React from "react";

export type Orientation = "landscape" | "portrait";
export type RenderMode = "desktop" | "tablet" | "mobile";
export interface IDeviceDataProps {
    renderModeDevice?: RenderMode;
    renderModeOrientation?: Orientation;
    isMobile?: boolean;
    isDesktop?: boolean;
    isTablet?: boolean;
}

export const RenderModeContext = React.createContext<IDeviceDataProps>({
    renderModeDevice: "desktop",
    renderModeOrientation: "landscape",
    isMobile: false,
    isTablet: false,
    isDesktop: true,
});
