import { RefreshPrompt } from "framework/components/refreshPrompt/refreshPrompt";
import * as React from "react";
import { FlashBox } from "ui/components/flashBox/flashBox";
import { Flex } from "ui/components/flex/flex";

export default function asyncComp<T extends React.ComponentType<PropsWithImmutableChildren<any>>>(getComponent: () => Promise<{ default: T }>, loadingComp?: JSX.Element): T {
    const Comp = React.lazy(getComponent);
    const lazyComponent = (props: React.ComponentPropsWithRef<T>) => {
        return (
            <AsyncCompErrorBoundary>
                <React.Suspense fallback={loadingComp || <FlashBox type="loading" />}>
                    <Comp {...props} />
                </React.Suspense>
            </AsyncCompErrorBoundary>
        );
    };
    return lazyComponent as T;
}

interface IErrorBoundaryState {
    hasError: boolean;
    faultReason?: string;
}

class AsyncCompErrorBoundary extends React.Component<PropsWithImmutableChildren<{}>, IErrorBoundaryState> {
    state: IErrorBoundaryState = { hasError: false };

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, faultReason: error.name };
    }

    render() {
        if (this.state.hasError && this.state.faultReason === "ChunkLoadError") {
            return (
                <div style={{ width: "100%" }}>
                    <Flex center={true}>
                        <RefreshPrompt />
                    </Flex>
                </div>
            );
        }
        return this.props.children;
    }
}
